import React, { useState, useEffect } from 'react';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import styles from './PublishedAnnouncementsCarousel.module.css';
import * as actions from '../../../store/actions';
import * as sortTypes from '../../../utilities/helpers/types';
import vcTorontoHeader from '../../../assets/images/vctoronto-header-2.jpg';

const initialSortState = sortTypes.SORT_BY_PUBLISHED_AT_DESC;

const PublishedAnnouncementsCarousel = props => {
    // Initializing Props from Store
    const { websiteBranding, publishedAnnouncements, onFetchWebsiteBranding, onFetchPublishedAnnouncements } = props;

    // Initializing State for Carousel
    const [carouselActiveIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Get Announcements
    useEffect(() => {
        if (!publishedAnnouncements) {
            onFetchPublishedAnnouncements(initialSortState);
        }
    }, [publishedAnnouncements, onFetchPublishedAnnouncements]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--announcementsBackgroundColor', branding.theme.announcementsBackgroundColor);
            document.documentElement.style.setProperty('--announcementsHeadingTextColor', branding.theme.announcementsHeadingTextColor);
            document.documentElement.style.setProperty('--announcementsSubtitleTextColor', branding.theme.announcementsSubtitleTextColor);
            document.documentElement.style.setProperty('--announcementsBodyTextColor', branding.theme.announcementsBodyTextColor);
        }
    });

    // Handlers for Carousel
    const next = items => {
        if (animating) return;
        const carouselNextIndex = carouselActiveIndex === items.length - 1 ? 0 : carouselActiveIndex + 1;
        setActiveIndex(carouselNextIndex);
    };

    const previous = items => {
        if (animating) return;
        const carouselNextIndex = carouselActiveIndex === 0 ? items.length - 1 : carouselActiveIndex - 1;
        setActiveIndex(carouselNextIndex);
    };

    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };

    // Display Errors
    let alert = null;
    if (props.errors) {
        alert = (
            <Alert color='danger'>
                <p>{props.error.toString()}</p>
            </Alert>
        );
    }

    // Display Announcements
    let announcementListGroup = [];

    if (publishedAnnouncements && props.profile) {
        announcementListGroup = publishedAnnouncements
            ? publishedAnnouncements.map(announcement => (
                  <CarouselItem
                      onExiting={() => setAnimating(true)}
                      onExited={() => setAnimating(false)}
                      key={announcement.id}
                      className={styles.PublishedAnnouncementsCarousel}
                  >
                      <img
                          src={announcement.backgroundImageUrl ? announcement.backgroundImageUrl : vcTorontoHeader}
                          alt={announcement.title}
                      />
                      <div className={styles.AnnouncementText}>
                          <div className={styles.AnnouncementTitle}>
                              {announcement.title ? (
                                  <h1>
                                      <strong>{announcement.title}</strong>
                                  </h1>
                              ) : null}
                          </div>
                          <div className={styles.AnnouncementSubtitle}>
                              {announcement.subtitle && announcement.displaySubtitle ? (
                                  <>
                                      <small>{announcement.subtitle}</small>
                                      <br />
                                  </>
                              ) : null}
                              {announcement.author && announcement.displayAuthor ? <small>From: {announcement.author}</small> : null}
                          </div>
                          <div className={styles.AnnouncementSummary}>
                              {announcement.summary ? ReactHtmlParser(announcement.summary) : null}
                          </div>
                      </div>
                  </CarouselItem>
              ))
            : null;
    }

    return (
        <Carousel
            activeIndex={carouselActiveIndex}
            next={() => next(announcementListGroup)}
            previous={() => previous(announcementListGroup)}
        >
            {alert}
            <CarouselIndicators
                items={announcementListGroup}
                activeIndex={carouselActiveIndex}
                onClickHandler={goToIndex}
                className={styles.CarouselIndicators}
            />
            {announcementListGroup}
            {announcementListGroup.length > 1 ? (
                <>
                    <CarouselControl direction='prev' directionText='Previous' onClickHandler={() => previous(announcementListGroup)} />
                    <CarouselControl direction='next' directionText='Next' onClickHandler={() => next(announcementListGroup)} />
                </>
            ) : null}
        </Carousel>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.user.currentProfile,
        websiteBranding: state.websiteConfiguration.websiteBranding,
        publishedAnnouncements: state.announcements.publishedAnnouncements,
        error: state.announcements.error,
        loading: state.announcements.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchPublishedAnnouncements: sort => dispatch(actions.fetchPublishedAnnouncements(sort))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishedAnnouncementsCarousel);
