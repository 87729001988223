import React, { useEffect } from 'react';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './PublishedHighlightsGrid.module.css';
import * as actions from '../../../store/actions';
import * as sortTypes from '../../../utilities/helpers/types';
import vcTorontoHeader from '../../../assets/images/vctoronto-header.jpg';

const initialSortState = sortTypes.SORT_BY_PUBLISHED_AT_DESC;

const PublishedHighlightsGrid = props => {
    // Initializing Props from Component
    // top refers to rem/px to push the component up (into the AnnouncementCarousel)
    const { headingStyle } = props;

    // Initializing Props from Store
    const { websiteBranding, publishedHighlights, onFetchWebsiteBranding, onFetchPublishedHighlights } = props;

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Get Highlights
    useEffect(() => {
        if (!publishedHighlights) {
            onFetchPublishedHighlights(initialSortState);
        }
    }, [publishedHighlights, onFetchPublishedHighlights]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--highlightsBackgroundColor', branding.theme.highlightsBackgroundColor);
            document.documentElement.style.setProperty('--highlightsHeadingTextColor', branding.theme.highlightsHeadingTextColor);
            document.documentElement.style.setProperty('--highlightsSubtitleTextColor', branding.theme.highlightsSubtitleTextColor);
            document.documentElement.style.setProperty('--highlightsBodyTextColor', branding.theme.highlightsBodyTextColor);
        }
    });

    // Display Errors
    let alert = null;
    if (props.errors) {
        alert = (
            <Alert color='danger'>
                <p>{props.error.toString()}</p>
            </Alert>
        );
    }

    // Display NewsArticles
    let highlightsListGroup = null;
    let heading = null;

    if (publishedHighlights && props.profile) {
        heading = (
            <div className={styles.Heading} style={headingStyle}>
                <h1>
                    <strong>Highlights</strong>
                </h1>
            </div>
        );
        highlightsListGroup = (
            <>
                {publishedHighlights
                    ? publishedHighlights.map(newsArticle => (
                          <Card key={newsArticle.id} className={styles.NewsArticleCard + ' text-left ck-content m-20'}>
                              <CardImg
                                  top
                                  width='100%'
                                  src={newsArticle.headlineImageUrl ? newsArticle.headlineImageUrl : vcTorontoHeader}
                                  alt='News Article'
                              />
                              <CardBody>
                                  {newsArticle.title ? (
                                      <CardTitle className={styles.NewsArticleTitle}>
                                          <strong>{newsArticle.title}</strong>
                                      </CardTitle>
                                  ) : null}
                                  {newsArticle.subtitle && newsArticle.displaySubtitle ? (
                                      <CardSubtitle className={styles.NewsArticleSubtitle}>
                                          <small>{newsArticle.subtitle}</small>
                                      </CardSubtitle>
                                  ) : null}
                                  {newsArticle.author && newsArticle.displayAuthor ? (
                                      <CardSubtitle className={styles.NewsArticleSubtitle + ' mb-2'}>
                                          <small>From: {newsArticle.author}</small>
                                      </CardSubtitle>
                                  ) : null}
                                  {newsArticle.summary && newsArticle.summary ? (
                                      <CardText className={styles.NewsArticleSummary}>
                                          <Link to='/'>
                                              <small>Read More</small>
                                              <i className='fa fa-caret-right fa-sm ml-2' />
                                          </Link>
                                      </CardText>
                                  ) : null}
                              </CardBody>
                          </Card>
                      ))
                    : null}
            </>
        );
    }

    return (
        <div className={styles.PublishedHighlightsGrid}>
            {heading}
            <div className={styles.Body}>
                {alert}
                {highlightsListGroup}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.user.currentProfile,
        websiteBranding: state.websiteConfiguration.websiteBranding,
        publishedHighlights: state.news.publishedHighlights,
        error: state.news.error,
        loading: state.news.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchPublishedHighlights: sort => dispatch(actions.fetchPublishedHighlights(sort))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishedHighlightsGrid);
