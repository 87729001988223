const getTeamName = (teamId, teams) => {

    // Initialize component with teamId from props
    let teamName = '';

    if (teamId && teams) {
        const teamRef = teams.filter(team => team.id === teamId)
        if (teamRef.length > 0) {
            teamName = teamRef[0].name
        }
    }

    return teamName
}

export default getTeamName;