import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { CardTitle, CardText, NavLink } from 'reactstrap';

import styles from './SidePanelLocations.module.css';
import * as actions from '../../../store/actions';
import Spinner from '../../../components/UI/Spinner/Spinner';

const SidePanelLocations = props => {
    // Initialize Google Maps
    const googleMapsProdConfig = {
        apiKey: process.env.REACT_APP_PROD_GOOGLE_MAPS_API_KEY
    };
    const googleMapsDevConfig = {
        apiKey: process.env.REACT_APP_DEV_GOOGLE_MAPS_API_KEY
    };
    const googleMapsConfig = process.env.NODE_ENV === 'production' ? googleMapsProdConfig : googleMapsDevConfig;

    // Initialize Props from Store
    const { websiteBranding, locations, onFetchWebsiteBranding, onFetchLocations } = props;

    // Get Locations
    useEffect(() => {
        if (!locations) {
            onFetchLocations();
        }
    }, [locations, onFetchLocations]);

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--sidePanelBackgroundColor', branding.theme.sidePanelBackgroundColor);
            document.documentElement.style.setProperty('--sidePanelHeadingTextColor', branding.theme.sidePanelHeadingTextColor);
            document.documentElement.style.setProperty('--sidePanelSubtitleTextColor', branding.theme.sidePanelSubtitleTextColor);
            document.documentElement.style.setProperty('--sidePanelBodyTextColor', branding.theme.sidePanelBodyTextColor);
        }
    });

    // Set Locations Card
    let locationsCard = <Spinner />;

    if (locations) {
        locationsCard = locations.map(location => {
            const locationNameEscaped = encodeURI(location.name);
            const locationCityEscaped = encodeURI(location.city);
            const locationProvinceEscaped = encodeURI(location.province);

            const locationSection = (
                <div key={location.id} className={styles.LocationsCard}>
                    <CardTitle className={styles.Title}>
                        <strong>{location.name}</strong>
                    </CardTitle>
                    <CardText className={styles.Body}>
                        {location.address},{location.city}, {location.province} {location.postalCode}
                    </CardText>
                    <CardText className={styles.Body}>
                        <NavLink href={location.link} target='_blank' rel='noopener noreferrer' className='p-0 m-0'>
                            Open in Google Maps
                        </NavLink>
                    </CardText>
                    <CardText className='embed-responsive embed-responsive-4by3'>
                        <iframe
                            title={location.name}
                            className='embed-responsive-item'
                            frameBorder='0'
                            style={{ border: 0 }}
                            src={
                                'https://www.google.com/maps/embed/v1/place?q=' +
                                locationNameEscaped +
                                ',' +
                                locationCityEscaped +
                                ',' +
                                locationProvinceEscaped +
                                '&key=' +
                                googleMapsConfig.apiKey
                            }
                            allowFullScreen
                        />
                    </CardText>
                </div>
            );
            return location.name && location.address ? locationSection : null;
        });
    }

    return (
        <div>
            <div className={styles.LocationsHeading}>
                <h4>
                    <strong>Locations</strong>
                </h4>
            </div>
            {locationsCard}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        websiteBranding: state.websiteConfiguration.websiteBranding,
        locations: state.locations.locations,
        error: state.locations.error,
        loading: state.locations.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchLocations: () => dispatch(actions.fetchLocations())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidePanelLocations);
