export const SORT_BY_NAME_ASC = 'SORT_BY_NAME_ASC';
export const SORT_BY_NAME_DESC = 'SORT_BY_NAME_DESC';
export const SORT_BY_TITLE_ASC = 'SORT_BY_TITLE_ASC';
export const SORT_BY_TITLE_DESC = 'SORT_BY_TITLE_DESC';
export const SORT_BY_FIRST_NAME_ASC = 'SORT_BY_FIRST_NAME_ASC';
export const SORT_BY_FIRST_NAME_DESC = 'SORT_BY_FIRST_NAME_DESC';
export const SORT_BY_EMAIL_ASC = 'SORT_BY_EMAIL_ASC';
export const SORT_BY_EMAIL_DESC = 'SORT_BY_EMAIL_DESC';
export const SORT_BY_DATE_ASC = 'SORT_BY_DATE_ASC';
export const SORT_BY_DATE_DESC = 'SORT_BY_DATE_DESC';
export const SORT_BY_CREATED_AT_ASC = 'SORT_BY_CREATED_AT_ASC';
export const SORT_BY_CREATED_AT_DESC = 'SORT_BY_CREATED_AT_DESC';
export const SORT_BY_UPDATED_AT_ASC = 'SORT_BY_UPDATED_AT_ASC';
export const SORT_BY_UPDATED_AT_DESC = 'SORT_BY_UPDATED_AT_DESC';
export const SORT_BY_PUBLISHED_AT_ASC = 'SORT_BY_PUBLISHED_AT_ASC';
export const SORT_BY_PUBLISHED_AT_DESC = 'SORT_BY_PUBLISHED_AT_DESC';
export const SORT_BY_ORDER_ASC = 'SORT_BY_ORDER_ASC';
export const SORT_BY_ORDER_DESC = 'SORT_BY_ORDER_DESC';
