import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchPlayersSaga(action) {
    try {
        const players = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_FIRST_NAME_ASC:
                sortField = 'firstName';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_FIRST_NAME_DESC:
                sortField = 'firstName';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'firstName';
                sortDirection = 'asc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('players')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            players.push(items);
        });
        yield put(actions.fetchPlayersSuccess(players));
    } catch (error) {
        yield put(actions.fetchPlayersFail());
    }
}

export function* createPlayerSaga(action) {
    try {
        const playerData = {
            ...action.player,
            createdAt: firebase.timestamp,
            createdBy: action.userId,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        yield firebase
            .db()
            .collection('players')
            .add(playerData);
        yield put(actions.fetchPlayers());
        yield put(actions.createPlayerSuccess());
    } catch (error) {
        // console.log('[createPlayerSaga > error]', error);
        yield put(actions.createPlayerFail(error));
    }
}

export function* updatePlayerSaga(action) {
    try {
        const playerData = {
            ...action.player,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const playerId = action.playerId;
        yield firebase
            .db()
            .collection('players')
            .doc(playerId)
            .update(playerData);
        yield put(actions.fetchPlayers());
        yield put(actions.updatePlayerSuccess());
    } catch (error) {
        // console.log('[updatePlayerSaga > error]', error);
        yield put(actions.updatePlayerFail(error));
    }
}

export function* addPlayerToTeamSaga(action) {
    try {
        const playerData = {
            ...action.player,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const playerId = action.playerId;
        yield firebase
            .db()
            .collection('players')
            .doc(playerId)
            .update({
                teams: firebase.arrayUnion(playerData.team)
            });
        yield put(actions.fetchPlayers());
        yield put(actions.addPlayerToTeamSuccess());
    } catch (error) {
        // console.log('[addPlayerToTeamSaga > error]', error);
        yield put(actions.addPlayerToTeamFail(error));
    }
}

export function* removePlayerFromTeamSaga(action) {
    try {
        const playerData = {
            ...action.player,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const playerId = action.playerId;
        yield firebase
            .db()
            .collection('players')
            .doc(playerId)
            .update({
                teams: playerData.teams
            });
        yield put(actions.fetchPlayers());
        yield put(actions.removePlayerFromTeamSuccess());
    } catch (error) {
        // console.log('[removePlayerFromTeamSaga > error]', error);
        yield put(actions.removePlayerFromTeamFail(error));
    }
}

export function* deletePlayerSaga(action) {
    try {
        const playerData = {
            ...action.player
        };
        yield firebase
            .db()
            .collection('players')
            .doc(playerData.id)
            .delete();
        yield put(actions.fetchPlayers());
        yield put(actions.deletePlayerSuccess());
    } catch (error) {
        yield put(actions.deletePlayerFail(error));
    }
}
