import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import firebase from '../../components/Integrations/Firebase';

export function* fetchCurrentProfileSaga(action) {
    try {
        if (action.userId) {
            const querySnapshot = yield firebase
                .db()
                .collection('profiles')
                .doc(action.userId)
                .get();
            const profileData = yield querySnapshot.data();
            const profile = {
                ...profileData,
                id: querySnapshot.id,
                roles: [...profileData.roles, 'authenticated']
            };
            yield put(actions.fetchCurrentProfileSuccess(profile));
        }
    } catch (error) {
        yield put(actions.fetchCurrentProfileFail(error));
    }
}

export function* fetchProfileByIdSaga(action) {
    try {
        if (action.userId) {
            const querySnapshot = yield firebase
                .db()
                .collection('profiles')
                .doc(action.userId)
                .get();
            const profileData = yield querySnapshot.data();
            const profile = { ...profileData, id: querySnapshot.id };
            yield put(actions.fetchProfileByIdSuccess(profile));
        }
    } catch (error) {
        yield put(actions.fetchProfileByIdFail(error));
    }
}

export function* fetchProfileByEmailSaga(action) {
    try {
        let profileData = null;
        if (action.email) {
            const profilesRef = yield firebase.db().collection('profiles');

            const querySnapshot = yield profilesRef.where('email', '==', action.email).get();
            yield querySnapshot.forEach(doc => {
                profileData = doc.data();
            });
            yield put(actions.fetchProfileByEmailSuccess(profileData));
        }
    } catch (error) {
        // console.log('[fetchProfileByEmailSaga > error', error);
        yield put(actions.fetchProfileByEmailFail(error));
    }
}

export function* createProfileSaga(action) {
    try {
        const userId = action.userId;
        const profileData = {
            ...action.profile,
            createdAt: firebase.timestamp,
            updatedAt: firebase.timestamp,
            createdBy: userId,
            updatedBy: userId
        };
        yield firebase
            .db()
            .collection('profiles')
            .doc(action.userId)
            .set(profileData);
        yield put(actions.createProfileSuccess());
    } catch (error) {
        yield put(actions.createProfileFail(error));
    }
}

export function* createAnonymousProfileSaga(action) {

    // Anonymous Profiles will not be stored in Firebase DB (although Users are stored in Firebase Auth)
    try {
        yield put(actions.createAnonymousProfileSuccess(action.profile))
    } catch (error) {
        yield put(actions.createAnonymousProfileFail(error));
    }
}

export function* updateProfileSaga(action) {
    try {
        const profileData = { ...action.profile, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('profiles')
            .doc(action.userId)
            .update(profileData);
            yield put(actions.clearUserSuccessStates());
            yield put(actions.clearUserErrorStates());
        yield put(actions.updateProfileSuccess());
    } catch (error) {
        // console.log('[updateProfileSaga > error', error);
        yield put(actions.updateProfileFail(error));
    }
}

export function* deleteProfileSaga(action) {
    try {
        const userId = action.userId;
        yield firebase
            .db()
            .collection('profiles')
            .doc(userId)
            .delete();
        yield put(actions.deleteProfileSuccess());
    } catch (error) {
        yield put(actions.deleteProfileFail(error));
    }
}

export function* updateEmailSaga(action) {
    try {
        const email = action.email;
        const profile = {
            ...action.profile,
            email: email,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const user = yield firebase.auth().currentUser;
        yield user.updateEmail(email);
        yield put(actions.sendEmailVerification());
        yield put(actions.updateProfile(profile, action.userId));
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.updateEmailSuccess());
    } catch (error) {
        yield put(actions.updateEmailFail(error));
    }
}

export function* updatePasswordSaga(action) {
    // TO DO: Update Profile with updatedBy, updatedAt
    try {
        const user = yield firebase.auth().currentUser;
        yield user.updatePassword(action.password);
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.updatePasswordSuccess());
    } catch (error) {
        yield put(actions.updatePasswordFail(error));
    }
}
