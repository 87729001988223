import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchSeasonsSaga(action) {
    try {
        const seasons = [];
        let query = null;
        let querySnapshot = null;

        // Determine Sort Fields
        let sortField = null;
        let sortDirection = null;
        let sortField2 = null;
        let sortDirection2 = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                sortField2 = 'order';
                sortDirection2 = 'desc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                sortField2 = 'order';
                sortDirection2 = 'desc';
                break;
            case sortTypes.SORT_BY_ORDER_ASC:
                sortField = 'order';
                sortDirection = 'asc';
                sortField2 = 'createdAt';
                sortDirection2 = 'asc';
                break;
            case sortTypes.SORT_BY_ORDER_DESC:
                sortField = 'order';
                sortDirection = 'desc';
                sortField2 = 'createdAt';
                sortDirection2 = 'asc';
                break;
            case sortTypes.SORT_BY_NAME_ASC:
                sortField = 'name';
                sortDirection = 'asc';
                sortField2 = 'createdAt';
                sortDirection2 = 'asc';
                break;
            case sortTypes.SORT_BY_NAME_DESC:
                sortField = 'name';
                sortDirection = 'desc';
                sortField2 = 'createdAt';
                sortDirection2 = 'asc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'asc';
                sortField2 = 'order';
                sortDirection2 = 'desc';
                break;
        }

        // Check if action contains query
        // Requires custom composite index (see error logged if the index does not show)
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'season' || action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            querySnapshot = yield firebase
                .db()
                .collection('seasons')
                .where(query.field, query.operation, query.value)
                .get();
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('seasons')
                .orderBy(sortField, sortDirection)
                .orderBy(sortField2, sortDirection2)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            seasons.push(items);
        });
        yield put(actions.fetchSeasonsSuccess(seasons));
    } catch (error) {
        // console.log('[fetchSeasonsSaga > error]', error);
        yield put(actions.fetchSeasonsFail(error));
    }
}

export function* createSeasonSaga(action) {
    try {
        const userId = action.userId;
        const seasonData = {
            ...action.season,
            createdAt: firebase.timestamp,
            createdBy: userId,
            updatedAt: firebase.timestamp,
            updatedBy: userId
        };
        yield firebase
            .db()
            .collection('seasons')
            .add(seasonData);
        yield put(actions.createSeasonSuccess());
    } catch (error) {
        yield put(actions.createSeasonFail(error));
    }
}

export function* updateSeasonSaga(action) {
    try {
        const seasonData = { ...action.season, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('seasons')
            .doc(action.seasonId)
            .update(seasonData);
        yield put(actions.updateSeasonSuccess());
    } catch (error) {
        yield put(actions.updateSeasonFail(error));
    }
}

export function* pinSeasonSaga(action) {
    try {
        // Highest order number is prioritized
        const querySnapshot = yield firebase
            .db()
            .collection('seasons')
            .orderBy('order', 'desc')
            .limit(1)
            .get();

        // let currentHighestOrderSeason = null;
        let newHighestOrder = 1;

        if (!querySnapshot.empty) {
            // currentHighestOrderSeason = yield querySnapshot[0].data();
            yield querySnapshot.forEach((doc, index) => {
                if (index === 0) {
                    let items = doc.data();
                    items = {
                        ...items,
                        id: doc.id
                    };
                    newHighestOrder = items;
                }
            });
            // newHighestOrder = currentHighestOrderSeason.order + 1;
        }

        // Update season with new highest order number
        const seasonData = { ...action.season, order: newHighestOrder, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('seasons')
            .doc(action.seasonId)
            .update(seasonData);
        yield put(actions.pinSeasonSuccess());
    } catch (error) {
        // console.log('[pinSeasonSaga > error]', error);
        yield put(actions.pinSeasonFail(error));
    }
}

export function* removePinFromSeasonSaga(action) {
    try {
        // Update season with new highest order number
        const seasonData = { ...action.season, order: 0, updatedAt: firebase.timestamp, updatedBy: action.userId };

        yield firebase
            .db()
            .collection('seasons')
            .doc(action.seasonId)
            .update(seasonData);
        yield put(actions.removePinFromSeasonSuccess());

    } catch (error) {
        // console.log('[removePinFromSeasonFail > error]', error);
        yield put(actions.removePinFromSeasonFail(error));
    }
}

export function* deleteSeasonSaga(action) {
    try {
        const seasonData = { ...action.season };
        yield firebase
            .db()
            .collection('seasons')
            .doc(seasonData.id)
            .delete();
        yield put(actions.fetchSeasons());
        yield put(actions.deleteSeasonSuccess());
    } catch (error) {
        yield put(actions.deleteSeasonFail(error));
    }
}
