import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as actions from '../../../store/actions';
import * as sortTypes from '../../../utilities/helpers/types';
import GamesBySeasonAndLocation from '../GamesBySeasonAndLocation/GamesBySeasonAndLocation';

const initialSortState = sortTypes.SORT_BY_DATE_ASC;
const initialFilterDateState = moment().format('YYYY-MM-DD');

const ViewGamesByDate = props => {
    // Initialize Props from Component
    const { selectedDate, filteredSeasons } = props;

    // Initializing State for Initial Load, Sort, Filter
    const [sortState] = useState(initialSortState);
    const [filterDateState, setFilterDateState] = useState(selectedDate ? selectedDate : initialFilterDateState);

    // Update dateInWeek to match selectedDate
    useEffect(() => {
        if (selectedDate !== filterDateState) {
            setFilterDateState(selectedDate);
        }
    }, [selectedDate, filterDateState]);

    // Initialize Props From Store
    const {
        websiteBranding,
        gamesByDate,
        leagues,
        seasons,
        teams,
        locations,
        onFetchWebsiteBranding,
        onFetchGamesByDate,
        onFetchLeagues,
        onFetchSeasons,
        onFetchTeams,
        onFetchLocations,
        loading,
        error
    } = props;

    // Set Date
    useEffect(() => {
        if (selectedDate !== filterDateState) {
            setFilterDateState(selectedDate);
        }
    }, [selectedDate, filterDateState]);

    // Fetch Data (Games, Leagues, Seasons, Teams, Location)
    const setGamesHandler = useCallback(
        (sort, filterDate) => {
            const date = {
                date: filterDate
            };
            onFetchGamesByDate(sort, date);
        },
        [onFetchGamesByDate]
    );

    useEffect(() => {
        setGamesHandler(sortState, filterDateState);
        if (!leagues) {
            onFetchLeagues();
        }
        if (!seasons) {
            onFetchSeasons();
        }
        if (!teams) {
            onFetchTeams();
        }
        if (!locations) {
            onFetchLocations();
        }
    }, [
        setGamesHandler,
        sortState,
        filterDateState,
        leagues,
        seasons,
        teams,
        locations,
        onFetchLeagues,
        onFetchSeasons,
        onFetchTeams,
        onFetchLocations
    ]);

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    return (
        <GamesBySeasonAndLocation
            branding={websiteBranding}
            leagues={leagues}
            seasons={filteredSeasons ? filteredSeasons : seasons}
            teams={teams}
            locations={locations}
            games={gamesByDate}
            loading={loading}
            error={error}
        />
    );
};

const mapStateToProps = state => {
    return {
        websiteBranding: state.websiteConfiguration.websiteBranding,
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        teams: state.teams.teams,
        locations: state.locations.locations,
        gamesByDate: state.games.gamesByDate,
        fetchGamesSuccess: state.games.fetchGamesSuccess,
        error: state.games.error,
        loading: state.games.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchGamesByDate: (sort, filterDate) => dispatch(actions.fetchGamesByDate(sort, filterDate)),
        onFetchLeagues: sort => dispatch(actions.fetchLeagues(sort)),
        onFetchSeasons: sort => dispatch(actions.fetchSeasons(sort)),
        onFetchTeams: sort => dispatch(actions.fetchTeams(sort)),
        onFetchLocations: () => dispatch(actions.fetchLocations())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewGamesByDate);
