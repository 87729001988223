// USER ACTIONS

import * as actionTypes from './actionTypes';

export const fetchCurrentProfile = (userId) => {
    return {
        type: actionTypes.FETCH_CURRENT_PROFILE,
        userId: userId
    };
}

export const fetchCurrentProfileSuccess = (profile) => {
    return {
        type: actionTypes.FETCH_CURRENT_PROFILE_SUCCESS,
        profile: profile
    };
}

export const fetchCurrentProfileFail = (error) => {
    return {
        type: actionTypes.FETCH_CURRENT_PROFILE_FAIL,
        error: error
    };
}

export const fetchProfileById = (userId) => {
    return {
        type: actionTypes.FETCH_PROFILE_BY_ID,
        userId: userId
    };
}

export const fetchProfileByIdSuccess = (profile) => {
    return {
        type: actionTypes.FETCH_PROFILE_BY_ID_SUCCESS,
        profile: profile
    };
}

export const fetchProfileByIdFail = (error) => {
    return {
        type: actionTypes.FETCH_PROFILE_BY_ID_FAIL,
        error: error
    };
}

export const fetchProfileByEmail = (email) => {
    return {
        type: actionTypes.FETCH_PROFILE_BY_EMAIL,
        email: email
    };
}

export const fetchProfileByEmailSuccess = (profile) => {
    return {
        type: actionTypes.FETCH_PROFILE_BY_EMAIL_SUCCESS,
        profile: profile
    };
}

export const fetchProfileByEmailFail = (error) => {
    return {
        type: actionTypes.FETCH_PROFILE_BY_EMAIL_FAIL,
        error: error
    };
}

export const createProfile = (profile, userId) => {
    return {
        type: actionTypes.CREATE_PROFILE,
        profile: profile,
        userId: userId
    };
}

export const createProfileSuccess = () => {
    return {
        type: actionTypes.CREATE_PROFILE_SUCCESS
    };
}

export const createProfileFail = (error) => {
    return {
        type: actionTypes.CREATE_PROFILE_FAIL,
        error: error
    };
}

export const createAnonymousProfile = (profile) => {
    return {
        type: actionTypes.CREATE_ANONYMOUS_PROFILE,
        profile: profile
    };
}

export const createAnonymousProfileSuccess = (profile) => {
    return {
        type: actionTypes.CREATE_ANONYMOUS_PROFILE_SUCCESS,
        profile: profile
    };
}

export const createAnonymousProfileFail = (error) => {
    return {
        type: actionTypes.CREATE_ANONYMOUS_PROFILE_FAIL,
        error: error
    };
}

export const updateProfile = (profile, userId) => {
    return {
        type: actionTypes.UPDATE_PROFILE,
        profile: profile,
        userId: userId
    };
}

export const updateProfileSuccess = () => {
    return {
        type: actionTypes.UPDATE_PROFILE_SUCCESS
    };
}

export const updateProfileFail = (error) => {
    return {
        type: actionTypes.UPDATE_PROFILE_FAIL,
        error: error
    };
}

export const deleteProfile = (userId) => {
    return {
        type: actionTypes.DELETE_PROFILE,
        userId: userId
    };
}

export const deleteProfileSuccess = () => {
    return {
        type: actionTypes.DELETE_PROFILE_SUCCESS
    };
}

export const deleteProfileFail = (error) => {
    return {
        type: actionTypes.DELETE_PROFILE_FAIL,
        error: error
    };
}

export const updateEmail = (profile, email, userId) => {
    return {
        type: actionTypes.UPDATE_EMAIL,
        profile: profile,
        email: email,
        userId: userId
    };
}

export const updateEmailSuccess = () => {
    return {
        type: actionTypes.UPDATE_EMAIL_SUCCESS
    };
}

export const updateEmailFail = (error) => {
    return {
        type: actionTypes.UPDATE_EMAIL_FAIL,
        error: error
    };
}

export const updatePassword = (password) => {
    return {
        type: actionTypes.UPDATE_PASSWORD,
        password: password
    };
}

export const updatePasswordSuccess = () => {
    return {
        type: actionTypes.UPDATE_PASSWORD_SUCCESS
    };
}

export const updatePasswordFail = (error) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_FAIL,
        error: error
    };
}

export const clearUserSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_USER_SUCCESS_STATES
    };
}

export const clearUserErrorStates = () => {
    return {
        type: actionTypes.CLEAR_USER_ERROR_STATES
    };
}