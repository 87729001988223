const getLeagueName = (leagueId, leagues) => {

    // Initialize component with leagueId from props
    let leagueName = '';

    if (leagueId && leagues) {
        const leagueRef = leagues.filter(league => league.id === leagueId)
        if (leagueRef.length > 0) {
            leagueName = leagueRef[0].name
        }
    }

    return leagueName
}

export default getLeagueName;