// PLAYERS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    players: null,
    createPlayerSuccess: null,
    updatePlayerSuccess: null,
    addPlayerToTeamSuccess: null,
    removePlayerFromTeamSuccess: null,
    deletePlayerSuccess: null,
    error: null,
    loading: false
}

const fetchPlayers = (state, action) => {
    return updateObject( state, {
        error: null, 
        loading: true 
    });
}

const fetchPlayersSuccess = (state, action) => {
    return updateObject( state, {
        players: action.players,
        error: null,
        loading: false
    });
}

const fetchPlayersFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
}

const createPlayer = (state, action) => {
    return updateObject( state, {
        createPlayerSuccess: null,
        error: null, 
        loading: true 
    });
}

const createPlayerSuccess = (state, action) => {
    return updateObject( state, {
        createPlayerSuccess: true,
        error: null,
        loading: false
    });
}

const createPlayerFail = (state, action) => {
    return updateObject( state, {
        createPlayerSuccess: false,
        error: action.error,
        loading: false
    });
}

const updatePlayer = (state, action) => {
    return updateObject( state, {
        updatePlayerSuccess: null,
        error: null, 
        loading: true 
    });
}

const updatePlayerSuccess = (state, action) => {
    return updateObject( state, {
        updatePlayerSuccess: true,
        error: null,
        loading: false
    });
}

const updatePlayerFail = (state, action) => {
    return updateObject( state, {
        updatePlayerSuccess: false,
        error: action.error, 
        loading: false
    });
}

const addPlayerToTeam = (state, action) => {
    return updateObject( state, {
        addPlayerToTeamSuccess: null,
        error: null, 
        loading: true 
    });
}

const addPlayerToTeamSuccess = (state, action) => {
    return updateObject( state, {
        addPlayerToTeamSuccess: true,
        error: null,
        loading: false
    });
}

const addPlayerToTeamFail = (state, action) => {
    return updateObject( state, {
        addPlayerToTeamSuccess: false,
        error: action.error, 
        loading: false
    });
}

const removePlayerFromTeam = (state, action) => {
    return updateObject( state, {
        removePlayerFromTeamSuccess: null,
        error: null, 
        loading: true 
    });
}

const removePlayerFromTeamSuccess = (state, action) => {
    return updateObject( state, {
        removePlayerFromTeamSuccess: true,
        error: null,
        loading: false
    });
}

const removePlayerFromTeamFail = (state, action) => {
    return updateObject( state, {
        removePlayerFromTeamSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deletePlayer = (state, action) => {
    return updateObject( state, {
        deletePlayerSuccess: null,
        error: null, 
        loading: true 
    });
}

const deletePlayerSuccess = (state, action) => {
    return updateObject( state, {
        deletePlayerSuccess: true,
        error: null, 
        loading: false 
    });
}

const deletePlayerFail = (state, action) => {
    return updateObject( state, {
        deletePlayerSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearPlayerSuccessStates = (state, action) => {
    return updateObject( state, {
        createPlayerSuccess: null,
        updatePlayerSuccess: null,
        addPlayerToTeamSuccess: null,
        removePlayerFromTeamSuccess: null,
        deletePlayerSuccess: null
    });
}

const clearPlayerErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PLAYERS: return fetchPlayers(state, action);
        case actionTypes.FETCH_PLAYERS_SUCCESS: return fetchPlayersSuccess(state, action);
        case actionTypes.FETCH_PLAYERS_FAIL: return fetchPlayersFail(state, action);
        case actionTypes.CREATE_PLAYER: return createPlayer(state, action);
        case actionTypes.CREATE_PLAYER_SUCCESS: return createPlayerSuccess(state, action);
        case actionTypes.CREATE_PLAYER_FAIL: return createPlayerFail(state, action);
        case actionTypes.UPDATE_PLAYER: return updatePlayer(state, action);
        case actionTypes.UPDATE_PLAYER_SUCCESS: return updatePlayerSuccess(state, action);
        case actionTypes.UPDATE_PLAYER_FAIL: return updatePlayerFail(state, action);
        case actionTypes.ADD_PLAYER_TO_TEAM: return addPlayerToTeam(state, action);
        case actionTypes.ADD_PLAYER_TO_TEAM_SUCCESS: return addPlayerToTeamSuccess(state, action);
        case actionTypes.ADD_PLAYER_TO_TEAM_FAIL: return addPlayerToTeamFail(state, action);
        case actionTypes.REMOVE_PLAYER_FROM_TEAM: return removePlayerFromTeam(state, action);
        case actionTypes.REMOVE_PLAYER_FROM_TEAM_SUCCESS: return removePlayerFromTeamSuccess(state, action);
        case actionTypes.REMOVE_PLAYER_FROM_TEAM_FAIL: return removePlayerFromTeamFail(state, action);
        case actionTypes.DELETE_PLAYER: return deletePlayer(state, action);
        case actionTypes.DELETE_PLAYER_SUCCESS: return deletePlayerSuccess(state, action);
        case actionTypes.DELETE_PLAYER_FAIL: return deletePlayerFail(state, action);
        case actionTypes.CLEAR_PLAYER_SUCCESS_STATES: return clearPlayerSuccessStates(state, action);
        case actionTypes.CLEAR_PLAYER_ERROR_STATES: return clearPlayerErrorStates(state, action);
        default: return state;
    }
}

export default reducer;