import React from 'react';
import { Table, Badge } from 'reactstrap';
import moment from 'moment';

import styles from './GamesTableByTimeAndCourts.module.css';
import getTeamName from '../../../utilities/helpers/getTeamName';

const GamesTableByTimeAndCourts = props => {
    // Initialize Props from Component
    const { branding, listOfMatchups, teams } = props;

    // Set Branding Variables in CSS
    if (branding) {
        document.documentElement.style.setProperty('--bodyPrimaryColor', branding.theme.bodyPrimaryColor);
        document.documentElement.style.setProperty('--mainPanelBackgroundColor', branding.theme.mainPanelBackgroundColor);
        document.documentElement.style.setProperty('--mainPanelHeadingTextColor', branding.theme.mainPanelHeadingTextColor);
        document.documentElement.style.setProperty('--mainPanelSubtitleTextColor', branding.theme.mainPanelSubtitleTextColor);
        document.documentElement.style.setProperty('--mainPanelBodyTextColor', branding.theme.mainPanelBodyTextColor);
    }

    return (
        <Table responsive>
            <thead>
                <tr className={styles.TableHeading}>
                    <th>Date</th>
                    <th>Time</th>
                    {listOfMatchups.courts.map((court, courtIndex) => {
                        return <th key={courtIndex}>{court}</th>;
                    })}
                </tr>
            </thead>
            <tbody className={styles.TableBody}>
                {listOfMatchups.rows.map((matchup, matchupIndex) => {
                    return (
                        <tr key={matchupIndex}>
                            <td>{matchup.date ? moment(matchup.date).format('MMM Do') : null}</td>
                            <td>{moment(matchup.date + ' ' + matchup.time).format('h:mm A')}</td>
                            {listOfMatchups.courts.map(court => {
                                let isGameInCourt = false;
                                let doesGameExistInCourt = false;

                                return matchup.games.map((game, gameIndex) => {
                                    if (game.court === court) {
                                        doesGameExistInCourt = true;
                                        isGameInCourt = true;
                                    } else {
                                        isGameInCourt = false;
                                    }

                                    if (gameIndex >= matchup.games.length - 1 && !doesGameExistInCourt) {
                                        return (
                                            <td key={gameIndex} colSpan='1'>
                                                {' '}
                                            </td>
                                        );
                                    } else if (isGameInCourt) {
                                        const teamHomeName = getTeamName(game.teamHome, teams);
                                        const teamAwayName = getTeamName(game.teamAway, teams);
                                        return (
                                            <td key={game} style={{ height: '2rem' }}>
                                                <strong>{teamHomeName ? teamHomeName : null}</strong>{' '}
                                                {game.teamHomeScore ? '(' + game.teamHomeScore + ')' : null}
                                                {game.teamHome === game.winningTeam ? (
                                                    <Badge color='primary' className='m-1 p-1'>
                                                        Winner
                                                    </Badge>
                                                ) : null}
                                                {' vs. '}
                                                <strong>{teamAwayName ? teamAwayName : null}</strong>{' '}
                                                {game.teamAwayScore ? '(' + game.teamAwayScore + ')' : null}
                                                {game.teamAway === game.winningTeam ? (
                                                    <Badge color='primary' className='m-1 p-1'>
                                                        Winner
                                                    </Badge>
                                                ) : null}
                                                {game.winningTeam === 'Tied' ? (
                                                    <Badge color='secondary' className='m-1 p-1'>
                                                        Tied
                                                    </Badge>
                                                ) : null}
                                            </td>
                                        );
                                    }
                                    return null;
                                });
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default GamesTableByTimeAndCourts;
