// LOCATIONS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    locations: null,
    createLocationSuccess: null,
    updateLocationSuccess: null,
    deleteLocationSuccess: null,
    error: null,
    loading: false
};

const fetchLocations = (state, action) => {
    return updateObject(state, {
        error: null,
        loading: true
    });
};

const fetchLocationsSuccess = (state, action) => {
    return updateObject(state, {
        locations: action.locations,
        error: null,
        loading: false
    });
};

const fetchLocationsFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const createLocation = (state, action) => {
    return updateObject(state, {
        createLocationSuccess: null,
        error: null,
        loading: true
    });
};

const createLocationSuccess = (state, action) => {
    return updateObject(state, {
        createLocationSuccess: true,
        error: null,
        loading: false
    });
};

const createLocationFail = (state, action) => {
    return updateObject(state, {
        createLocationSuccess: false,
        error: action.error,
        loading: false
    });
};

const updateLocation = (state, action) => {
    return updateObject(state, {
        updateLocationSuccess: null,
        error: null,
        loading: true
    });
};

const updateLocationSuccess = (state, action) => {
    return updateObject(state, {
        updateLocationSuccess: true,
        error: null,
        loading: false
    });
};

const updateLocationFail = (state, action) => {
    return updateObject(state, {
        updateLocationSuccess: false,
        error: action.error,
        loading: false
    });
};

const deleteLocation = (state, action) => {
    return updateObject(state, {
        deleteLocationSuccess: null,
        error: null,
        loading: true
    });
};

const deleteLocationSuccess = (state, action) => {
    return updateObject(state, {
        deleteLocationSuccess: true,
        error: null,
        loading: false
    });
};

const deleteLocationFail = (state, action) => {
    return updateObject(state, {
        deleteLocationSuccess: false,
        error: action.error,
        loading: false
    });
};

const clearLocationSuccessStates = (state, action) => {
    return updateObject(state, {
        createLocationSuccess: null,
        updateLocationSuccess: null,
        deleteLocationSuccess: null
    });
};
const clearLocationErrorStates = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LOCATIONS: return fetchLocations(state, action);
        case actionTypes.FETCH_LOCATIONS_SUCCESS: return fetchLocationsSuccess(state, action);
        case actionTypes.FETCH_LOCATIONS_FAIL: return fetchLocationsFail(state, action);
        case actionTypes.CREATE_LOCATION: return createLocation(state, action);
        case actionTypes.CREATE_LOCATION_SUCCESS: return createLocationSuccess(state, action);
        case actionTypes.CREATE_LOCATION_FAIL: return createLocationFail(state, action);
        case actionTypes.UPDATE_LOCATION: return updateLocation(state, action);
        case actionTypes.UPDATE_LOCATION_SUCCESS: return updateLocationSuccess(state, action);
        case actionTypes.UPDATE_LOCATION_FAIL: return updateLocationFail(state, action);
        case actionTypes.DELETE_LOCATION: return deleteLocation(state, action);
        case actionTypes.DELETE_LOCATION_SUCCESS: return deleteLocationSuccess(state, action);
        case actionTypes.DELETE_LOCATION_FAIL: return deleteLocationFail(state, action);
        case actionTypes.CLEAR_LOCATION_SUCCESS_STATES: return clearLocationSuccessStates(state, action);
        case actionTypes.CLEAR_LOCATION_ERROR_STATES: return clearLocationErrorStates(state, action);
        default: return state;
    }
};

export default reducer;
