import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';

import styles from './PublishedAlertsBanner.module.css';
import * as actions from '../../../store/actions';
import * as sortTypes from '../../../utilities/helpers/types';

const initialSortState = sortTypes.SORT_BY_PUBLISHED_AT_DESC;

const PublishedAlertsBanner = props => {
    // Initializing Props from Store
    const { websiteBranding, publishedAlerts, onFetchWebsiteBranding, onFetchPublishedAlerts } = props;

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Get Alerts
    useEffect(() => {
        if (!publishedAlerts) {
            onFetchPublishedAlerts(initialSortState);
        }
    }, [publishedAlerts, onFetchPublishedAlerts]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--alertsBackgroundColor', branding.theme.alertsBackgroundColor);
            document.documentElement.style.setProperty('--alertsHeadingTextColor', branding.theme.alertsHeadingTextColor);
            document.documentElement.style.setProperty('--alertsSubtitleTextColor', branding.theme.alertsSubtitleTextColor);
            document.documentElement.style.setProperty('--alertsBodyTextColor', branding.theme.alertsBodyTextColor);
        }
    });


    // Display Errors
    let alertError = null;
    if (props.errors) {
        alertError = (
            <Alert color='danger'>
                <p>{props.error.toString()}</p>
            </Alert>
        );
    }

    // Display Alerts
    let alertListGroup = [];

    if (publishedAlerts && props.profile) {
        alertListGroup = publishedAlerts
            ? publishedAlerts.map((alert, index) => (
                  <div key={index} className={styles.AlertBox}>
                      <div className={styles.AlertHeading}>
                          <h4>
                              <i className={styles.AlertIcon + ' fa fa-exclamation-triangle'} color='white' />
                          </h4>
                      </div>
                      <div className={styles.AlertText}>
                          <div className={styles.AlertTitle}>
                              {alert.title ? (
                                  <h4>
                                      <strong>{alert.title}</strong>
                                  </h4>
                              ) : null}
                          </div>
                          <div className={styles.AlertSubtitle}>
                              {alert.subtitle && alert.displaySubtitle ? (
                                  <>
                                      <small>{alert.subtitle}</small>
                                      <br />
                                  </>
                              ) : null}
                              {alert.author && alert.displayAuthor ? <small>From: {alert.author}</small> : null}
                          </div>
                          <div className={styles.AlertSummary}>{alert.summary ? alert.summary : null}</div>
                      </div>
                  </div>
              ))
            : null;
    }

    return (
        <div className={styles.PublishedAlertsBanner}>
            {alertError}
            {alertListGroup}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.user.currentProfile,
        websiteBranding: state.websiteConfiguration.websiteBranding,
        publishedAlerts: state.alerts.publishedAlerts,
        error: state.alerts.error,
        loading: state.alerts.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchPublishedAlerts: sort => dispatch(actions.fetchPublishedAlerts(sort))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishedAlertsBanner);
