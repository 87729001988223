import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import moment from 'moment';

import styles from './SingleRowDatePicker.module.css';

const daysToShow = 7;

const SingleRowDatePicker = props => {

    // Initialize Props from Component
    const { branding, selectedDate, onDateChanged, nextGameDate } = props;
    const [dateInWeek, setDateInWeek] = useState(moment(selectedDate));

    // Set Branding Variables in CSS
    if (branding) {
        document.documentElement.style.setProperty('--bodyPrimaryColor', branding.theme.bodyPrimaryColor);
        document.documentElement.style.setProperty('--mainPanelBackgroundColor', branding.theme.mainPanelBackgroundColor);
        document.documentElement.style.setProperty('--mainPanelHeadingTextColor', branding.theme.mainPanelHeadingTextColor);
        document.documentElement.style.setProperty('--mainPanelSubtitleTextColor', branding.theme.mainPanelSubtitleTextColor);
        document.documentElement.style.setProperty('--mainPanelBodyTextColor', branding.theme.mainPanelBodyTextColor);
    }

    // Update dateInWeek to match selectedDate
    useEffect(() => {
        if (selectedDate !== dateInWeek) {
            setDateInWeek(selectedDate);
        }
    }, [selectedDate, dateInWeek]);

    // Handlers
    const handlePreviousWeek = () => {
        const dateInPreviousWeek = moment(dateInWeek)
            .subtract(7, 'days')
            .format('YYYY-MM-DD');
        onDateChanged(dateInPreviousWeek);
    };

    const handleNextWeek = () => {
        const dateInPreviousWeek = moment(dateInWeek)
            .add(7, 'days')
            .format('YYYY-MM-DD');
        onDateChanged(dateInPreviousWeek);
    };

    const handlePreviousDay = () => {
        const dateInPreviousWeek = moment(dateInWeek)
            .subtract(1, 'days')
            .format('YYYY-MM-DD');
        onDateChanged(dateInPreviousWeek);
    };

    const handleNextDay = () => {
        const dateInPreviousWeek = moment(dateInWeek)
            .add(1, 'days')
            .format('YYYY-MM-DD');
        onDateChanged(dateInPreviousWeek);
    };

    // Display Days of Week
    const currentDateMoment = moment(dateInWeek);
    const startOfWeek = moment(currentDateMoment).startOf('week');
    const weekDates = [];

    let dateIndex = moment(startOfWeek);
    for (let i = 0; i < daysToShow; i++) {
        weekDates.push(moment(dateIndex));
        dateIndex = dateIndex.add(1, 'days');
    }

    // Display Month on Schedule Picker
    const displayMonth = [];
    weekDates.reduce((accumulator, date) => {
        if (accumulator) {
            if (date.month() !== accumulator.month()) {
                displayMonth.push(
                    <th key={date.format('X')}>
                        <div>{date.format('MMM')}</div>
                    </th>
                );
                return date;
            } else {
                displayMonth.push(<th key={date.format('X')}></th>);
                return date;
            }
        } else {
            displayMonth.push(
                <th key={date.format('X')}>
                    <div>{date.format('MMM')}</div>
                </th>
            );
            return date;
        }
    }, null);

    // Display Days Of Week on Schedule Picker
    const daysOfWeek = weekDates.map(date => (
        <th key={date.format('X')} className={styles.TableCell}>
            <div onClick={() => onDateChanged(date.format('YYYY-MM-DD'))}>{date.format('ddd')}</div>
        </th>
    ));

    // Display Days Of Week on Schedule Picker
    const datesOfWeek = weekDates.map(date => {
        const dateClassNames = [styles.TableCellDate];
        if (moment(date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            dateClassNames.push(styles.Today);
        }
        if (moment(date).format('YYYY-MM-DD') === moment(dateInWeek).format('YYYY-MM-DD')) {
            dateClassNames.push(styles.SelectedDate);
        }

        return (
            <td key={moment(date).format('X')} className={styles.TableCell}>
                <div
                    className={dateClassNames.join(' ')}
                    onClick={() => {
                        setDateInWeek(moment(date).format('YYYY-MM-DD'));
                        onDateChanged(moment(date).format('YYYY-MM-DD'));
                    }}
                >
                    <div className={styles.TableCellDateDiv}>{moment(date).format('DD')}</div>
                </div>
            </td>
        );
    });

    // Display Date Heading
    let nextGameHeading = null;
    if (moment(dateInWeek).format('YYYY-MM-DD') === moment(nextGameDate).format('YYYY-MM-DD')) {
        nextGameHeading = 'Next Game';
    }

    let dateRelationToToday = null;
    if (moment(dateInWeek).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        dateRelationToToday = 'Today';
    } else if (moment(dateInWeek).diff(moment(), 'days') < 7 && moment(dateInWeek).diff(moment(), 'days') >= 0) {
        dateRelationToToday = 'This Week';
    } else if (moment(dateInWeek).diff(moment(), 'days') <= 0) {
        dateRelationToToday = 'Previously';
    }

    let dateHeading = (
        <div>
            {nextGameHeading ? (
                <>
                    <strong>{nextGameHeading}</strong>
                    <br />
                </>
            ) : null}
            {dateRelationToToday ? (
                <>
                    <strong>{dateRelationToToday}</strong> |{' '}
                </>
            ) : null}
            {moment(dateInWeek).format('LL')}
        </div>
    );

    return (
        <>
            <div className={styles.SingleRowDatePicker}>
                <div className={styles.ArrowContainer}>
                    <Button outline color='primary' className={styles.Arrow} onClick={handlePreviousWeek}>
                        <i className='fa fa-caret-left' />
                    </Button>
                </div>
                <Table className={styles.Table} size='sm'>
                    <thead className={styles.TableHeading}>
                        <tr>{displayMonth}</tr>
                        <tr>{daysOfWeek}</tr>
                    </thead>
                    <tbody className={styles.TableBody}>
                        <tr>{datesOfWeek}</tr>
                    </tbody>
                </Table>
                <div className={styles.ArrowContainer}>
                    <Button outline color='primary' className={styles.Arrow} onClick={handleNextWeek}>
                        <i className='fa fa-caret-right' />
                    </Button>
                </div>
            </div>
            <div className={styles.DateHeading}>
                <Button outline color='primary' className={styles.Arrow} onClick={handlePreviousDay}>
                    <i className='fa fa-caret-left' />
                </Button>

                {dateHeading}

                <Button outline color='primary' className={styles.Arrow} onClick={handleNextDay}>
                    <i className='fa fa-caret-right' />
                </Button>
            </div>
        </>
    );
};

export default SingleRowDatePicker;
