const check = (rules, roles, action, data) => {
    const permissionsByRole = [];

    if (roles) {
        roles.forEach(role => {
            permissionsByRole.push(rules[role]);
        });
    }

    // Flatten Permissions Array

    const permissions = {
        static: [],
        dynamic: []
    };

    permissionsByRole.forEach(permission => {
        if (permission) {
            if (permission.static) {
                permission.static.forEach(staticPermission => {
                    permissions.static.push(staticPermission);
                });
            }
            if (permission.dynamic) {
                permissions.dynamic.forEach(dynamicPermission => {
                    permissions.dynamic.push(dynamicPermission);
                });
            }
        }
    });

    if (!permissions) {
        // role is not present in the rules
        // console.log('[Can.js > Role not present]');
        return false;
    }

    const staticPermissions = permissions.static;

    if (staticPermissions && staticPermissions.includes(action)) {
        // static rule provided for action
        // console.log('[check.js > action, staticPermissions]', action, staticPermissions);
        return true;
    } else {
        // console.log('[Can.js > Static rule not provided for action > action, staticPermissions]', action, staticPermissions);
    }

    const dynamicPermissions = permissions.dynamic;

    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        if (!permissionCondition) {
            // dynamic rule not provided for action
            // console.log('[Can.js > Dynamic rule not provided for action > dynamicPermissions]');
            return false;
        }

        return permissionCondition(data);
    }
    return false;
};

export default check;
