import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import styles from './League.module.css';
import * as actions from '../../store/actions';
import About from '../../components/About/About';
import Banner from '../../components/UI/Banner/Banner';
import ViewUpcomingGamesByLocation from '../Games/ViewUpcomingGamesByLocation/ViewUpcomingGamesByLocation';
import ViewCompletedGamesByLocation from '../Games/ViewCompletedGamesByLocation/ViewCompletedGamesByLocation';
import Standings from '../Website/Standings/Standings';
import Teams from '../Website/Teams/Teams';

const League = props => {
    // Initialize path:
    // League Path structure: '/leagues/', ':leagueId', '/', ':view'
    // Season Path structure: '/leagues/', ':leagueId', '/season/', ':seasonId', '/', ':view'
    const { leagues, seasons, onFetchLeagues, onFetchSeasons } = props;
    const leagueId = props.match.params.leagueId;
    const seasonId = props.match.params.seasonId;
    const view = props.match.params.view;

    let leagueComponent = null;
    let seasonComponent = null;

    // Get Leagues & Seasons
    useEffect(() => {
        if (!leagues) {
            onFetchLeagues();
        }
    }, [leagues, onFetchLeagues]);

    useEffect(() => {
        if (!seasons) {
            onFetchSeasons();
        }
    }, [seasons, onFetchSeasons]);

    if (leagues && seasons) {
        if (view === 'about') {
            leagues
                .filter(league => league.id === leagueId)
                .map(() => {
                    leagueComponent = <About />;
                    return null;
                });
        } else {
            seasons
                .filter(season => season.id === seasonId)
                .map(season => {
                    if (view === 'schedule') {
                        seasonComponent = <ViewUpcomingGamesByLocation filteredSeasons={[season]} heading={'Upcoming Games'} />;
                    } else if (view === 'scores') {
                        seasonComponent = <ViewCompletedGamesByLocation filteredSeasons={[season]} heading={'Scores'} />;
                    } else if (view === 'standings') {
                        seasonComponent = <Standings filteredSeasons={[season]} heading={'Standings'} />;
                    } else if (view === 'teams') {
                        seasonComponent = <Teams filteredSeasons={[season]} heading={'Teams'} />;
                    }
                    return null;
                });
        }
    }

    return (
        <div className={styles.League}>
            <Banner />
            <main>
                <div className={styles.LeagueContent}>{leagueComponent ? leagueComponent : seasonComponent ? seasonComponent : null}</div>
            </main>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        error: state.seasons.error,
        loading: state.seasons.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchLeagues: sort => dispatch(actions.fetchLeagues(sort)),
        onFetchSeasons: sort => dispatch(actions.fetchSeasons(sort))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(League)
);
