import React from 'react';
import { Alert, Badge } from 'reactstrap';

import GamesTableByTimeAndCourts from '../GamesTableByTimeAndCourts/GamesTableByTimeAndCourts';
import Spinner from '../../../components/UI/Spinner/Spinner';
import getLeagueAndSeasonName from '../../../utilities/helpers/getLeagueAndSeasonName';

const GamesBySeasonAndLocation = props => {
    // Initialize Props from Component
    const { branding, typeOfGame } = props;
    const gameUnit = typeOfGame ? typeOfGame : 'games';
    let gamesNotFound = true;

    // Initialize Props from Store
    const { leagues, seasons, teams, locations, games, loading, error } = props;

    // Display Errors
    let alert = null;

    if (error) {
        console.log('[Games.js > error]', error.toString());
        alert = (
            <Alert color='danger'>
                <p>Sorry, everything went wrong! Please try again later.</p>
                <p>{error.toString()}</p>
            </Alert>
        );
    }

    // Display List of Seasons as Cards
    let seasonAndLocationCards = null;

    if (loading) {
        seasonAndLocationCards = <Spinner />;
    }

    if (games && leagues && seasons && teams && locations) {
        seasonAndLocationCards = seasons.map(season => {
            const listOfLocations = locations.map(location => {
                let rowIndex = 0;

                const listOfMatchups = games
                    .filter(game => game.season === season.id && game.location === location.id)
                    .reduce(
                        (accumulator, game) => {
                            gamesNotFound = false;
                            if (accumulator.rows.length > 0) {
                                // If same date / time, add courts and team games to the accumulators
                                if (accumulator.rows[rowIndex].date === game.date && accumulator.rows[rowIndex].time === game.time) {
                                    // Check if court exists in accumulator
                                    if (game.court && accumulator.courts.every(court => court !== game.court)) {
                                        accumulator.courts.push(game.court);
                                    }

                                    accumulator.rows[rowIndex].games.push(game);

                                    return accumulator;
                                }

                                // If same date, but not the same time, add a new row
                                if (accumulator.rows[rowIndex].date === game.date) {
                                    rowIndex++;
                                    accumulator.rows.push({
                                        date: game.date ? game.date : null,
                                        time: game.time ? game.time : null,
                                        games: game.id ? [game] : []
                                    });
                                    if (game.court && accumulator.courts.every(court => court !== game.court)) {
                                        accumulator.courts.push(game.court);
                                    }
                                    return accumulator;
                                }

                                // If not the same date, add a new row
                                rowIndex++;
                                accumulator.rows.push({
                                    date: game.date ? game.date : null,
                                    time: game.time ? game.time : null,
                                    games: game.id ? [game] : []
                                });
                                if (game.court && accumulator.courts.every(court => court !== game.court)) {
                                    accumulator.courts.push(game.court);
                                }
                                return accumulator;
                            } else {
                                // On first run, create the first row
                                accumulator.rows.push({
                                    date: game.date ? game.date : null,
                                    time: game.time ? game.time : null,
                                    games: game.id ? [game] : []
                                });
                                if (game.court && accumulator.courts.every(court => court !== game.court)) {
                                    accumulator.courts.push(game.court);
                                }
                                return accumulator;
                            }
                        },
                        {
                            rows: [],
                            courts: []
                        }
                    );

                return listOfMatchups.rows.length > 0 ? (
                    <div key={location.id}>
                        <div className='text-left mb-4'>
                            <h4>
                                <Badge color='primary'>{getLeagueAndSeasonName(season.id, seasons, leagues)}</Badge>
                            </h4>
                            <strong>Games at {location.name}</strong>
                        </div>
                        {alert}
                        <GamesTableByTimeAndCourts branding={branding} listOfMatchups={listOfMatchups} teams={teams} />
                    </div>
                ) : null;
            });
            return listOfLocations;
        });
    }

    if (gamesNotFound) {
        seasonAndLocationCards = (
            <div>There are no {gameUnit} available. Please contact the league administrator for more information.</div>
        );
    }

    return seasonAndLocationCards;
};

export default GamesBySeasonAndLocation;
