// SEASONS ACTION

import * as actionTypes from './actionTypes';

export const fetchSeasons = (sort, query) => {
    return {
        type: actionTypes.FETCH_SEASONS,
        sort: sort,
        query: query
    };
};

export const fetchSeasonsSuccess = seasons => {
    return {
        type: actionTypes.FETCH_SEASONS_SUCCESS,
        seasons: seasons
    };
};

export const fetchSeasonsFail = error => {
    return {
        type: actionTypes.FETCH_SEASONS_FAIL,
        error: error
    };
};

export const createSeason = (userId, season) => {
    return {
        type: actionTypes.CREATE_SEASON,
        userId: userId,
        season: season
    };
};

export const createSeasonSuccess = () => {
    return {
        type: actionTypes.CREATE_SEASON_SUCCESS
    };
};

export const createSeasonFail = error => {
    return {
        type: actionTypes.CREATE_SEASON_FAIL,
        error: error
    };
};

export const updateSeason = (userId, season, seasonId) => {
    return {
        type: actionTypes.UPDATE_SEASON,
        userId: userId,
        season: season,
        seasonId: seasonId
    };
};

export const updateSeasonSuccess = () => {
    return {
        type: actionTypes.UPDATE_SEASON_SUCCESS
    };
};

export const updateSeasonFail = error => {
    return {
        type: actionTypes.UPDATE_SEASON_FAIL,
        error: error
    };
};

export const pinSeason = (userId, season, seasonId) => {
    return {
        type: actionTypes.PIN_SEASON,
        userId: userId,
        season: season,
        seasonId: seasonId
    };
};

export const pinSeasonSuccess = () => {
    return {
        type: actionTypes.PIN_SEASON_SUCCESS
    };
};

export const pinSeasonFail = error => {
    return {
        type: actionTypes.PIN_SEASON_FAIL,
        error: error
    };
};

export const removePinFromSeason = (userId, season, seasonId) => {
    return {
        type: actionTypes.REMOVE_PIN_FROM_SEASON,
        userId: userId,
        season: season,
        seasonId: seasonId
    };
};

export const removePinFromSeasonSuccess = () => {
    return {
        type: actionTypes.REMOVE_PIN_FROM_SEASON_SUCCESS
    };
};

export const removePinFromSeasonFail = error => {
    return {
        type: actionTypes.REMOVE_PIN_FROM_SEASON_FAIL,
        error: error
    };
};

export const deleteSeason = (userId, season) => {
    return {
        type: actionTypes.DELETE_SEASON,
        userId: userId,
        season: season
    };
};

export const deleteSeasonSuccess = () => {
    return {
        type: actionTypes.DELETE_SEASON_SUCCESS
    };
};

export const deleteSeasonFail = error => {
    return {
        type: actionTypes.DELETE_SEASON_FAIL,
        error: error
    };
};

export const clearSeasonSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_SEASON_SUCCESS_STATES
    };
};

export const clearSeasonErrorStates = () => {
    return {
        type: actionTypes.CLEAR_SEASON_ERROR_STATES
    };
};
