import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';

import styles from './Contact.module.css';
import Banner from '../UI/Banner/Banner';

const Contact = () => {
    return (
        <div className={styles.Contact}>
            <Banner />
            <main>
                <Card body className='text-left'>
                    <CardBody>
                    <div className='mb-4'>
                            <h1>
                                <strong>Contact Us</strong>
                            </h1>
                        </div>
                        <CardText>
                            If you have any questions or concerns about our league that you would like to address, please feel free to email
                            us. We will try to respond to all emails on the same day. Thank you!
                        </CardText>

                        <CardText>
                            Email: <Link to='mailto:funtimevolleyball@gmail.com'>funtimevolleyball@gmail.com</Link>
                        </CardText>
                    </CardBody>
                </Card>
            </main>
        </div>
    );
};

export default Contact;
