// SEASONS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    seasons: null,
    createSeasonSuccess: null,
    updateSeasonSuccess: null,
    deleteSeasonSuccess: null,
    error: null,
    loading: false
}

const fetchSeasons = (state, action) => {
    return updateObject( state, {
        error: null, 
        loading: true 
    });
}

const fetchSeasonsSuccess = (state, action) => {
    return updateObject( state, {
        seasons: action.seasons,
        error: null,
        loading: false
    });
}

const fetchSeasonsFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
}

const createSeason = (state, action) => {
    return updateObject( state, {
        createSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const createSeasonSuccess = (state, action) => {
    return updateObject( state, {
        createSeasonSuccess: true,
        error: null,
        loading: false
    });
}

const createSeasonFail = (state, action) => {
    return updateObject( state, {
        createSeasonSuccess: false,
        error: action.error,
        loading: false
    });
}

const updateSeason = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const updateSeasonSuccess = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: true,
        error: null,
        loading: false
    });
}

const updateSeasonFail = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: false,
        error: action.error, 
        loading: false
    });
}

const pinSeason = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const pinSeasonSuccess = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: true,
        error: null,
        loading: false
    });
}

const pinSeasonFail = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: false,
        error: action.error, 
        loading: false
    });
}

const removePinFromSeason = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const removePinFromSeasonSuccess = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: true,
        error: null,
        loading: false
    });
}

const removePinFromSeasonFail = (state, action) => {
    return updateObject( state, {
        updateSeasonSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deleteSeason = (state, action) => {
    return updateObject( state, {
        deleteSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const deleteSeasonSuccess = (state, action) => {
    return updateObject( state, {
        deleteSeasonSuccess: true,
        error: null, 
        loading: false 
    });
}

const deleteSeasonFail = (state, action) => {
    return updateObject( state, {
        deleteSeasonSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearSeasonSuccessStates = (state, action) => {
    return updateObject( state, { 
        createSeasonSuccess: null,
        updateSeasonSuccess: null,
        deleteSeasonSuccess: null
    });
}
const clearSeasonErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SEASONS: return fetchSeasons(state, action);
        case actionTypes.FETCH_SEASONS_SUCCESS: return fetchSeasonsSuccess(state, action);
        case actionTypes.FETCH_SEASONS_FAIL: return fetchSeasonsFail(state, action);
        case actionTypes.CREATE_SEASON: return createSeason(state, action);
        case actionTypes.CREATE_SEASON_SUCCESS: return createSeasonSuccess(state, action);
        case actionTypes.CREATE_SEASON_FAIL: return createSeasonFail(state, action);
        case actionTypes.UPDATE_SEASON: return updateSeason(state, action);
        case actionTypes.UPDATE_SEASON_SUCCESS: return updateSeasonSuccess(state, action);
        case actionTypes.UPDATE_SEASON_FAIL: return updateSeasonFail(state, action);
        case actionTypes.PIN_SEASON: return pinSeason(state, action);
        case actionTypes.PIN_SEASON_SUCCESS: return pinSeasonSuccess(state, action);
        case actionTypes.PIN_SEASON_FAIL: return pinSeasonFail(state, action);
        case actionTypes.REMOVE_PIN_FROM_SEASON: return removePinFromSeason(state, action);
        case actionTypes.REMOVE_PIN_FROM_SEASON_SUCCESS: return removePinFromSeasonSuccess(state, action);
        case actionTypes.REMOVE_PIN_FROM_SEASON_FAIL: return removePinFromSeasonFail(state, action);
        case actionTypes.DELETE_SEASON: return deleteSeason(state, action);
        case actionTypes.DELETE_SEASON_SUCCESS: return deleteSeasonSuccess(state, action);
        case actionTypes.DELETE_SEASON_FAIL: return deleteSeasonFail(state, action);
        case actionTypes.CLEAR_SEASON_SUCCESS_STATES: return clearSeasonSuccessStates(state, action);
        case actionTypes.CLEAR_SEASON_ERROR_STATES: return clearSeasonErrorStates(state, action);
        default: return state;
    }
}

export default reducer;