// LEAGUES ACTION

import * as actionTypes from './actionTypes';

export const fetchLeagues = (sort, query) => {
    return {
        type: actionTypes.FETCH_LEAGUES,
        sort: sort,
        query: query
    };
}

export const fetchLeaguesSuccess = (leagues) => {
    return {
        type: actionTypes.FETCH_LEAGUES_SUCCESS,
        leagues: leagues
    };
}

export const fetchLeaguesFail = (error) => {
    return {
        type: actionTypes.FETCH_LEAGUES_FAIL,
        error: error
    };
}

export const createLeague = (userId, league) => {
    return {
        type: actionTypes.CREATE_LEAGUE,
        userId: userId,
        league: league
    };
}

export const createLeagueSuccess = () => {
    return {
        type: actionTypes.CREATE_LEAGUE_SUCCESS
    };
}

export const createLeagueFail = (error) => {
    return {
        type: actionTypes.CREATE_LEAGUE_FAIL,
        error: error
    };
}


export const updateLeague = (userId, league, leagueId) => {
    return {
        type: actionTypes.UPDATE_LEAGUE,
        userId: userId,
        league: league,
        leagueId: leagueId
    };
}

export const updateLeagueSuccess = () => {
    return {
        type: actionTypes.UPDATE_LEAGUE_SUCCESS
    };
}

export const updateLeagueFail = () => {
    return {
        type: actionTypes.UPDATE_LEAGUE_FAIL
    };
}

export const deleteLeague = (userId, league) => {
    return {
        type: actionTypes.DELETE_LEAGUE,
        userId: userId,
        league: league
    };
}

export const deleteLeagueSuccess = () => {
    return {
        type: actionTypes.DELETE_LEAGUE_SUCCESS
    };
}

export const deleteLeagueFail = (error) => {
    return {
        type: actionTypes.DELETE_LEAGUE_FAIL,
        error: error
    };
}

export const clearLeagueSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_LEAGUE_SUCCESS_STATES
    };
}

export const clearLeagueErrorStates = () => {
    return {
        type: actionTypes.CLEAR_LEAGUE_ERROR_STATES
    };
}