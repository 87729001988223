// PROJECTS ACTION

import * as actionTypes from './actionTypes';

export const fetchProjects = (sort, query) => {
    return {
        type: actionTypes.FETCH_PROJECTS,
        sort: sort,
        query: query
    };
}

export const fetchProjectsSuccess = (projects) => {
    return {
        type: actionTypes.FETCH_PROJECTS_SUCCESS,
        projects: projects
    };
}

export const fetchProjectsFail = (error) => {
    return {
        type: actionTypes.FETCH_PROJECTS_FAIL,
        error: error
    };
}

export const createProject = (userId, project) => {
    return {
        type: actionTypes.CREATE_PROJECT,
        userId: userId,
        project: project
    };
}

export const createProjectSuccess = () => {
    return {
        type: actionTypes.CREATE_PROJECT_SUCCESS
    };
}

export const createProjectFail = (error) => {
    return {
        type: actionTypes.CREATE_PROJECT_FAIL,
        error: error
    };
}

export const updateProject = (userId, project, projectId) => {
    return {
        type: actionTypes.UPDATE_PROJECT,
        userId: userId,
        project: project,
        projectId: projectId
    };
}

export const updateProjectSuccess = () => {
    return {
        type: actionTypes.UPDATE_PROJECT_SUCCESS
    };
}

export const updateProjectFail = () => {
    return {
        type: actionTypes.UPDATE_PROJECT_FAIL
    };
}

export const deleteProject = (userId, project) => {
    return {
        type: actionTypes.DELETE_PROJECT,
        userId: userId,
        project: project
    };
}

export const deleteProjectSuccess = () => {
    return {
        type: actionTypes.DELETE_PROJECT_SUCCESS
    };
}

export const deleteProjectFail = (error) => {
    return {
        type: actionTypes.DELETE_PROJECT_FAIL,
        error: error
    };
}

export const clearProjectSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_PROJECT_SUCCESS_STATES
    };
}

export const clearProjectErrorStates = () => {
    return {
        type: actionTypes.CLEAR_PROJECT_ERROR_STATES
    };
}

// BRANDING CONFIGURATIONS ACTION

export const fetchWebsiteBranding = (projectId) => {
    return {
        type: actionTypes.FETCH_WEBSITE_BRANDING,
        projectId: projectId
    };
}

export const fetchWebsiteBrandingSuccess = (websiteBranding) => {
    return {
        type: actionTypes.FETCH_WEBSITE_BRANDING_SUCCESS,
        websiteBranding: websiteBranding
    };
}

export const fetchWebsiteBrandingFail = (error) => {
    return {
        type: actionTypes.FETCH_WEBSITE_BRANDING_FAIL,
        error: error
    };
}

export const createWebsiteBranding = (userId, projectId, websiteBranding) => {
    return {
        type: actionTypes.CREATE_WEBSITE_BRANDING,
        userId: userId,
        projectId: projectId,
        websiteBranding: websiteBranding
    };
}

export const createWebsiteBrandingSuccess = () => {
    return {
        type: actionTypes.CREATE_WEBSITE_BRANDING_SUCCESS
    };
}

export const createWebsiteBrandingFail = (error) => {
    return {
        type: actionTypes.CREATE_WEBSITE_BRANDING_FAIL,
        error: error
    };
}

export const updateWebsiteBranding = (userId, websiteBranding, websiteBrandingId) => {
    return {
        type: actionTypes.UPDATE_WEBSITE_BRANDING,
        userId: userId,
        websiteBranding: websiteBranding,
        websiteBrandingId: websiteBrandingId
    };
}

export const updateWebsiteBrandingSuccess = () => {
    return {
        type: actionTypes.UPDATE_WEBSITE_BRANDING_SUCCESS
    };
}

export const updateWebsiteBrandingFail = () => {
    return {
        type: actionTypes.UPDATE_WEBSITE_BRANDING_FAIL
    };
}

export const deleteWebsiteBranding = (userId, websiteBranding) => {
    return {
        type: actionTypes.DELETE_WEBSITE_BRANDING,
        userId: userId,
        websiteBranding: websiteBranding
    };
}

export const deleteWebsiteBrandingSuccess = () => {
    return {
        type: actionTypes.DELETE_WEBSITE_BRANDING_SUCCESS
    };
}

export const deleteWebsiteBrandingFail = (error) => {
    return {
        type: actionTypes.DELETE_WEBSITE_BRANDING_FAIL,
        error: error
    };
}

export const clearWebsiteBrandingSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_WEBSITE_BRANDING_SUCCESS_STATES
    };
}

export const clearWebsiteBrandingErrorStates = () => {
    return {
        type: actionTypes.CLEAR_WEBSITE_BRANDING_ERROR_STATES
    };
}