// ANNOUNCEMENTS ACTION

import * as actionTypes from './actionTypes';

export const uploadImage = (userId, folder, imageName, image) => {
    return {
        type: actionTypes.UPLOAD_IMAGE,
        userId: userId,
        folder: folder,
        imageName: imageName,
        image: image
    };
};

export const uploadImageSuccess = downloadUrl => {
    return {
        type: actionTypes.UPLOAD_IMAGE_SUCCESS,
        downloadUrl: downloadUrl
    };
};

export const uploadImageFail = error => {
    return {
        type: actionTypes.UPLOAD_IMAGE_FAIL,
        error: error
    };
};

export const deleteImages = (userId, images) => {
    return {
        type: actionTypes.DELETE_IMAGES,
        userId: userId,
        images: images
    };
};

export const deleteImagesSuccess = () => {
    return {
        type: actionTypes.DELETE_IMAGES_SUCCESS
    };
};

export const deleteImagesFail = error => {
    return {
        type: actionTypes.DELETE_IMAGES_FAIL,
        error: error
    };
};

export const clearUploadImageSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_UPLOAD_IMAGE_SUCCESS_STATES
    };
};

export const clearUploadImageErrorStates = () => {
    return {
        type: actionTypes.CLEAR_UPLOAD_IMAGE_ERROR_STATES
    };
};
