// ALERTS ACTION

import * as actionTypes from './actionTypes';

export const fetchAllAlerts = (sort) => {
    return {
        type: actionTypes.FETCH_ALL_ALERTS,
        sort: sort
    };
}

export const fetchAllAlertsSuccess = (alerts) => {
    return {
        type: actionTypes.FETCH_ALL_ALERTS_SUCCESS,
        alerts: alerts
    };
}

export const fetchAllAlertsFail = (error) => {
    return {
        type: actionTypes.FETCH_ALL_ALERTS_FAIL,
        error: error
    };
}

export const fetchPublishedAlerts = (sort) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_ALERTS,
        sort: sort
    };
}

export const fetchPublishedAlertsSuccess = (publishedAlerts) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_ALERTS_SUCCESS,
        publishedAlerts: publishedAlerts
    };
}

export const fetchPublishedAlertsFail = (error) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_ALERTS_FAIL,
        error: error
    };
}

export const fetchAlert = (alertId) => {
    return {
        type: actionTypes.FETCH_ALERT,
        alertId: alertId
    };
}

export const fetchAlertSuccess = (alert) => {
    return {
        type: actionTypes.FETCH_ALERT_SUCCESS,
        alert: alert
    };
}

export const fetchAlertFail = (error) => {
    return {
        type: actionTypes.FETCH_ALERT_FAIL,
        error: error
    };
}

export const createAlert = (userId, alert) => {
    return {
        type: actionTypes.CREATE_ALERT,
        userId: userId,
        alert: alert
    };
}

export const createAlertSuccess = () => {
    return {
        type: actionTypes.CREATE_ALERT_SUCCESS
    };
}

export const createAlertFail = (error) => {
    return {
        type: actionTypes.CREATE_ALERT_FAIL,
        error: error
    };
}

export const updateAlert = (userId, alert, alertId) => {
    return {
        type: actionTypes.UPDATE_ALERT,
        userId: userId,
        alert: alert,
        alertId: alertId
    };
}

export const updateAlertSuccess = () => {
    return {
        type: actionTypes.UPDATE_ALERT_SUCCESS
    };
}

export const updateAlertFail = (error) => {
    return {
        type: actionTypes.UPDATE_ALERT_FAIL,
        error: error
    };
}

export const deleteAlert = (userId, alert) => {
    return {
        type: actionTypes.DELETE_ALERT,
        userId: userId,
        alert: alert
    };
}

export const deleteAlertSuccess = () => {
    return {
        type: actionTypes.DELETE_ALERT_SUCCESS
    };
}

export const deleteAlertFail = (error) => {
    return {
        type: actionTypes.DELETE_ALERT_FAIL,
        error: error
    };
}

export const clearAlertSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_ALERT_SUCCESS_STATES
    };
}

export const clearAlertErrorStates = () => {
    return {
        type: actionTypes.CLEAR_ALERT_ERROR_STATES
    };
}