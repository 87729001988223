import React from 'react';

import styles from './SidePanel.module.css';
import SidePanelStandings from '../../../containers/Website/SidePanelStandings/SidePanelStandings';
import SidePanelLocations from '../../../containers/Website/SidePanelLocations/SidePanelLocations';
// import News from '../../components/News/News';

const SidePanel = props => {
    // Initialize Props from Component
    const { branding } = props;

    if (branding) {
        document.documentElement.style.setProperty('--bodyPrimaryColor', branding.theme.bodyPrimaryColor);
        document.documentElement.style.setProperty('--bodyAccentColor', branding.theme.bodyAccentColor);
        document.documentElement.style.setProperty('--sidePanelBackgroundColor', branding.theme.sidePanelBackgroundColor);
        document.documentElement.style.setProperty('--sidePanelTextPrimaryColor', branding.theme.sidePanelTextPrimaryColor);
    }

    return (
        <div className={styles.SidePanel}>
            <SidePanelStandings />
            <SidePanelLocations />
        </div>
    );
};

export default SidePanel;
