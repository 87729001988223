// GAMES REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    games: null,
    gamesByDate: null,
    gamesByQuery: null,
    upcomingGames: null,
    completedGames: null,
    previousGames: null,
    nextGameDate: null,
    fetchGamesSuccess: null,
    nextGameDateSuccess: null,
    createGameSuccess: null,
    createBulkGamesSuccess: null,
    updateGameSuccess: null,
    updateBulkGamesSuccess: null,
    deleteGameSuccess: null,
    error: null,
    loading: false
};

const fetchGames = (state, action) => {
    return updateObject(state, {
        games: null,
        fetchGamesSuccess: null,
        error: null,
        loading: true
    });
};

const fetchGamesSuccess = (state, action) => {
    return updateObject(state, {
        games: action.games,
        fetchGamesSuccess: true,
        error: null,
        loading: false
    });
};

const fetchGamesFail = (state, action) => {
    return updateObject(state, {
        games: null,
        fetchGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const fetchGamesByDate = (state, action) => {
    return updateObject(state, {
        gamesByDate: null,
        fetchGamesSuccess: null,
        error: null,
        loading: true
    });
};

const fetchGamesByDateSuccess = (state, action) => {
    return updateObject(state, {
        gamesByDate: action.games,
        fetchGamesSuccess: true,
        error: null,
        loading: false
    });
};

const fetchGamesByDateFail = (state, action) => {
    return updateObject(state, {
        gamesByDate: null,
        fetchGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const fetchGamesByQuery = (state, action) => {
    return updateObject(state, {
        gamesByQuery: null,
        fetchGamesSuccess: null,
        error: null,
        loading: true
    });
};

const fetchGamesByQuerySuccess = (state, action) => {
    return updateObject(state, {
        gamesByQuery: action.games,
        fetchGamesSuccess: true,
        error: null,
        loading: false
    });
};

const fetchGamesByQueryFail = (state, action) => {
    return updateObject(state, {
        gamesByQuery: null,
        fetchGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const fetchUpcomingGames = (state, action) => {
    return updateObject(state, {
        upcomingGames: null,
        fetchGamesSuccess: null,
        error: null,
        loading: true
    });
};

const fetchUpcomingGamesSuccess = (state, action) => {
    return updateObject(state, {
        upcomingGames: action.games,
        fetchGamesSuccess: true,
        error: null,
        loading: false
    });
};

const fetchUpcomingGamesFail = (state, action) => {
    return updateObject(state, {
        upcomingGames: null,
        fetchGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const fetchCompletedGames = (state, action) => {
    return updateObject(state, {
        completedGames: null,
        fetchGamesSuccess: null,
        error: null,
        loading: true
    });
};

const fetchCompletedGamesSuccess = (state, action) => {
    return updateObject(state, {
        completedGames: action.games,
        fetchGamesSuccess: true,
        error: null,
        loading: false
    });
};

const fetchCompletedGamesFail = (state, action) => {
    return updateObject(state, {
        completedGames: null,
        fetchGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const fetchPreviousGames = (state, action) => {
    return updateObject(state, {
        previousGames: null,
        fetchGamesSuccess: null,
        error: null,
        loading: true
    });
};

const fetchPreviousGamesSuccess = (state, action) => {
    return updateObject(state, {
        previousGames: action.games,
        fetchGamesSuccess: true,
        error: null,
        loading: false
    });
};

const fetchPreviousGamesFail = (state, action) => {
    return updateObject(state, {
        previousGames: null,
        fetchGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const fetchNextGameDate = (state, action) => {
    return updateObject(state, {
        nextGameDate: null,
        nextGameDateSuccess: null,
        error: null,
        loading: true
    });
};

const fetchNextGameDateSuccess = (state, action) => {
    return updateObject(state, {
        nextGameDate: action.nextGameDate,
        nextGameDateSuccess: true,
        error: null,
        loading: false
    });
};

const fetchNextGameDateFail = (state, action) => {
    return updateObject(state, {
        nextGameDate: null,
        nextGameDateSuccess: false,
        error: action.error,
        loading: false
    });
};

const createGame = (state, action) => {
    return updateObject(state, {
        createGameSuccess: null,
        error: null,
        loading: true
    });
};

const createGameSuccess = (state, action) => {
    return updateObject(state, {
        createGameSuccess: true,
        error: null,
        loading: false
    });
};

const createGameFail = (state, action) => {
    return updateObject(state, {
        createGameSuccess: false,
        error: action.error,
        loading: false
    });
};

const createBulkGames = (state, action) => {
    return updateObject(state, {
        createBulkGamesSuccess: null,
        error: null,
        loading: true
    });
};

const createBulkGamesSuccess = (state, action) => {
    return updateObject(state, {
        createBulkGamesSuccess: true,
        error: null,
        loading: false
    });
};

const createBulkGamesFail = (state, action) => {
    return updateObject(state, {
        createBulkGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const updateGame = (state, action) => {
    return updateObject(state, {
        updateGameSuccess: null,
        error: null,
        loading: true
    });
};

const updateGameSuccess = (state, action) => {
    return updateObject(state, {
        updateGameSuccess: true,
        error: null,
        loading: false
    });
};

const updateGameFail = (state, action) => {
    return updateObject(state, {
        updateGameSuccess: false,
        error: action.error,
        loading: false
    });
};

const updateBulkGames = (state, action) => {
    return updateObject(state, {
        updateBulkGamesSuccess: null,
        error: null,
        loading: true
    });
};

const updateBulkGamesSuccess = (state, action) => {
    return updateObject(state, {
        updateBulkGamesSuccess: action.gameIds,
        error: null,
        loading: false
    });
};

const updateBulkGamesFail = (state, action) => {
    return updateObject(state, {
        updateBulkGamesSuccess: false,
        error: action.error,
        loading: false
    });
};

const deleteGame = (state, action) => {
    return updateObject(state, {
        deleteGameSuccess: null,
        error: null,
        loading: true
    });
};

const deleteGameSuccess = (state, action) => {
    return updateObject(state, {
        deleteGameSuccess: true,
        error: null,
        loading: false
    });
};

const deleteGameFail = (state, action) => {
    return updateObject(state, {
        deleteGameSuccess: false,
        error: action.error,
        loading: false
    });
};

const clearGameSuccessStates = (state, action) => {
    return updateObject(state, {
        fetchGamesSuccess: null,
        nextGameDateSuccess: null,
        createGameSuccess: null,
        createBulkGamesSuccess: null,
        updateGameSuccess: null,
        updateBulkGamesSuccess: null,
        deleteGameSuccess: null
    });
};

const clearGameErrorStates = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_GAMES:
            return fetchGames(state, action);
        case actionTypes.FETCH_GAMES_SUCCESS:
            return fetchGamesSuccess(state, action);
        case actionTypes.FETCH_GAMES_FAIL:
            return fetchGamesFail(state, action);
        case actionTypes.FETCH_GAMES_BY_DATE:
            return fetchGamesByDate(state, action);
        case actionTypes.FETCH_GAMES_BY_DATE_SUCCESS:
            return fetchGamesByDateSuccess(state, action);
        case actionTypes.FETCH_GAMES_BY_DATE_FAIL:
            return fetchGamesByDateFail(state, action);
        case actionTypes.FETCH_GAMES_BY_QUERY:
            return fetchGamesByQuery(state, action);
        case actionTypes.FETCH_GAMES_BY_QUERY_SUCCESS:
            return fetchGamesByQuerySuccess(state, action);
        case actionTypes.FETCH_GAMES_BY_QUERY_FAIL:
            return fetchGamesByQueryFail(state, action);
        case actionTypes.FETCH_UPCOMING_GAMES:
            return fetchUpcomingGames(state, action);
        case actionTypes.FETCH_UPCOMING_GAMES_SUCCESS:
            return fetchUpcomingGamesSuccess(state, action);
        case actionTypes.FETCH_UPCOMING_GAMES_FAIL:
            return fetchUpcomingGamesFail(state, action);
        case actionTypes.FETCH_COMPLETED_GAMES:
            return fetchCompletedGames(state, action);
        case actionTypes.FETCH_COMPLETED_GAMES_SUCCESS:
            return fetchCompletedGamesSuccess(state, action);
        case actionTypes.FETCH_COMPLETED_GAMES_FAIL:
            return fetchCompletedGamesFail(state, action);
        case actionTypes.FETCH_PREVIOUS_GAMES:
            return fetchPreviousGames(state, action);
        case actionTypes.FETCH_PREVIOUS_GAMES_SUCCESS:
            return fetchPreviousGamesSuccess(state, action);
        case actionTypes.FETCH_PREVIOUS_GAMES_FAIL:
            return fetchPreviousGamesFail(state, action);
        case actionTypes.FETCH_NEXT_GAME_DATE:
            return fetchNextGameDate(state, action);
        case actionTypes.FETCH_NEXT_GAME_DATE_SUCCESS:
            return fetchNextGameDateSuccess(state, action);
        case actionTypes.FETCH_NEXT_GAME_DATE_FAIL:
            return fetchNextGameDateFail(state, action);
        case actionTypes.CREATE_GAME:
            return createGame(state, action);
        case actionTypes.CREATE_GAME_SUCCESS:
            return createGameSuccess(state, action);
        case actionTypes.CREATE_GAME_FAIL:
            return createGameFail(state, action);
        case actionTypes.CREATE_BULK_GAMES:
            return createBulkGames(state, action);
        case actionTypes.CREATE_BULK_GAMES_SUCCESS:
            return createBulkGamesSuccess(state, action);
        case actionTypes.CREATE_BULK_GAMES_FAIL:
            return createBulkGamesFail(state, action);
        case actionTypes.UPDATE_GAME:
            return updateGame(state, action);
        case actionTypes.UPDATE_GAME_SUCCESS:
            return updateGameSuccess(state, action);
        case actionTypes.UPDATE_GAME_FAIL:
            return updateGameFail(state, action);
        case actionTypes.DELETE_GAME:
            return deleteGame(state, action);
        case actionTypes.UPDATE_BULK_GAMES:
            return updateBulkGames(state, action);
        case actionTypes.UPDATE_BULK_GAMES_SUCCESS:
            return updateBulkGamesSuccess(state, action);
        case actionTypes.UPDATE_BULK_GAMES_FAIL:
            return updateBulkGamesFail(state, action);
        case actionTypes.DELETE_GAME_SUCCESS:
            return deleteGameSuccess(state, action);
        case actionTypes.DELETE_GAME_FAIL:
            return deleteGameFail(state, action);
        case actionTypes.CLEAR_GAME_SUCCESS_STATES:
            return clearGameSuccessStates(state, action);
        case actionTypes.CLEAR_GAME_ERROR_STATES:
            return clearGameErrorStates(state, action);
        default:
            return state;
    }
};

export default reducer;
