// ANNOUNCEMENTS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    announcements: null,
    publishedAnnouncements: null,
    announcement: null,
    fetchAllAnnouncementsSuccess: null,
    fetchPublishedAnnouncementsSuccess: null,
    fetchAnnouncementSuccess: null,
    createAnnouncementSuccess: null,
    updateAnnouncementSuccess: null,
    deleteAnnouncementSuccess: null,
    error: null,
    loading: false
}

const fetchAllAnnouncements = (state, action) => {
    return updateObject( state, {
        fetchAllAnnouncementsSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchAllAnnouncementsSuccess = (state, action) => {
    return updateObject( state, {
        announcements: action.announcements,
        fetchAllAnnouncementsSuccess: true,
        error: null,
        loading: false
    });
}

const fetchAllAnnouncementsFail = (state, action) => {
    return updateObject( state, {
        fetchAllAnnouncementsSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchPublishedAnnouncements = (state, action) => {
    return updateObject( state, {
        fetchPublishedAnnouncementsSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchPublishedAnnouncementsSuccess = (state, action) => {
    return updateObject( state, {
        publishedAnnouncements: action.publishedAnnouncements,
        fetchPublishedAnnouncementsSuccess: true,
        error: null,
        loading: false
    });
}

const fetchPublishedAnnouncementsFail = (state, action) => {
    return updateObject( state, {
        fetchPublishedAnnouncementsSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchAnnouncement = (state, action) => {
    return updateObject( state, {
        fetchAnnouncementSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchAnnouncementSuccess = (state, action) => {
    return updateObject( state, {
        announcement: action.announcement,
        fetchAnnouncementSuccess: true,
        error: null,
        loading: false
    });
}

const fetchAnnouncementFail = (state, action) => {
    return updateObject( state, {
        fetchAnnouncementSuccess: false,
        error: action.error,
        loading: false
    });
}

const createAnnouncement = (state, action) => {
    return updateObject( state, {
        createAnnouncementSuccess: null,
        error: null, 
        loading: true 
    });
}

const createAnnouncementSuccess = (state, action) => {
    return updateObject( state, {
        createAnnouncementSuccess: true,
        error: null,
        loading: false
    });
}

const createAnnouncementFail = (state, action) => {
    return updateObject( state, {
        createAnnouncementSuccess: false,
        error: action.error,
        loading: false
    });
}

const updateAnnouncement = (state, action) => {
    return updateObject( state, {
        updateAnnouncementSuccess: null,
        error: null, 
        loading: true 
    });
}

const updateAnnouncementSuccess = (state, action) => {
    return updateObject( state, {
        updateAnnouncementSuccess: true,
        error: null,
        loading: false
    });
}

const updateAnnouncementFail = (state, action) => {
    return updateObject( state, {
        updateAnnouncementSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deleteAnnouncement = (state, action) => {
    return updateObject( state, {
        deleteAnnouncementSuccess: null,
        error: null, 
        loading: true 
    });
}

const deleteAnnouncementSuccess = (state, action) => {
    return updateObject( state, {
        deleteAnnouncementSuccess: true,
        error: null, 
        loading: false 
    });
}

const deleteAnnouncementFail = (state, action) => {
    return updateObject( state, {
        deleteAnnouncementSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearAnnouncementSuccessStates = (state, action) => {
    return updateObject( state, {
        fetchAllAnnouncementsSuccess: null,
        fetchPublishedAnnouncementsSuccess: null,
        fetchAnnouncementSuccess: null,
        createAnnouncementSuccess: null,
        updateAnnouncementSuccess: null,
        deleteAnnouncementSuccess: null
    });
}

const clearAnnouncementErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ALL_ANNOUNCEMENTS: return fetchAllAnnouncements(state, action);
        case actionTypes.FETCH_ALL_ANNOUNCEMENTS_SUCCESS: return fetchAllAnnouncementsSuccess(state, action);
        case actionTypes.FETCH_ALL_ANNOUNCEMENTS_FAIL: return fetchAllAnnouncementsFail(state, action);
        case actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS: return fetchPublishedAnnouncements(state, action);
        case actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS_SUCCESS: return fetchPublishedAnnouncementsSuccess(state, action);
        case actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS_FAIL: return fetchPublishedAnnouncementsFail(state, action);
        case actionTypes.FETCH_ANNOUNCEMENT: return fetchAnnouncement(state, action);
        case actionTypes.FETCH_ANNOUNCEMENT_SUCCESS: return fetchAnnouncementSuccess(state, action);
        case actionTypes.FETCH_ANNOUNCEMENT_FAIL: return fetchAnnouncementFail(state, action);
        case actionTypes.CREATE_ANNOUNCEMENT: return createAnnouncement(state, action);
        case actionTypes.CREATE_ANNOUNCEMENT_SUCCESS: return createAnnouncementSuccess(state, action);
        case actionTypes.CREATE_ANNOUNCEMENT_FAIL: return createAnnouncementFail(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT: return updateAnnouncement(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS: return updateAnnouncementSuccess(state, action);
        case actionTypes.UPDATE_ANNOUNCEMENT_FAIL: return updateAnnouncementFail(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT: return deleteAnnouncement(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT_SUCCESS: return deleteAnnouncementSuccess(state, action);
        case actionTypes.DELETE_ANNOUNCEMENT_FAIL: return deleteAnnouncementFail(state, action);
        case actionTypes.CLEAR_ANNOUNCEMENT_SUCCESS_STATES: return clearAnnouncementSuccessStates(state, action);
        case actionTypes.CLEAR_ANNOUNCEMENT_ERROR_STATES: return clearAnnouncementErrorStates(state, action);
        default: return state;
    }
}

export default reducer;