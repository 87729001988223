// LEAGUES REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    leagues: null,
    createLeagueSuccess: null,
    updateLeagueSuccess: null,
    deleteLeagueSuccess: null,
    error: null,
    loading: false
}

const fetchLeagues = (state, action) => {
    return updateObject( state, {
        error: null, 
        loading: true 
    });
}

const fetchLeaguesSuccess = (state, action) => {
    return updateObject( state, {
        leagues: action.leagues,
        error: null,
        loading: false
    });
}

const fetchLeaguesFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
}

const createLeague = (state, action) => {
    return updateObject( state, {
        createLeagueSuccess: null,
        error: null, 
        loading: true 
    });
}

const createLeagueSuccess = (state, action) => {
    return updateObject( state, {
        createLeagueSuccess: true,
        error: null,
        loading: false
    });
}

const createLeagueFail = (state, action) => {
    return updateObject( state, {
        createLeagueSuccess: false,
        error: action.error,
        loading: false
    });
}

const updateLeague = (state, action) => {
    return updateObject( state, {
        updateLeagueSuccess: null,
        error: null, 
        loading: true 
    });
}

const updateLeagueSuccess = (state, action) => {
    return updateObject( state, {
        updateLeagueSuccess: true,
        error: null,
        loading: false
    });
}

const updateLeagueFail = (state, action) => {
    return updateObject( state, {
        updateLeagueSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deleteLeague = (state, action) => {
    return updateObject( state, {
        deleteLeagueSuccess: null,
        error: null, 
        loading: true 
    });
}

const deleteLeagueSuccess = (state, action) => {
    return updateObject( state, {
        deleteLeagueSuccess: true,
        error: null, 
        loading: false 
    });
}

const deleteLeagueFail = (state, action) => {
    return updateObject( state, {
        deleteLeagueSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearLeagueSuccessStates = (state, action) => {
    return updateObject( state, { 
        createLeagueSuccess: null,
        updateLeagueSuccess: null,
        deleteLeagueSuccess: null
    });
}
const clearLeagueErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_LEAGUES: return fetchLeagues(state, action);
        case actionTypes.FETCH_LEAGUES_SUCCESS: return fetchLeaguesSuccess(state, action);
        case actionTypes.FETCH_LEAGUES_FAIL: return fetchLeaguesFail(state, action);
        case actionTypes.CREATE_LEAGUE: return createLeague(state, action);
        case actionTypes.CREATE_LEAGUE_SUCCESS: return createLeagueSuccess(state, action);
        case actionTypes.CREATE_LEAGUE_FAIL: return createLeagueFail(state, action);
        case actionTypes.UPDATE_LEAGUE: return updateLeague(state, action);
        case actionTypes.UPDATE_LEAGUE_SUCCESS: return updateLeagueSuccess(state, action);
        case actionTypes.UPDATE_LEAGUE_FAIL: return updateLeagueFail(state, action);
        case actionTypes.DELETE_LEAGUE: return deleteLeague(state, action);
        case actionTypes.DELETE_LEAGUE_SUCCESS: return deleteLeagueSuccess(state, action);
        case actionTypes.DELETE_LEAGUE_FAIL: return deleteLeagueFail(state, action);
        case actionTypes.CLEAR_LEAGUE_SUCCESS_STATES: return clearLeagueSuccessStates(state, action);
        case actionTypes.CLEAR_LEAGUE_ERROR_STATES: return clearLeagueErrorStates(state, action);
        default: return state;
    }
}

export default reducer;