import getLeagueName from './getLeagueName';

const getLeagueAndSeasonName = (seasonId, seasons, leagues) => {
    let seasonName = '';
    let leagueName = '';
    let leagueId = '';

    // Initialize component with seasonId from props
    if (seasonId && seasons && leagues) {
        const seasonRef = seasons.filter(season => season.id === seasonId);
        if (seasonRef.length > 0) {
            seasonName = seasonRef[0].name;
            leagueId = seasonRef[0].league;
        }

        leagueName = getLeagueName(leagueId, leagues);
    }

    // Format: <League Name> - <Season Name>
    return leagueName + ' - ' + seasonName;
};

export default getLeagueAndSeasonName;
