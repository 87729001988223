// NEWS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    newsArticles: null,
    publishedNewsArticles: null,
    newsArticle: null,
    fetchAllNewsArticlesSuccess: null,
    fetchPublishedNewsArticlesSuccess: null,
    fetchNewsArticleSuccess: null,
    createNewsArticleSuccess: null,
    updateNewsArticleSuccess: null,
    deleteNewsArticleSuccess: null,
    error: null,
    loading: false
}

const fetchAllNewsArticles = (state, action) => {
    return updateObject( state, {
        fetchAllNewsArticlesSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchAllNewsArticlesSuccess = (state, action) => {
    return updateObject( state, {
        newsArticles: action.newsArticles,
        fetchAllNewsArticlesSuccess: true,
        error: null,
        loading: false
    });
}

const fetchAllNewsArticlesFail = (state, action) => {
    return updateObject( state, {
        fetchAllNewsArticlesSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchPublishedNewsArticles = (state, action) => {
    return updateObject( state, {
        fetchPublishedNewsArticlesSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchPublishedNewsArticlesSuccess = (state, action) => {
    return updateObject( state, {
        publishedNewsArticles: action.publishedNewsArticles,
        fetchPublishedNewsArticlesSuccess: true,
        error: null,
        loading: false
    });
}

const fetchPublishedNewsArticlesFail = (state, action) => {
    return updateObject( state, {
        fetchPublishedNewsArticlesSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchPublishedHighlights = (state, action) => {
    return updateObject( state, {
        fetchPublishedHighlightsSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchPublishedHighlightsSuccess = (state, action) => {
    return updateObject( state, {
        publishedHighlights: action.publishedHighlights,
        fetchPublishedHighlightsSuccess: true,
        error: null,
        loading: false
    });
}

const fetchPublishedHighlightsFail = (state, action) => {
    return updateObject( state, {
        fetchPublishedHighlightsSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchNewsArticle = (state, action) => {
    return updateObject( state, {
        fetchNewsArticleSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchNewsArticleSuccess = (state, action) => {
    return updateObject( state, {
        newsArticle: action.newsArticle,
        fetchNewsArticleSuccess: true,
        error: null,
        loading: false
    });
}

const fetchNewsArticleFail = (state, action) => {
    return updateObject( state, {
        fetchNewsArticleSuccess: false,
        error: action.error,
        loading: false
    });
}

const createNewsArticle = (state, action) => {
    return updateObject( state, {
        createNewsArticleSuccess: null,
        error: null, 
        loading: true 
    });
}

const createNewsArticleSuccess = (state, action) => {
    return updateObject( state, {
        createNewsArticleSuccess: true,
        error: null,
        loading: false
    });
}

const createNewsArticleFail = (state, action) => {
    return updateObject( state, {
        createNewsArticleSuccess: false,
        error: action.error,
        loading: false
    });
}

const updateNewsArticle = (state, action) => {
    return updateObject( state, {
        updateNewsArticleSuccess: null,
        error: null, 
        loading: true 
    });
}

const updateNewsArticleSuccess = (state, action) => {
    return updateObject( state, {
        updateNewsArticleSuccess: true,
        error: null,
        loading: false
    });
}

const updateNewsArticleFail = (state, action) => {
    return updateObject( state, {
        updateNewsArticleSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deleteNewsArticle = (state, action) => {
    return updateObject( state, {
        deleteNewsArticleSuccess: null,
        error: null, 
        loading: true 
    });
}

const deleteNewsArticleSuccess = (state, action) => {
    return updateObject( state, {
        deleteNewsArticleSuccess: true,
        error: null, 
        loading: false 
    });
}

const deleteNewsArticleFail = (state, action) => {
    return updateObject( state, {
        deleteNewsArticleSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearNewsArticleSuccessStates = (state, action) => {
    return updateObject( state, {
        fetchAllNewsArticlesSuccess: null,
        fetchPublishedNewsArticlesSuccess: null,
        fetchPublishedHighlightsSuccess: null,
        fetchNewsArticleSuccess: null,
        createNewsArticleSuccess: null,
        updateNewsArticleSuccess: null,
        deleteNewsArticleSuccess: null
    });
}

const clearNewsArticleErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ALL_NEWS_ARTICLES: return fetchAllNewsArticles(state, action);
        case actionTypes.FETCH_ALL_NEWS_ARTICLES_SUCCESS: return fetchAllNewsArticlesSuccess(state, action);
        case actionTypes.FETCH_ALL_NEWS_ARTICLES_FAIL: return fetchAllNewsArticlesFail(state, action);
        case actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES: return fetchPublishedNewsArticles(state, action);
        case actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES_SUCCESS: return fetchPublishedNewsArticlesSuccess(state, action);
        case actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES_FAIL: return fetchPublishedNewsArticlesFail(state, action);
        case actionTypes.FETCH_PUBLISHED_HIGHLIGHTS: return fetchPublishedHighlights(state, action);
        case actionTypes.FETCH_PUBLISHED_HIGHLIGHTS_SUCCESS: return fetchPublishedHighlightsSuccess(state, action);
        case actionTypes.FETCH_PUBLISHED_HIGHLIGHTS_FAIL: return fetchPublishedHighlightsFail(state, action);
        case actionTypes.FETCH_NEWS_ARTICLE: return fetchNewsArticle(state, action);
        case actionTypes.FETCH_NEWS_ARTICLE_SUCCESS: return fetchNewsArticleSuccess(state, action);
        case actionTypes.FETCH_NEWS_ARTICLE_FAIL: return fetchNewsArticleFail(state, action);
        case actionTypes.CREATE_NEWS_ARTICLE: return createNewsArticle(state, action);
        case actionTypes.CREATE_NEWS_ARTICLE_SUCCESS: return createNewsArticleSuccess(state, action);
        case actionTypes.CREATE_NEWS_ARTICLE_FAIL: return createNewsArticleFail(state, action);
        case actionTypes.UPDATE_NEWS_ARTICLE: return updateNewsArticle(state, action);
        case actionTypes.UPDATE_NEWS_ARTICLE_SUCCESS: return updateNewsArticleSuccess(state, action);
        case actionTypes.UPDATE_NEWS_ARTICLE_FAIL: return updateNewsArticleFail(state, action);
        case actionTypes.DELETE_NEWS_ARTICLE: return deleteNewsArticle(state, action);
        case actionTypes.DELETE_NEWS_ARTICLE_SUCCESS: return deleteNewsArticleSuccess(state, action);
        case actionTypes.DELETE_NEWS_ARTICLE_FAIL: return deleteNewsArticleFail(state, action);
        case actionTypes.CLEAR_NEWS_ARTICLE_SUCCESS_STATES: return clearNewsArticleSuccessStates(state, action);
        case actionTypes.CLEAR_NEWS_ARTICLE_ERROR_STATES: return clearNewsArticleErrorStates(state, action);
        default: return state;
    }
}

export default reducer;