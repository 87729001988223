// PROJECTS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    projects: null,
    createProjectSuccess: null,
    updateProjectSuccess: null,
    deleteProjectSuccess: null,
    websiteBranding: null,
    createWebsiteBrandingSuccess: null,
    updateWebsiteBrandingSuccess: null,
    deleteWebsiteBrandingSuccess: null,
    loading: false,
    error: null
}

const fetchProjects = (state, action) => {
    return updateObject( state, {
        loading: true,
        error: null
    });
}

const fetchProjectsSuccess = (state, action) => {
    return updateObject( state, {
        projects: action.projects,
        loading: false,
        error: null
    });
}

const fetchProjectsFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
}

const createProject = (state, action) => {
    return updateObject( state, {
        createProjectSuccess: null,
        loading: true,
        error: null
    });
}

const createProjectSuccess = (state, action) => {
    return updateObject( state, {
        createProjectSuccess: true,
        loading: false,
        error: null
    });
}

const createProjectFail = (state, action) => {
    return updateObject( state, {
        createProjectSuccess: false,
        loading: false,
        error: action.error
    });
}

const updateProject = (state, action) => {
    return updateObject( state, {
        updateProjectSuccess: null,
        loading: true,
        error: null
    });
}

const updateProjectSuccess = (state, action) => {
    return updateObject( state, {
        updateProjectSuccess: true,
        loading: false,
        error: null
    });
}

const updateProjectFail = (state, action) => {
    return updateObject( state, {
        updateProjectSuccess: false,
        loading: false,
        error: action.error
    });
}

const deleteProject = (state, action) => {
    return updateObject( state, {
        deleteProjectSuccess: null,
        loading: true,
        error: null
    });
}

const deleteProjectSuccess = (state, action) => {
    return updateObject( state, {
        deleteProjectSuccess: true,
        loading: false,
        error: null
    });
}

const deleteProjectFail = (state, action) => {
    return updateObject( state, {
        deleteProjectSuccess: false,
        loading: false,
        error: action.error
    });
}

const clearProjectSuccessStates = (state, action) => {
    return updateObject( state, { 
        createProjectSuccess: null,
        updateProjectSuccess: null,
        deleteProjectSuccess: null
    });
}
const clearProjectErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}


const fetchWebsiteBranding = (state, action) => {
    return updateObject( state, {
        loading: true,
        error: null
    });
}

const fetchWebsiteBrandingSuccess = (state, action) => {
    return updateObject( state, {
        websiteBranding: action.websiteBranding,
        loading: false,
        error: null
    });
}

const fetchWebsiteBrandingFail = (state, action) => {
    return updateObject( state, {
        loading: false,
        error: action.error
    });
}

const createWebsiteBranding = (state, action) => {
    return updateObject( state, {
        createWebsiteBrandingSuccess: null,
        loading: true,
        error: null
    });
}

const createWebsiteBrandingSuccess = (state, action) => {
    return updateObject( state, {
        createWebsiteBrandingSuccess: true,
        loading: false,
        error: null
    });
}

const createWebsiteBrandingFail = (state, action) => {
    return updateObject( state, {
        createWebsiteBrandingSuccess: false,
        loading: false,
        error: action.error
    });
}

const updateWebsiteBranding = (state, action) => {
    return updateObject( state, {
        updateWebsiteBrandingSuccess: null,
        loading: true,
        error: null
    });
}

const updateWebsiteBrandingSuccess = (state, action) => {
    return updateObject( state, {
        updateWebsiteBrandingSuccess: true,
        loading: false,
        error: null
    });
}

const updateWebsiteBrandingFail = (state, action) => {
    return updateObject( state, {
        updateWebsiteBrandingSuccess: false,
        loading: false,
        error: action.error
    });
}

const deleteWebsiteBranding = (state, action) => {
    return updateObject( state, {
        deleteWebsiteBrandingSuccess: null,
        loading: true,
        error: null
    });
}

const deleteWebsiteBrandingSuccess = (state, action) => {
    return updateObject( state, {
        deleteWebsiteBrandingSuccess: true,
        loading: false,
        error: null
    });
}

const deleteWebsiteBrandingFail = (state, action) => {
    return updateObject( state, {
        deleteWebsiteBrandingSuccess: false,
        loading: false,
        error: action.error
    });
}

const clearWebsiteBrandingSuccessStates = (state, action) => {
    return updateObject( state, { 
        createWebsiteBrandingSuccess: null,
        updateWebsiteBrandingSuccess: null,
        deleteWebsiteBrandingSuccess: null
    });
}
const clearWebsiteBrandingErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PROJECTS: return fetchProjects(state, action);
        case actionTypes.FETCH_PROJECTS_SUCCESS: return fetchProjectsSuccess(state, action);
        case actionTypes.FETCH_PROJECTS_FAIL: return fetchProjectsFail(state, action);
        case actionTypes.CREATE_PROJECT: return createProject(state, action);
        case actionTypes.CREATE_PROJECT_SUCCESS: return createProjectSuccess(state, action);
        case actionTypes.CREATE_PROJECT_FAIL: return createProjectFail(state, action);
        case actionTypes.UPDATE_PROJECT: return updateProject(state, action);
        case actionTypes.UPDATE_PROJECT_SUCCESS: return updateProjectSuccess(state, action);
        case actionTypes.UPDATE_PROJECT_FAIL: return updateProjectFail(state, action);
        case actionTypes.DELETE_PROJECT: return deleteProject(state, action);
        case actionTypes.DELETE_PROJECT_SUCCESS: return deleteProjectSuccess(state, action);
        case actionTypes.DELETE_PROJECT_FAIL: return deleteProjectFail(state, action);
        case actionTypes.CLEAR_PROJECT_SUCCESS_STATES: return clearProjectSuccessStates(state, action);
        case actionTypes.CLEAR_PROJECT_ERROR_STATES: return clearProjectErrorStates(state, action);
        case actionTypes.FETCH_WEBSITE_BRANDING: return fetchWebsiteBranding(state, action);
        case actionTypes.FETCH_WEBSITE_BRANDING_SUCCESS: return fetchWebsiteBrandingSuccess(state, action);
        case actionTypes.FETCH_WEBSITE_BRANDING_FAIL: return fetchWebsiteBrandingFail(state, action);
        case actionTypes.CREATE_WEBSITE_BRANDING: return createWebsiteBranding(state, action);
        case actionTypes.CREATE_WEBSITE_BRANDING_SUCCESS: return createWebsiteBrandingSuccess(state, action);
        case actionTypes.CREATE_WEBSITE_BRANDING_FAIL: return createWebsiteBrandingFail(state, action);
        case actionTypes.UPDATE_WEBSITE_BRANDING: return updateWebsiteBranding(state, action);
        case actionTypes.UPDATE_WEBSITE_BRANDING_SUCCESS: return updateWebsiteBrandingSuccess(state, action);
        case actionTypes.UPDATE_WEBSITE_BRANDING_FAIL: return updateWebsiteBrandingFail(state, action);
        case actionTypes.DELETE_WEBSITE_BRANDING: return deleteWebsiteBranding(state, action);
        case actionTypes.DELETE_WEBSITE_BRANDING_SUCCESS: return deleteWebsiteBrandingSuccess(state, action);
        case actionTypes.DELETE_WEBSITE_BRANDING_FAIL: return deleteWebsiteBrandingFail(state, action);
        case actionTypes.CLEAR_WEBSITE_BRANDING_SUCCESS_STATES: return clearWebsiteBrandingSuccessStates(state, action);
        case actionTypes.CLEAR_WEBSITE_BRANDING_ERROR_STATES: return clearWebsiteBrandingErrorStates(state, action);
        default: return state;
    }
}

export default reducer;