// AUTH REDUCER
// Notes: Do not put additional states into auth reducer, or it may cause page to load if auth state is changed

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    loading: null,
    initialLoading: true,
    // Auth States
    userId: null,
    userData: null,
    isAuthenticated: null,
    isReauthenticated: null,
    loginError: null,
    logoutError: null,
    // Registration States
    registrationData: null,
    isRegistered: null,
    registerError: null,
    sendEmailVerificationError: null,
    sendEmailVerificationSuccess: null,
    forgotPasswordError: null,
    forgotPasswordSuccess: null,
    // Delete Account States
    deleteAccountError: null,
    deleteAccountSuccess: null
};

const login = (state, action) => {
    return updateObject(state, {
        loginError: null,
        loading: true
    });
};

const loginSuccess = (state, action) => {
    return updateObject(state, {
        userId: action.userData.userId,
        userData: action.userData,
        isAuthenticated: true,
        isReauthenticated: false,
        loginError: null,
        loading: false
    });
};

const loginFail = (state, action) => {
    return updateObject(state, {
        loginError: action.error,
        loading: false
    });
};

const loginAnonymously = (state, action) => {
    return updateObject(state, {
        loginError: null,
        loading: true
    });
};

const loginAnonymouslySuccess = (state, action) => {
    return updateObject(state, {
        userId: action.userData.userId,
        userData: action.userData,
        loginError: null,
        loading: false
    });
};

const loginAnonymouslyFail = (state, action) => {
    return updateObject(state, {
        loginError: action.error,
        loading: false
    });
};

const logout = (state, action) => {
    return updateObject(state, {
        isReauthenticated: false,
        logoutError: null,
        loading: true
    });
};

const logoutSuccess = (state, action) => {
    return updateObject(state, {
        userId: false,
        isAuthenticated: false,
        logoutError: null,
        loading: false
    });
};

const logoutFail = (state, action) => {
    return updateObject(state, {
        logoutError: action.error,
        loading: false
    });
};

const reauthenticate = (state, action) => {
    return updateObject(state, {
        loginError: null,
        loading: true
    });
};

const reauthenticateSuccess = (state, action) => {
    return updateObject(state, {
        isReauthenticated: true,
        loginError: null,
        loading: false
    });
};

const reauthenticateFail = (state, action) => {
    return updateObject(state, {
        loginError: action.error,
        loading: false
    });
};

const register = (state, action) => {
    return updateObject(state, {
        isRegistered: false,
        registerError: null,
        loading: true
    });
};

const registerSuccess = (state, action) => {
    return updateObject(state, {
        isRegistered: true,
        registerError: null,
        loading: false
    });
};

const registerFail = (state, action) => {
    return updateObject(state, {
        isRegistered: false,
        registerError: action.error,
        loading: false
    });
};

const sendEmailVerification = (state, action) => {
    return updateObject(state, {
        sendEmailVerificationError: null,
        sendEmailVerificationSuccess: null,
        loading: true
    });
};

const sendEmailVerificationSuccess = (state, action) => {
    return updateObject(state, {
        sendEmailVerificationError: null,
        sendEmailVerificationSuccess: true,
        loading: false
    });
};

const sendEmailVerificationFail = (state, action) => {
    return updateObject(state, {
        sendEmailVerificationError: action.error,
        sendEmailVerificationSuccess: null,
        loading: false
    });
};

const forgotPassword = (state, action) => {
    return updateObject(state, {
        forgotPasswordError: null,
        forgotPasswordSuccess: null,
        loading: true
    });
};

const forgotPasswordSuccess = (state, action) => {
    return updateObject(state, {
        forgotPasswordError: null,
        forgotPasswordSuccess: true,
        loading: false
    });
};

const forgotPasswordFail = (state, action) => {
    return updateObject(state, {
        forgotPasswordError: action.error,
        forgotPasswordSuccess: null,
        loading: false
    });
};

const deleteAccount = (state, action) => {
    return updateObject(state, {
        deleteAccountError: null,
        deleteAccountSuccess: null,
        loading: true
    });
};

const deleteAccountSuccess = (state, action) => {
    return updateObject(state, {
        userId: null,
        isAuthenticated: false,
        deleteAccountError: null,
        deleteAccountSuccess: true,
        loading: false
    });
};

const deleteAccountFail = (state, action) => {
    return updateObject(state, {
        deleteAccountError: action.error,
        deleteAccountSuccess: false,
        loading: false
    });
};

const checkAutoLogin = (state, action) => {
    return updateObject(state, {
        loginError: null,
        loading: true,
        initialLoading: true
    });
};

const checkAutoLoginSuccess = (state, action) => {
    return updateObject(state, {
        userId: action.userData.userId,
        userData: action.userData,
        isAuthenticated: true,
        loginError: null,
        loading: false,
        initialLoading: false
    });
};

const checkAutoLoginFail = (state, action) => {
    return updateObject(state, {
        loginError: action.error,
        isAuthenticated: false,
        loading: false,
        initialLoading: false
    });
};

const clearAuthSuccessStates = (state, action) => {
    return updateObject(state, {
        // Registration States
        endEmailVerificationSuccess: null,
        forgotPasswordSuccess: null,
        // Delete Account States
        deleteAccountSuccess: null
    });
};

const clearAuthErrorStates = (state, action) => {
    return updateObject(state, {
        // Auth States
        loginError: null,
        logoutError: null,
        // Registration States
        registerError: null,
        sendEmailVerificationError: null,
        forgotPasswordError: null,
        // Delete Account States
        deleteAccountError: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return login(state, action);
        case actionTypes.LOGIN_SUCCESS:
            return loginSuccess(state, action);
        case actionTypes.LOGIN_FAIL:
            return loginFail(state, action);
        case actionTypes.LOGIN_ANONYMOUSLY:
            return loginAnonymously(state, action);
        case actionTypes.LOGIN_ANONYMOUSLY_SUCCESS:
            return loginAnonymouslySuccess(state, action);
        case actionTypes.LOGIN_ANONYMOUSLY_FAIL:
            return loginAnonymouslyFail(state, action);
        case actionTypes.LOGOUT:
            return logout(state, action);
        case actionTypes.LOGOUT_SUCCESS:
            return logoutSuccess(state, action);
        case actionTypes.LOGOUT_FAIL:
            return logoutFail(state, action);
        case actionTypes.REAUTHENTICATE:
            return reauthenticate(state, action);
        case actionTypes.REAUTHENTICATE_SUCCESS:
            return reauthenticateSuccess(state, action);
        case actionTypes.REAUTHENTICATE_FAIL:
            return reauthenticateFail(state, action);
        case actionTypes.REGISTER:
            return register(state, action);
        case actionTypes.REGISTER_SUCCESS:
            return registerSuccess(state, action);
        case actionTypes.REGISTER_FAIL:
            return registerFail(state, action);
        case actionTypes.SEND_EMAIL_VERIFICATION:
            return sendEmailVerification(state, action);
        case actionTypes.SEND_EMAIL_VERIFICATION_SUCCESS:
            return sendEmailVerificationSuccess(state, action);
        case actionTypes.SEND_EMAIL_VERIFICATION_FAIL:
            return sendEmailVerificationFail(state, action);
        case actionTypes.FORGOT_PASSWORD:
            return forgotPassword(state, action);
        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return forgotPasswordSuccess(state, action);
        case actionTypes.FORGOT_PASSWORD_FAIL:
            return forgotPasswordFail(state, action);
        case actionTypes.DELETE_ACCOUNT:
            return deleteAccount(state, action);
        case actionTypes.DELETE_ACCOUNT_SUCCESS:
            return deleteAccountSuccess(state, action);
        case actionTypes.DELETE_ACCOUNT_FAIL:
            return deleteAccountFail(state, action);
        case actionTypes.CHECK_AUTO_LOGIN:
            return checkAutoLogin(state, action);
        case actionTypes.CHECK_AUTO_LOGIN_SUCCESS:
            return checkAutoLoginSuccess(state, action);
        case actionTypes.CHECK_AUTO_LOGIN_FAIL:
            return checkAutoLoginFail(state, action);
        case actionTypes.CLEAR_AUTH_SUCCESS_STATES:
            return clearAuthSuccessStates(state, action);
        case actionTypes.CLEAR_AUTH_ERROR_STATES:
            return clearAuthErrorStates(state, action);
        default:
            return state;
    }
};

export default reducer;
