import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchLeaguesSaga(action) {
    try {
        const leagues = [];
        let query = null;
        let querySnapshot = null;

        // Determine Sort Fields
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_NAME_ASC:
                sortField = 'name';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_NAME_DESC:
                sortField = 'name';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'name';
                sortDirection = 'asc';
                break;
        }

        // Check if action contains query
        // Requires custom composite index (see error logged if the index does not show)
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'league' || action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            querySnapshot = yield firebase
                .db()
                .collection('leagues')
                .where(query.field, query.operation, query.value)
                .get();
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('leagues')
                .orderBy(sortField, sortDirection)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            leagues.push(items);
        });
        yield put(actions.fetchLeaguesSuccess(leagues));
    } catch (error) {
        // console.log('[fetchLeaguesSaga, error]', error);
        yield put(actions.fetchLeaguesFail(error));
    }
}

export function* createLeagueSaga(action) {
    try {
        const userId = action.userId;
        const leagueData = {
            ...action.league,
            createdAt: firebase.timestamp,
            createdBy: userId,
            updatedAt: firebase.timestamp,
            updatedBy: userId
        };
        yield firebase
            .db()
            .collection('leagues')
            .add(leagueData);
        yield put(actions.fetchLeagues());
        yield put(actions.createLeagueSuccess());
    } catch (error) {
        yield put(actions.createLeagueFail(error));
    }
}

export function* updateLeagueSaga(action) {
    try {
        const leagueData = { ...action.league, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('leagues')
            .doc(action.leagueId)
            .update(leagueData);
        yield put(actions.fetchLeagues());
        yield put(actions.updateLeagueSuccess());
    } catch (error) {
        yield put(actions.updateLeagueFail(error));
    }
}

export function* deleteLeagueSaga(action) {
    try {
        const leagueData = { ...action.league };
        yield firebase
            .db()
            .collection('leagues')
            .doc(leagueData.id)
            .delete();
        yield put(actions.fetchLeagues());
        yield put(actions.deleteLeagueSuccess());
    } catch (error) {
        yield put(actions.deleteLeagueFail(error));
    }
}
