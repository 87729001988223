import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import styles from './MainPanelUpcomingGamesByLocation.module.css';
import * as actions from '../../../store/actions';
import SingleRowDatePicker from '../../../components/Website/MainPanel/SingleRowDatePicker/SingleRowDatePicker';
import ViewGamesByDate from '../../Games/ViewGamesByDate/ViewGamesByDate';

const initialDate = moment().format('YYYY-MM-DD');

const MainPanelUpcomingGamesByLocation = props => {
    // Initializing State for Dates
    const [selectedDate, setSelectedDate] = useState(initialDate);

    // Initializing Props from Store
    const { websiteBranding, nextGameDate, nextGameDateSuccess, onFetchWebsiteBranding, onFetchNextGameDate, onClearGameSuccessStates } = props;
    const nextGameDateRef = useRef();

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--mainPanelBackgroundColor', branding.theme.mainPanelBackgroundColor);
            document.documentElement.style.setProperty('--mainPanelHeadingTextColor', branding.theme.mainPanelHeadingTextColor);
            document.documentElement.style.setProperty('--mainPanelSubtitleTextColor', branding.theme.mainPanelSubtitleTextColor);
            document.documentElement.style.setProperty('--mainPanelBodyTextColor', branding.theme.mainPanelBodyTextColor);
        }
    });

    // Get next game date
    useEffect(() => {
        if (!nextGameDateRef.current) {
            onFetchNextGameDate();
        }
        return () => {
            // Remove nextGameDateRef value on unmount to retrieve a new 'nextGameDate' on reload
            // (Since nextGameDate is preserved by Redux)
            nextGameDateRef.current = null;
        }
    }, [nextGameDateRef, onFetchNextGameDate]);

    // Set Next Game Date on initial load
    useEffect(() => {
        if (nextGameDateSuccess && nextGameDate) {
            setSelectedDate(nextGameDate);
            onClearGameSuccessStates();
        }
    }, [nextGameDateSuccess, nextGameDate, onClearGameSuccessStates]);

    // Manage Schedule Picker Date
    const handleDateChange = date => {
        setSelectedDate(date);
    };

    return (
        <div className={styles.MainPanelUpcomingGamesByLocation}>
            <div className={styles.UpcomingGamesHeading}>
                <h4>
                    <strong>Schedules</strong>
                </h4>
            </div>
            <SingleRowDatePicker branding={websiteBranding} selectedDate={selectedDate} onDateChanged={handleDateChange} nextGameDate={nextGameDate} />
            <ViewGamesByDate selectedDate={selectedDate} />
        </div>
    );
};

const mapStateToProps = state => {
    return {
        websiteBranding: state.websiteConfiguration.websiteBranding,
        nextGameDate: state.games.nextGameDate,
        nextGameDateSuccess: state.games.nextGameDateSuccess,
        error: state.games.error,
        loading: state.games.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchNextGameDate: () => dispatch(actions.fetchNextGameDate()),
        onClearGameSuccessStates: () => dispatch(actions.clearGameSuccessStates())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainPanelUpcomingGamesByLocation);
