import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchAllAnnouncementsSaga(action) {
    try {
        const announcements = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('announcements')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            announcements.push(items);
        });
        yield put(actions.fetchAllAnnouncementsSuccess(announcements));
    } catch (error) {
        // console.log('fetchAllAnnouncements error', error);
        yield put(actions.fetchAllAnnouncementsFail(error));
    }
}

export function* fetchPublishedAnnouncementsSaga(action) {
    try {
        const announcements = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('announcements')
            .where('status', '==', 'Published')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            announcements.push(items);
        });
        yield put(actions.fetchPublishedAnnouncementsSuccess(announcements));
    } catch (error) {
        // console.log('fetchPublishedAnnouncements error', error);
        yield put(actions.fetchPublishedAnnouncementsFail(error));
    }
}

export function* fetchAnnouncementSaga(action) {
    try {
        let announcement = null;
        const announcementId = action.announcementId;
        const querySnapshot = yield firebase
            .db()
            .collection('announcements')
            .doc(announcementId)
            .get();
        if (querySnapshot.exists) {
            announcement = yield querySnapshot.data();
            announcement = { ...announcement, id: querySnapshot.id };
        }
        yield put(actions.fetchAnnouncementSuccess(announcement));
    } catch (error) {
        // console.log('fetchAnnouncement error', error);
        yield put(actions.fetchAnnouncementFail(error));
    }
}

export function* createAnnouncementSaga(action) {
    try {
        const announcementData = {
            ...action.announcement,
            createdAt: firebase.timestamp,
            updatedAt: firebase.timestamp,
            createdBy: action.userId,
            updatedBy: action.userId
        };

        if (announcementData.status === 'Published') {
            announcementData.publishedAt = firebase.timestamp;
            announcementData.publishedBy = action.userId;
        } else {
            announcementData.publishedAt = '';
            announcementData.publishedBy = '';
        }

        yield firebase
            .db()
            .collection('announcements')
            .add(announcementData);
        yield put(actions.createAnnouncementSuccess());
    } catch (error) {
        // console.log('[createAnnouncementSaga > error]', error);
        yield put(actions.createAnnouncementFail(error));
    }
}

export function* updateAnnouncementSaga(action) {
    try {
        const announcementData = {
            ...action.announcement,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const announcementId = action.announcementId;

        if (announcementData.status === 'Published') {
            announcementData.publishedAt = firebase.timestamp;
            announcementData.publishedBy = action.userId;
        } else {
            announcementData.publishedAt = '';
            announcementData.publishedBy = '';
        }

        yield firebase
            .db()
            .collection('announcements')
            .doc(announcementId)
            .update(announcementData);
        yield put(actions.updateAnnouncementSuccess());
    } catch (error) {
        // console.log('[updateAnnouncementSaga > error]', error);
        yield put(actions.updateAnnouncementFail(error));
    }
}

export function* deleteAnnouncementSaga(action) {
    try {
        const announcementData = {
            ...action.announcement
        };
        const userId = action.userId;
        const announcementId = announcementData.id;
        const uploadedImages = [];
        if (announcementData.uploadedImages) {
            if (announcementData.uploadedImages.length > 0) {
                uploadedImages.push(...announcementData.uploadedImages);
            }
        }

        yield firebase
            .db()
            .collection('announcements')
            .doc(announcementId)
            .delete();
        yield put(actions.deleteImages(userId, uploadedImages));
        yield put(actions.deleteAnnouncementSuccess());
    } catch (error) {
        yield put(actions.deleteAnnouncementFail(error));
    }
}
