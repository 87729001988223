// NEWS ACTION

import * as actionTypes from './actionTypes';

export const fetchAllNewsArticles = (sort) => {
    return {
        type: actionTypes.FETCH_ALL_NEWS_ARTICLES,
        sort: sort
    };
}

export const fetchAllNewsArticlesSuccess = (newsArticles) => {
    return {
        type: actionTypes.FETCH_ALL_NEWS_ARTICLES_SUCCESS,
        newsArticles: newsArticles
    };
}

export const fetchAllNewsArticlesFail = (error) => {
    return {
        type: actionTypes.FETCH_ALL_NEWS_ARTICLES_FAIL,
        error: error
    };
}

export const fetchPublishedNewsArticles = (sort) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES,
        sort: sort
    };
}

export const fetchPublishedNewsArticlesSuccess = (publishedNewsArticles) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES_SUCCESS,
        publishedNewsArticles: publishedNewsArticles
    };
}

export const fetchPublishedNewsArticlesFail = (error) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES_FAIL,
        error: error
    };
}

export const fetchPublishedHighlights = (sort) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_HIGHLIGHTS,
        sort: sort
    };
}

export const fetchPublishedHighlightsSuccess = (publishedHighlights) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_HIGHLIGHTS_SUCCESS,
        publishedHighlights: publishedHighlights
    };
}

export const fetchPublishedHighlightsFail = (error) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_HIGHLIGHTS_FAIL,
        error: error
    };
}

export const fetchNewsArticle = (newsArticleId) => {
    return {
        type: actionTypes.FETCH_NEWS_ARTICLE,
        newsArticleId: newsArticleId
    };
}

export const fetchNewsArticleSuccess = (newsArticle) => {
    return {
        type: actionTypes.FETCH_NEWS_ARTICLE_SUCCESS,
        newsArticle: newsArticle
    };
}

export const fetchNewsArticleFail = (error) => {
    return {
        type: actionTypes.FETCH_NEWS_ARTICLE_FAIL,
        error: error
    };
}

export const createNewsArticle = (userId, newsArticle) => {
    return {
        type: actionTypes.CREATE_NEWS_ARTICLE,
        userId: userId,
        newsArticle: newsArticle
    };
}

export const createNewsArticleSuccess = () => {
    return {
        type: actionTypes.CREATE_NEWS_ARTICLE_SUCCESS
    };
}

export const createNewsArticleFail = (error) => {
    return {
        type: actionTypes.CREATE_NEWS_ARTICLE_FAIL,
        error: error
    };
}

export const updateNewsArticle = (userId, newsArticle, newsArticleId) => {
    return {
        type: actionTypes.UPDATE_NEWS_ARTICLE,
        userId: userId,
        newsArticle: newsArticle,
        newsArticleId: newsArticleId
    };
}

export const updateNewsArticleSuccess = () => {
    return {
        type: actionTypes.UPDATE_NEWS_ARTICLE_SUCCESS
    };
}

export const updateNewsArticleFail = (error) => {
    return {
        type: actionTypes.UPDATE_NEWS_ARTICLE_FAIL,
        error: error
    };
}

export const deleteNewsArticle = (userId, newsArticle) => {
    return {
        type: actionTypes.DELETE_NEWS_ARTICLE,
        userId: userId,
        newsArticle: newsArticle
    };
}

export const deleteNewsArticleSuccess = () => {
    return {
        type: actionTypes.DELETE_NEWS_ARTICLE_SUCCESS
    };
}

export const deleteNewsArticleFail = (error) => {
    return {
        type: actionTypes.DELETE_NEWS_ARTICLE_FAIL,
        error: error
    };
}

export const clearNewsArticleSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_NEWS_ARTICLE_SUCCESS_STATES
    };
}

export const clearNewsArticleErrorStates = () => {
    return {
        type: actionTypes.CLEAR_NEWS_ARTICLE_ERROR_STATES
    };
}