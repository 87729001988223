import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchAllAlertsSaga(action) {
    try {
        const alerts = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('alerts')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            alerts.push(items);
        });
        yield put(actions.fetchAllAlertsSuccess(alerts));
    } catch (error) {
        // console.log('fetchAllAlerts error', error);
        yield put(actions.fetchAllAlertsFail(error));
    }
}

export function* fetchPublishedAlertsSaga(action) {
    try {
        const alerts = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('alerts')
            .where('status', '==', 'Published')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            alerts.push(items);
        });
        yield put(actions.fetchPublishedAlertsSuccess(alerts));
    } catch (error) {
        // console.log('fetchPublishedAlerts error', error);
        yield put(actions.fetchPublishedAlertsFail(error));
    }
}

export function* fetchAlertSaga(action) {
    try {
        let alert = null;
        const alertId = action.alertId;
        const querySnapshot = yield firebase
            .db()
            .collection('alerts')
            .doc(alertId)
            .get();
        if (querySnapshot.exists) {
            alert = yield querySnapshot.data();
            alert = { ...alert, id: querySnapshot.id };
        }
        yield put(actions.fetchAlertSuccess(alert));
    } catch (error) {
        // console.log('fetchAlert error', error);
        yield put(actions.fetchAlertFail(error));
    }
}

export function* createAlertSaga(action) {
    try {
        const alertData = {
            ...action.alert,
            createdAt: firebase.timestamp,
            updatedAt: firebase.timestamp,
            createdBy: action.userId,
            updatedBy: action.userId
        };

        if (alertData.status === 'Published') {
            alertData.publishedAt = firebase.timestamp;
            alertData.publishedBy = action.userId;
        } else {
            alertData.publishedAt = '';
            alertData.publishedBy = '';
        }

        yield firebase
            .db()
            .collection('alerts')
            .add(alertData);
        yield put(actions.createAlertSuccess());
    } catch (error) {
        // console.log('[createAlertSaga > error]', error);
        yield put(actions.createAlertFail(error));
    }
}

export function* updateAlertSaga(action) {
    try {
        const alertData = {
            ...action.alert,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const alertId = action.alertId;

        if (alertData.status === 'Published') {
            alertData.publishedAt = firebase.timestamp;
            alertData.publishedBy = action.userId;
        } else {
            alertData.publishedAt = '';
            alertData.publishedBy = '';
        }

        yield firebase
            .db()
            .collection('alerts')
            .doc(alertId)
            .update(alertData);
        yield put(actions.updateAlertSuccess());
    } catch (error) {
        // console.log('[updateAlertSaga > error]', error);
        yield put(actions.updateAlertFail(error));
    }
}

export function* deleteAlertSaga(action) {
    try {
        const alertData = {
            ...action.alert
        };
        const userId = action.userId;
        const alertId = alertData.id;
        const uploadedImages = [];
        if (alertData.uploadedImages) {
            if (alertData.uploadedImages.length > 0) {
                uploadedImages.push(...alertData.uploadedImages);
            }
        }

        yield firebase
            .db()
            .collection('alerts')
            .doc(alertId)
            .delete();
        yield put(actions.deleteImages(userId, uploadedImages));
        yield put(actions.deleteAlertSuccess());
    } catch (error) {
        yield put(actions.deleteAlertFail(error));
    }
}
