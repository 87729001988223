import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchTeamsSaga(action) {
    try {
        const teams = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_NAME_ASC:
                sortField = 'name';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_NAME_DESC:
                sortField = 'name';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'name';
                sortDirection = 'asc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('teams')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            teams.push(items);
        });
        yield put(actions.fetchTeamsSuccess(teams));
    } catch (error) {
        yield put(actions.fetchTeamsFail());
    }
}

export function* createTeamSaga(action) {
    try {
        const teamData = {
            ...action.team,
            createdAt: firebase.timestamp,
            createdBy: action.userId,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        yield firebase
            .db()
            .collection('teams')
            .add(teamData);
        yield put(actions.fetchTeams());
        yield put(actions.createTeamSuccess());
    } catch (error) {
        // console.log('[createTeamSaga > error]', error);
        yield put(actions.createTeamFail(error));
    }
}

export function* createBulkTeamsSaga(action) {
    try {
        const teamsData = [...action.teams];
        const batch = yield firebase.db().batch();

        yield teamsData.forEach(team => {
            const teamData = {
                ...team,
                createdAt: firebase.timestamp,
                updatedAt: firebase.timestamp,
                createdBy: action.userId,
                updatedBy: action.userId
            };
            const teamDataRef = firebase
                .db()
                .collection('teams')
                .doc();
            batch.set(teamDataRef, teamData);
        });
        yield batch.commit();
        yield put(actions.createBulkTeamsSuccess());
    } catch (error) {
        // console.log('[createBulkTeamsSaga > error]', error);
        yield put(actions.createBulkTeamsFail(error));
    }
}

export function* updateTeamSaga(action) {
    try {
        const teamData = {
            ...action.team,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const teamId = action.teamId;
        yield firebase
            .db()
            .collection('teams')
            .doc(teamId)
            .update(teamData);
        yield put(actions.fetchTeams());
        yield put(actions.updateTeamSuccess());
    } catch (error) {
        // console.log('[updateTeamSaga > error]', error);
        yield put(actions.updateTeamFail(error));
    }
}

export function* addTeamToSeasonSaga(action) {
    try {
        const teamData = {
            ...action.team,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const teamId = action.teamId;
        yield firebase
            .db()
            .collection('teams')
            .doc(teamId)
            .update({
                seasons: firebase.arrayUnion(teamData.season)
            });
        yield put(actions.fetchTeams());
        yield put(actions.addTeamToSeasonSuccess());
    } catch (error) {
        // console.log('[addTeamToSeasonSaga > error]', error);
        yield put(actions.addTeamToSeasonFail(error));
    }
}

export function* removeTeamFromSeasonSaga(action) {
    try {
        const teamData = {
            ...action.team,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const teamId = action.teamId;
        yield firebase
            .db()
            .collection('teams')
            .doc(teamId)
            .update({
                seasons: teamData.seasons
            });
        yield put(actions.fetchTeams());
        yield put(actions.removeTeamFromSeasonSuccess());
    } catch (error) {
        // console.log('[removeTeamFromSeasonSaga > error]', error);
        yield put(actions.removeTeamFromSeasonFail(error));
    }
}

export function* deleteTeamSaga(action) {
    try {
        const teamData = { ...action.team };
        yield firebase
            .db()
            .collection('teams')
            .doc(teamData.id)
            .delete();
        yield put(actions.fetchTeams());
        yield put(actions.deleteTeamSuccess());
    } catch (error) {
        // console.log('[deleteTeamSaga > error]', error);
        yield put(actions.deleteTeamFail(error));
    }
}
