import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';
import * as sortTypes from '../../../utilities/helpers/types';
import GamesBySeasonAndLocation from '../GamesBySeasonAndLocation/GamesBySeasonAndLocation';

const initialSortState = sortTypes.SORT_BY_DATE_ASC;

const ViewUpcomingGamesByLocation = props => {
    // Initializing State for Sort
    const [sortState] = useState(initialSortState);

    // Initialize Props from Component
    const { filteredSeasons, heading } = props;

    // Initialize Props From Store
    const {
        upcomingGames,
        leagues,
        seasons,
        teams,
        locations,
        onFetchUpcomingGames,
        onFetchLeagues,
        onFetchSeasons,
        onFetchTeams,
        onFetchLocations,
        loading,
        error
    } = props;

    const setUpcomingGamesHandler = useCallback(
        (sort, query) => {
            onFetchUpcomingGames(sort, query);
        },
        [onFetchUpcomingGames]
    );

    useEffect(() => {
        setUpcomingGamesHandler(sortState);
        if (!leagues) {
            onFetchLeagues();
        }
        if (!seasons) {
            onFetchSeasons();
        }
        if (!teams) {
            onFetchTeams();
        }
        if (!locations) {
            onFetchLocations();
        }
    }, [
        setUpcomingGamesHandler,
        sortState,
        leagues,
        seasons,
        teams,
        locations,
        onFetchLeagues,
        onFetchSeasons,
        onFetchTeams,
        onFetchLocations
    ]);

    return (
        <>
            {heading ? <h1>{heading}</h1> : null}
            <GamesBySeasonAndLocation
                leagues={leagues}
                seasons={filteredSeasons ? filteredSeasons : seasons}
                teams={teams}
                locations={locations}
                games={upcomingGames}
                loading={loading}
                error={error}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        teams: state.teams.teams,
        locations: state.locations.locations,
        upcomingGames: state.games.upcomingGames,
        error: state.games.error,
        loading: state.games.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchUpcomingGames: (sort, query) => dispatch(actions.fetchUpcomingGames(sort, query)),
        onFetchLeagues: sort => dispatch(actions.fetchLeagues(sort)),
        onFetchSeasons: sort => dispatch(actions.fetchSeasons(sort)),
        onFetchTeams: sort => dispatch(actions.fetchTeams(sort)),
        onFetchLocations: () => dispatch(actions.fetchLocations())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewUpcomingGamesByLocation);
