// ADMIN ACTIONS

import * as actionTypes from './actionTypes';

export const fetchAllProfiles = () => {
    return {
        type: actionTypes.FETCH_ALL_PROFILES
    };
}

export const fetchAllProfilesSuccess = (userProfiles) => {
    return {
        type: actionTypes.FETCH_ALL_PROFILES_SUCCESS,
        userProfiles: userProfiles
    };
}

export const fetchAllProfilesFail = (error) => {
    return {
        type: actionTypes.FETCH_ALL_PROFILES_FAIL,
        error: error
    };
}
