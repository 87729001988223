import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';
import * as actions from './store/actions';
import Home from './containers/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import League from './containers/League/League';
import Navigation from './components/Navigation/Navigation';

const App = props => {
    // Initialize Props from Store
    const {
        profile,
        websiteBranding,
        leagues,
        seasons,
        onFetchCurrentProfile,
        onFetchWebsiteBranding,
        onFetchLeagues,
        onFetchSeasons,
        isAuthenticated,
        onCheckAutoLogin,
        onLoginAnonymously
    } = props;

    // Login Anonymously
    useEffect(() => {
        onCheckAutoLogin();
        if (isAuthenticated === false) {
            onLoginAnonymously();
        }
    }, [isAuthenticated, onCheckAutoLogin, onLoginAnonymously]);

    // Get Profile
    useEffect(() => {
        if (!profile) {
            onFetchCurrentProfile();
        }
    }, [profile, onFetchCurrentProfile]);

    // Get Website Branding
    useEffect(() => {
        if (profile && !websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [profile, websiteBranding, onFetchWebsiteBranding]);

    // Get Leagues & Seasons (For Navigation)
    useEffect(() => {
        if (profile && !leagues) {
            onFetchLeagues();
        }
    }, [profile, leagues, onFetchLeagues]);

    useEffect(() => {
        if (profile && !seasons) {
            onFetchSeasons();
        }
    }, [profile, seasons, onFetchSeasons]);

    const routes = (
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/league/:leaugeId/about/' exact component={About} />
            {/* <Route path="/league/past-results/" exact component={About} /> */}
            <Route path='/league/:leagueId/season/:seasonId/:view' exact component={League} />
            <Redirect to='/' />
        </Switch>
    );

    return (
        <BrowserRouter>
            <div className='App'>
                <Navigation branding={websiteBranding} leagues={leagues} seasons={seasons} />
                {routes}
            </div>
        </BrowserRouter>
    );
};

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        userData: state.auth.userData,
        isAuthenticated: state.auth.isAuthenticated,
        profile: state.user.currentProfile,
        websiteBranding: state.websiteConfiguration.websiteBranding,
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        error: state.auth.error,
        loading: state.auth.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLoginAnonymously: () => dispatch(actions.loginAnonymously()),
        onCheckAutoLogin: () => dispatch(actions.checkAutoLogin()),
        onFetchCurrentProfile: () => dispatch(actions.fetchCurrentProfile()),
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchLeagues: (sort, query) => dispatch(actions.fetchLeagues(sort, query)),
        onFetchSeasons: (sort, query) => dispatch(actions.fetchSeasons(sort, query))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
