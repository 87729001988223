// ACTION TYPES

export const FETCH_ALL_PROFILES = 'FETCH_ALL_PROFILES';
export const FETCH_ALL_PROFILES_SUCCESS = 'FETCH_ALL_PROFILES_SUCCESS';
export const FETCH_ALL_PROFILES_FAIL = 'FETCH_ALL_PROFILES_FAIL';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_ANONYMOUSLY = 'LOGIN_ANONYMOUSLY';
export const LOGIN_ANONYMOUSLY_SUCCESS = 'LOGIN_ANONYMOUSLY_SUCCESS';
export const LOGIN_ANONYMOUSLY_FAIL = 'LOGIN_ANONYMOUSLY_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';
export const REAUTHENTICATE = 'REAUTHENTICATE';
export const REAUTHENTICATE_SUCCESS = 'REAUTHENTICATE_SUCCESS';
export const REAUTHENTICATE_FAIL = 'REAUTHENTICATE_FAIL';
export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SEND_EMAIL_VERIFICATION = 'SEND_EMAIL_VERIFICATION';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAIL = 'SEND_EMAIL_VERIFICATION_FAIL';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';
export const CHECK_AUTO_LOGIN = 'CHECK_AUTO_LOGIN';
export const CHECK_AUTO_LOGIN_SUCCESS = 'CHECK_AUTO_LOGIN_SUCCESS';
export const CHECK_AUTO_LOGIN_FAIL = 'CHECK_AUTO_LOGIN_FAIL';
export const CLEAR_AUTH_SUCCESS_STATES = 'CLEAR_AUTH_SUCCESS_STATES';
export const CLEAR_AUTH_ERROR_STATES = 'CLEAR_AUTH_ERROR_STATES';

export const FETCH_CURRENT_PROFILE = 'FETCH_CURRENT_PROFILE';
export const FETCH_CURRENT_PROFILE_SUCCESS = 'FETCH_CURRENT_PROFILE_SUCCESS';
export const FETCH_CURRENT_PROFILE_FAIL = 'FETCH_CURRENT_PROFILE_FAIL';
export const FETCH_PROFILE_BY_ID = 'FETCH_PROFILE_BY_ID';
export const FETCH_PROFILE_BY_ID_SUCCESS = 'FETCH_PROFILE_BY_ID_SUCCESS';
export const FETCH_PROFILE_BY_ID_FAIL = 'FETCH_PROFILE_BY_ID_FAIL';
export const FETCH_PROFILE_BY_EMAIL = 'FETCH_PROFILE_BY_EMAIL';
export const FETCH_PROFILE_BY_EMAIL_SUCCESS = 'FETCH_PROFILE_BY_EMAIL_SUCCESS';
export const FETCH_PROFILE_BY_EMAIL_FAIL = 'FETCH_PROFILE_BY_EMAIL_FAIL';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const CREATE_PROFILE_SUCCESS = 'CREATE_PROFILE_SUCCESS';
export const CREATE_PROFILE_FAIL = 'CREATE_PROFILE_FAIL';
export const CREATE_ANONYMOUS_PROFILE_FAIL = 'CREATE_ANONYMOUS_PROFILE_FAIL';
export const CREATE_ANONYMOUS_PROFILE = 'CREATE_ANONYMOUS_PROFILE';
export const CREATE_ANONYMOUS_PROFILE_SUCCESS = 'CREATE_ANONYMOUS_PROFILE_SUCCESS';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAIL = 'DELETE_PROFILE_FAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAIL = 'UPDATE_EMAIL_FAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
export const CLEAR_USER_SUCCESS_STATES = 'CLEAR_USER_SUCCESS_STATES';
export const CLEAR_USER_ERROR_STATES = 'CLEAR_USER_ERROR_STATES';

export const FETCH_LEAGUES = 'FETCH_LEAGUES';
export const FETCH_LEAGUES_SUCCESS = 'FETCH_LEAGUES_SUCCESS';
export const FETCH_LEAGUES_FAIL = 'FETCH_LEAGUES_FAIL';
export const CREATE_LEAGUE = 'CREATE_LEAGUE';
export const CREATE_LEAGUE_SUCCESS = 'CREATE_LEAGUE_SUCCESS';
export const CREATE_LEAGUE_FAIL = 'CREATE_LEAGUE_FAIL';
export const UPDATE_LEAGUE_SUCCESS = 'UPDATE_LEAGUE_SUCCESS';
export const UPDATE_LEAGUE = 'UPDATE_LEAGUE';
export const UPDATE_LEAGUE_FAIL = 'UPDATE_LEAGUE_FAIL';
export const DELETE_LEAGUE = 'DELETE_LEAGUE';
export const DELETE_LEAGUE_SUCCESS = 'DELETE_LEAGUE_SUCCESS';
export const DELETE_LEAGUE_FAIL = 'DELETE_LEAGUE_FAIL';
export const CLEAR_LEAGUE_SUCCESS_STATES = 'CLEAR_LEAGUE_SUCCESS_STATES';
export const CLEAR_LEAGUE_ERROR_STATES = 'CLEAR_LEAGUE_ERROR_STATES';

export const FETCH_SEASONS = 'FETCH_SEASONS';
export const FETCH_SEASONS_SUCCESS = 'FETCH_SEASONS_SUCCESS';
export const FETCH_SEASONS_FAIL = 'FETCH_SEASONS_FAIL';
export const CREATE_SEASON = 'CREATE_SEASON';
export const CREATE_SEASON_SUCCESS = 'CREATE_SEASON_SUCCESS';
export const CREATE_SEASON_FAIL = 'CREATE_SEASON_FAIL';
export const UPDATE_SEASON_SUCCESS = 'UPDATE_SEASON_SUCCESS';
export const UPDATE_SEASON = 'UPDATE_SEASON';
export const UPDATE_SEASON_FAIL = 'UPDATE_SEASON_FAIL';
export const PIN_SEASON_SUCCESS = 'PIN_SEASON_SUCCESS';
export const PIN_SEASON = 'PIN_SEASON';
export const PIN_SEASON_FAIL = 'PIN_SEASON_FAIL';
export const REMOVE_PIN_FROM_SEASON_SUCCESS = 'REMOVE_PIN_FROM_SEASON_SUCCESS';
export const REMOVE_PIN_FROM_SEASON = 'REMOVE_PIN_FROM_SEASON';
export const REMOVE_PIN_FROM_SEASON_FAIL = 'REMOVE_PIN_FROM_SEASON_FAIL';
export const DELETE_SEASON = 'DELETE_SEASON';
export const DELETE_SEASON_SUCCESS = 'DELETE_SEASON_SUCCESS';
export const DELETE_SEASON_FAIL = 'DELETE_SEASON_FAIL';
export const CLEAR_SEASON_SUCCESS_STATES = 'CLEAR_SEASON_SUCCESS_STATES';
export const CLEAR_SEASON_ERROR_STATES = 'CLEAR_SEASON_ERROR_STATES';

export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAIL = 'FETCH_TEAMS_FAIL';
export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAIL = 'CREATE_TEAM_FAIL';
export const CREATE_BULK_TEAMS = 'CREATE_BULK_TEAMS';
export const CREATE_BULK_TEAMS_SUCCESS = 'CREATE_BULK_TEAMS_SUCCESS';
export const CREATE_BULK_TEAMS_FAIL = 'CREATE_BULK_TEAMS_FAIL';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAIL = 'UPDATE_TEAM_FAIL';
export const ADD_TEAM_TO_SEASON = 'ADD_TEAM_TO_SEASON';
export const ADD_TEAM_TO_SEASON_SUCCESS = 'ADD_TEAM_TO_SEASON_SUCCESS';
export const ADD_TEAM_TO_SEASON_FAIL = 'ADD_TEAM_TO_SEASON_FAIL';
export const REMOVE_TEAM_FROM_SEASON = 'REMOVE_TEAM_FROM_SEASON';
export const REMOVE_TEAM_FROM_SEASON_SUCCESS = 'REMOVE_TEAM_FROM_SEASON_SUCCESS';
export const REMOVE_TEAM_FROM_SEASON_FAIL = 'REMOVE_TEAM_FROM_SEASON_FAIL';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAIL = 'DELETE_TEAM_FAIL';
export const CLEAR_TEAM_SUCCESS_STATES = 'CLEAR_TEAM_SUCCESS_STATES';
export const CLEAR_TEAM_ERROR_STATES = 'CLEAR_TEAM_ERROR_STATES';

export const FETCH_PLAYERS = 'FETCH_PLAYERS';
export const FETCH_PLAYERS_SUCCESS = 'FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_FAIL = 'FETCH_PLAYERS_FAIL';
export const CREATE_PLAYER = 'CREATE_PLAYER';
export const CREATE_PLAYER_SUCCESS = 'CREATE_PLAYER_SUCCESS';
export const CREATE_PLAYER_FAIL = 'CREATE_PLAYER_FAIL';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAIL = 'UPDATE_PLAYER_FAIL';
export const ADD_PLAYER_TO_TEAM = 'ADD_PLAYER_TO_TEAM';
export const ADD_PLAYER_TO_TEAM_SUCCESS = 'ADD_PLAYER_TO_TEAM_SUCCESS';
export const ADD_PLAYER_TO_TEAM_FAIL = 'ADD_PLAYER_TO_TEAM_FAIL';
export const REMOVE_PLAYER_FROM_TEAM = 'REMOVE_PLAYER_FROM_TEAM';
export const REMOVE_PLAYER_FROM_TEAM_SUCCESS = 'REMOVE_PLAYER_FROM_TEAM_SUCCESS';
export const REMOVE_PLAYER_FROM_TEAM_FAIL = 'REMOVE_PLAYER_FROM_TEAM_FAIL';
export const DELETE_PLAYER = 'DELETE_PLAYER';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_FAIL = 'DELETE_PLAYER_FAIL';
export const CLEAR_PLAYER_SUCCESS_STATES = 'CLEAR_PLAYER_SUCCESS_STATES';
export const CLEAR_PLAYER_ERROR_STATES = 'CLEAR_PLAYER_ERROR_STATES';

export const FETCH_GAMES = 'FETCH_GAMES';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const FETCH_GAMES_FAIL = 'FETCH_GAMES_FAIL';
export const FETCH_GAMES_BY_DATE = 'FETCH_GAMES_BY_DATE';
export const FETCH_GAMES_BY_DATE_SUCCESS = 'FETCH_GAMES_BY_DATE_SUCCESS';
export const FETCH_GAMES_BY_DATE_FAIL = 'FETCH_GAMES_BY_DATE_FAIL';
export const FETCH_GAMES_BY_QUERY = 'FETCH_GAMES_BY_QUERY';
export const FETCH_GAMES_BY_QUERY_SUCCESS = 'FETCH_GAMES_BY_QUERY_SUCCESS';
export const FETCH_GAMES_BY_QUERY_FAIL = 'FETCH_GAMES_BY_QUERY_FAIL';
export const FETCH_UPCOMING_GAMES = 'FETCH_UPCOMING_GAMES';
export const FETCH_UPCOMING_GAMES_SUCCESS = 'FETCH_UPCOMING_GAMES_SUCCESS';
export const FETCH_UPCOMING_GAMES_FAIL = 'FETCH_UPCOMING_GAMES_FAIL';
export const FETCH_COMPLETED_GAMES = 'FETCH_COMPLETED_GAMES';
export const FETCH_COMPLETED_GAMES_SUCCESS = 'FETCH_COMPLETED_GAMES_SUCCESS';
export const FETCH_COMPLETED_GAMES_FAIL = 'FETCH_COMPLETED_GAMES_FAIL';
export const FETCH_PREVIOUS_GAMES = 'FETCH_PREVIOUS_GAMES';
export const FETCH_PREVIOUS_GAMES_SUCCESS = 'FETCH_PREVIOUS_GAMES_SUCCESS';
export const FETCH_PREVIOUS_GAMES_FAIL = 'FETCH_PREVIOUS_GAMES_FAIL';
export const FETCH_NEXT_GAME_DATE = 'FETCH_NEXT_GAME_DATE';
export const FETCH_NEXT_GAME_DATE_SUCCESS = 'FETCH_NEXT_GAME_DATE_SUCCESS';
export const FETCH_NEXT_GAME_DATE_FAIL = 'FETCH_NEXT_GAME_DATE_FAIL';
export const CREATE_GAME = 'CREATE_GAME';
export const CREATE_GAME_SUCCESS = 'CREATE_GAME_SUCCESS';
export const CREATE_GAME_FAIL = 'CREATE_GAME_FAIL';
export const CREATE_BULK_GAMES = 'CREATE_BULK_GAMES';
export const CREATE_BULK_GAMES_SUCCESS = 'CREATE_BULK_GAMES_SUCCESS';
export const CREATE_BULK_GAMES_FAIL = 'CREATE_BULK_GAMES_FAIL';
export const UPDATE_GAME_SUCCESS = 'UPDATE_GAME_SUCCESS';
export const UPDATE_GAME = 'UPDATE_GAME';
export const UPDATE_GAME_FAIL = 'UPDATE_GAME_FAIL';
export const UPDATE_BULK_GAMES = 'UPDATE_BULK_GAMES';
export const UPDATE_BULK_GAMES_SUCCESS = 'UPDATE_BULK_GAMES_SUCCESS';
export const UPDATE_BULK_GAMES_FAIL = 'UPDATE_BULK_GAMES_FAIL';
export const DELETE_GAME = 'DELETE_GAME';
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS';
export const DELETE_GAME_FAIL = 'DELETE_GAME_FAIL';
export const CLEAR_GAME_SUCCESS_STATES = 'CLEAR_GAME_SUCCESS_STATES';
export const CLEAR_GAME_ERROR_STATES = 'CLEAR_GAME_ERROR_STATES';

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAIL = 'FETCH_LOCATIONS_FAIL';
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';
export const CLEAR_LOCATION_SUCCESS_STATES = 'CLEAR_LOCATION_SUCCESS_STATES';
export const CLEAR_LOCATION_ERROR_STATES = 'CLEAR_LOCATION_ERROR_STATES';

export const FETCH_ALL_ALERTS = 'FETCH_ALL_ALERTS';
export const FETCH_ALL_ALERTS_SUCCESS = 'FETCH_ALL_ALERTS_SUCCESS';
export const FETCH_ALL_ALERTS_FAIL = 'FETCH_ALL_ALERTS_FAIL';
export const FETCH_PUBLISHED_ALERTS = 'FETCH_PUBLISHED_ALERTS';
export const FETCH_PUBLISHED_ALERTS_SUCCESS = 'FETCH_PUBLISHED_ALERTS_SUCCESS';
export const FETCH_PUBLISHED_ALERTS_FAIL = 'FETCH_PUBLISHED_ALERTS_FAIL';
export const FETCH_ALERT = 'FETCH_ALERT';
export const FETCH_ALERT_SUCCESS = 'FETCH_ALERT_SUCCESS';
export const FETCH_ALERT_FAIL = 'FETCH_ALERT_FAIL';
export const CREATE_ALERT = 'CREATE_ALERT';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CREATE_ALERT_FAIL = 'CREATE_ALERT_FAIL';
export const UPDATE_ALERT = 'UPDATE_ALERT';
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';
export const UPDATE_ALERT_FAIL = 'UPDATE_ALERT_FAIL';
export const DELETE_ALERT = 'DELETE_ALERT';
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';
export const DELETE_ALERT_FAIL = 'DELETE_ALERT_FAIL';
export const CLEAR_ALERT_SUCCESS_STATES = 'CLEAR_ALERT_SUCCESS_STATES';
export const CLEAR_ALERT_ERROR_STATES = 'CLEAR_ALERT_ERROR_STATES';

export const FETCH_ALL_ANNOUNCEMENTS = 'FETCH_ALL_ANNOUNCEMENTS';
export const FETCH_ALL_ANNOUNCEMENTS_SUCCESS = 'FETCH_ALL_ANNOUNCEMENTS_SUCCESS';
export const FETCH_ALL_ANNOUNCEMENTS_FAIL = 'FETCH_ALL_ANNOUNCEMENTS_FAIL';
export const FETCH_PUBLISHED_ANNOUNCEMENTS = 'FETCH_PUBLISHED_ANNOUNCEMENTS';
export const FETCH_PUBLISHED_ANNOUNCEMENTS_SUCCESS = 'FETCH_PUBLISHED_ANNOUNCEMENTS_SUCCESS';
export const FETCH_PUBLISHED_ANNOUNCEMENTS_FAIL = 'FETCH_PUBLISHED_ANNOUNCEMENTS_FAIL';
export const FETCH_ANNOUNCEMENT = 'FETCH_ANNOUNCEMENT';
export const FETCH_ANNOUNCEMENT_SUCCESS = 'FETCH_ANNOUNCEMENT_SUCCESS';
export const FETCH_ANNOUNCEMENT_FAIL = 'FETCH_ANNOUNCEMENT_FAIL';
export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS';
export const CREATE_ANNOUNCEMENT_FAIL = 'CREATE_ANNOUNCEMENT_FAIL';
export const UPDATE_ANNOUNCEMENT = 'UPDATE_ANNOUNCEMENT';
export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_FAIL = 'UPDATE_ANNOUNCEMENT_FAIL';
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT';
export const DELETE_ANNOUNCEMENT_SUCCESS = 'DELETE_ANNOUNCEMENT_SUCCESS';
export const DELETE_ANNOUNCEMENT_FAIL = 'DELETE_ANNOUNCEMENT_FAIL';
export const CLEAR_ANNOUNCEMENT_SUCCESS_STATES = 'CLEAR_ANNOUNCEMENT_SUCCESS_STATES';
export const CLEAR_ANNOUNCEMENT_ERROR_STATES = 'CLEAR_ANNOUNCEMENT_ERROR_STATES';

export const FETCH_ALL_NEWS_ARTICLES = 'FETCH_ALL_NEWS_ARTICLES';
export const FETCH_ALL_NEWS_ARTICLES_SUCCESS = 'FETCH_ALL_NEWS_ARTICLES_SUCCESS';
export const FETCH_ALL_NEWS_ARTICLES_FAIL = 'FETCH_ALL_NEWS_ARTICLES_FAIL';
export const FETCH_PUBLISHED_NEWS_ARTICLES = 'FETCH_PUBLISHED_NEWS_ARTICLES';
export const FETCH_PUBLISHED_NEWS_ARTICLES_SUCCESS = 'FETCH_PUBLISHED_NEWS_ARTICLES_SUCCESS';
export const FETCH_PUBLISHED_NEWS_ARTICLES_FAIL = 'FETCH_PUBLISHED_NEWS_ARTICLES_FAIL';
export const FETCH_PUBLISHED_HIGHLIGHTS = 'FETCH_PUBLISHED_HIGHLIGHTS';
export const FETCH_PUBLISHED_HIGHLIGHTS_SUCCESS = 'FETCH_PUBLISHED_HIGHLIGHTS_SUCCESS';
export const FETCH_PUBLISHED_HIGHLIGHTS_FAIL = 'FETCH_PUBLISHED_HIGHLIGHTS_FAIL';
export const FETCH_NEWS_ARTICLE = 'FETCH_NEWS_ARTICLE';
export const FETCH_NEWS_ARTICLE_SUCCESS = 'FETCH_NEWS_ARTICLE_SUCCESS';
export const FETCH_NEWS_ARTICLE_FAIL = 'FETCH_NEWS_ARTICLE_FAIL';
export const CREATE_NEWS_ARTICLE = 'CREATE_NEWS_ARTICLE';
export const CREATE_NEWS_ARTICLE_SUCCESS = 'CREATE_NEWS_ARTICLE_SUCCESS';
export const CREATE_NEWS_ARTICLE_FAIL = 'CREATE_NEWS_ARTICLE_FAIL';
export const UPDATE_NEWS_ARTICLE = 'UPDATE_NEWS_ARTICLE';
export const UPDATE_NEWS_ARTICLE_SUCCESS = 'UPDATE_NEWS_ARTICLE_SUCCESS';
export const UPDATE_NEWS_ARTICLE_FAIL = 'UPDATE_NEWS_ARTICLE_FAIL';
export const DELETE_NEWS_ARTICLE = 'DELETE_NEWS_ARTICLE';
export const DELETE_NEWS_ARTICLE_SUCCESS = 'DELETE_NEWS_ARTICLE_SUCCESS';
export const DELETE_NEWS_ARTICLE_FAIL = 'DELETE_NEWS_ARTICLE_FAIL';
export const CLEAR_NEWS_ARTICLE_SUCCESS_STATES = 'CLEAR_NEWS_ARTICLE_SUCCESS_STATES';
export const CLEAR_NEWS_ARTICLE_ERROR_STATES = 'CLEAR_NEWS_ARTICLE_ERROR_STATES';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAIL = 'UPLOAD_IMAGE_FAIL';
export const DELETE_IMAGES = 'DELETE_IMAGES';
export const DELETE_IMAGES_SUCCESS = 'DELETE_IMAGES_SUCCESS';
export const DELETE_IMAGES_FAIL = 'DELETE_IMAGES_FAIL';
export const CLEAR_UPLOAD_IMAGE_SUCCESS_STATES = 'CLEAR_UPLOAD_IMAGE_SUCCESS_STATES';
export const CLEAR_UPLOAD_IMAGE_ERROR_STATES = 'CLEAR_UPLOAD_IMAGE_ERROR_STATES';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export const CLEAR_PROJECT_SUCCESS_STATES = 'CLEAR_PROJECT_SUCCESS_STATES';
export const CLEAR_PROJECT_ERROR_STATES = 'CLEAR_PROJECT_ERROR_STATES';
export const FETCH_WEBSITE_BRANDING = 'FETCH_WEBSITE_BRANDING';
export const FETCH_WEBSITE_BRANDING_SUCCESS = 'FETCH_WEBSITE_BRANDING_SUCCESS';
export const FETCH_WEBSITE_BRANDING_FAIL = 'FETCH_WEBSITE_BRANDING_FAIL';
export const CREATE_WEBSITE_BRANDING = 'CREATE_WEBSITE_BRANDING';
export const CREATE_WEBSITE_BRANDING_SUCCESS = 'CREATE_WEBSITE_BRANDING_SUCCESS';
export const CREATE_WEBSITE_BRANDING_FAIL = 'CREATE_WEBSITE_BRANDING_FAIL';
export const UPDATE_WEBSITE_BRANDING_SUCCESS = 'UPDATE_WEBSITE_BRANDING_SUCCESS';
export const UPDATE_WEBSITE_BRANDING = 'UPDATE_WEBSITE_BRANDING';
export const UPDATE_WEBSITE_BRANDING_FAIL = 'UPDATE_WEBSITE_BRANDING_FAIL';
export const DELETE_WEBSITE_BRANDING = 'DELETE_WEBSITE_BRANDING';
export const DELETE_WEBSITE_BRANDING_SUCCESS = 'DELETE_WEBSITE_BRANDING_SUCCESS';
export const DELETE_WEBSITE_BRANDING_FAIL = 'DELETE_WEBSITE_BRANDING_FAIL';
export const CLEAR_WEBSITE_BRANDING_SUCCESS_STATES = 'CLEAR_WEBSITE_BRANDING_SUCCESS_STATES';
export const CLEAR_WEBSITE_BRANDING_ERROR_STATES = 'CLEAR_WEBSITE_BRANDING_ERROR_STATES';