// ADMIN REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    userProfiles: null,
    fetchAllProfilesError: null,
    fetchAllProfilesSuccess: null,
    loading: false
};

const fetchAllProfiles = (state, action) => {
    return updateObject(state, {
        fetchAllProfilesError: null,
        fetchAllProfilesSuccess: null,
        loading: true
    });
};

const fetchAllProfilesSuccess = (state, action) => {
    return updateObject(state, {
        userProfiles: action.userProfiles,
        fetchAllProfilesError: null,
        fetchAllProfilesSuccess: true,
        loading: false
    });
};

const fetchAllProfilesFail = (state, action) => {
    return updateObject(state, {
        fetchAllProfilesError: action.error,
        fetchAllProfilesSuccess: false,
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ALL_PROFILES:
            return fetchAllProfiles(state, action);
        case actionTypes.FETCH_ALL_PROFILES_SUCCESS:
            return fetchAllProfilesSuccess(state, action);
        case actionTypes.FETCH_ALL_PROFILES_FAIL:
            return fetchAllProfilesFail(state, action);
        default:
            return state;
    }
};

export default reducer;
