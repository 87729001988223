import React, { useEffect } from 'react';
import { Table, Alert, Badge } from 'reactstrap';
import { connect } from 'react-redux';

import * as actions from '../../../store/actions';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Can from '../../../components/Permissions/Can/Can';
import getLeagueAndSeasonName from '../../../utilities/helpers/getLeagueAndSeasonName';

const Standings = props => {
    // Initialize Standings Config
    const standingsConfig = {
        showTeams: true,
        showWins: true,
        showLosses: true,
        showTies: true,
        showSeasonPoints: true,
        showAccumulatedGamePoints: true,
        showGamesPlayed: true,
        showUpcomingGamesScheduled: true
    };

    // Initializing Props from Component
    const { filteredSeasons, heading } = props;

    // Initializing Props from Store
    const { games, leagues, seasons, teams, onFetchGames, onFetchLeagues, onFetchSeasons, onFetchTeams } = props;

    useEffect(() => {
        onFetchGames();
        onFetchLeagues();
        onFetchSeasons();
        onFetchTeams();
    }, [onFetchGames, onFetchLeagues, onFetchSeasons, onFetchTeams]);

    // Display Errors
    let alert = null;

    if (props.error) {
        console.log('[Standings.js > error]', props.error.toString());
        alert = (
            <Alert color='danger'>
                <p>Sorry, something went wrong! Please try again later.</p>
                <p>{props.error.toString()}</p>
            </Alert>
        );
    }

    // Display List of Seasons as Cards
    let seasonCards = null;
    let listOfSeasons = null;

    // Determine if filteredSeasons were passed as a prop
    if (filteredSeasons) {
        listOfSeasons = filteredSeasons;
    } else {
        listOfSeasons = seasons;
    }

    if (props.loading) {
        seasonCards = <Spinner />;
    }

    if (games && leagues && seasons && teams) {
        seasonCards = (
            <Can
                roles={props.profile.roles}
                perform={'games:read'}
                no={() => <div>Sorry, you do not have permissions to view this content. Please contact your system administrator.</div>}
                yes={() => {
                    return listOfSeasons.map(season => (
                        <div key={season.id}>
                            <div className='text-left mb-4'>
                                <h4>
                                    <Badge color='primary'>{getLeagueAndSeasonName(season.id, seasons, leagues)}</Badge>
                                </h4>
                            </div>
                            {alert}
                            <Table responsive striped bordered>
                                <thead>
                                    <tr>
                                        {standingsConfig.showTeams ? <th>Team</th> : null}
                                        {standingsConfig.showWins ? <th>Wins</th> : null}
                                        {standingsConfig.showLosses ? <th>Losses</th> : null}
                                        {standingsConfig.showTies ? <th>Ties</th> : null}
                                        {standingsConfig.showSeasonPoints ? <th>Season Points</th> : null}
                                        {standingsConfig.showAccumulatedGamePoints ? <th>Game Points</th> : null}
                                        {standingsConfig.showGamesPlayed ? <th>Games Played</th> : null}
                                        {standingsConfig.showUpcomingGamesScheduled ? <th>Upcoming Games Scheduled</th> : null}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* TO DO: Transition Logic to Backend */}
                                    {teams
                                        .filter(team => {
                                            if (team.seasons) {
                                                const seasonRef = team.seasons.filter(teamSeason => teamSeason === season.id);
                                                return seasonRef.length > 0;
                                            } else {
                                                return false;
                                            }
                                        })
                                        .map(team => {
                                            const result = games.reduce(
                                                (accumulator, game) => {
                                                    const stats = {
                                                        totalWins: 0,
                                                        totalLosses: 0,
                                                        totalTies: 0,
                                                        totalSeasonPoints: 0,
                                                        totalGamePoints: 0,
                                                        totalGamesPlayed: 0,
                                                        totalGamesScheduled: 0
                                                    };
                                                    // Check if game is in the current season
                                                    if (game.season === season.id) {
                                                        // Check if team is in the current game, calculate points, games played, games scheduled
                                                        if (game.teamHome === team.id) {
                                                            if (game.gameStatus === 'Completed') {
                                                                stats.totalGamesPlayed++;
                                                            } else {
                                                                stats.totalGamesScheduled++;
                                                            }
                                                            stats.totalGamePoints = game.teamHomeScore
                                                                ? +stats.totalGamePoints + +game.teamHomeScore
                                                                : 0;
                                                        }

                                                        if (game.teamAway === team.id) {
                                                            if (game.gameStatus === 'Completed') {
                                                                stats.totalGamesPlayed++;
                                                            } else {
                                                                stats.totalGamesScheduled++;
                                                            }
                                                            stats.totalGamePoints = game.teamAwayScore
                                                                ? +stats.totalGamePoints + +game.teamAwayScore
                                                                : 0;
                                                        }

                                                        // Check if winning team / losing team is current team
                                                        if (game.winningTeam === team.id) {
                                                            stats.totalWins++;
                                                            stats.totalSeasonPoints = stats.totalSeasonPoints + 2;
                                                        } else if (game.losingTeam === team.id) {
                                                            stats.totalLosses++;
                                                        } else if (
                                                            (game.teamHome === team.id || game.teamAway === team.id) &&
                                                            game.winningTeam === 'Tied'
                                                        ) {
                                                            stats.totalTies++;
                                                            stats.totalSeasonPoints = stats.totalSeasonPoints + 1;
                                                        }
                                                    }
                                                    return {
                                                        ...accumulator,
                                                        totalWins: accumulator.totalWins + stats.totalWins,
                                                        totalLosses: accumulator.totalLosses + stats.totalLosses,
                                                        totalTies: accumulator.totalTies + stats.totalTies,
                                                        totalSeasonPoints: accumulator.totalSeasonPoints + stats.totalSeasonPoints,
                                                        totalGamePoints: +accumulator.totalGamePoints + +stats.totalGamePoints,
                                                        totalGamesPlayed: accumulator.totalGamesPlayed + stats.totalGamesPlayed,
                                                        totalGamesScheduled: accumulator.totalGamesScheduled + stats.totalGamesScheduled
                                                    };
                                                },
                                                {
                                                    totalWins: 0,
                                                    totalLosses: 0,
                                                    totalTies: 0,
                                                    totalSeasonPoints: 0,
                                                    totalGamePoints: 0,
                                                    totalGamesPlayed: 0,
                                                    totalGamesScheduled: 0
                                                }
                                            );

                                            return (
                                                <tr key={team.id}>
                                                    {standingsConfig.showTeams ? <td>{team.name}</td> : null}
                                                    {standingsConfig.showWins ? <td>{result.totalWins} Wins</td> : null}
                                                    {standingsConfig.showLosses ? <td>{result.totalLosses} Losses</td> : null}
                                                    {standingsConfig.showTies ? <td>{result.totalTies} Ties</td> : null}
                                                    {standingsConfig.showSeasonPoints ? <td>{result.totalSeasonPoints} Points</td> : null}
                                                    {standingsConfig.showAccumulatedGamePoints ? (
                                                        <td>{result.totalGamePoints} Points</td>
                                                    ) : null}
                                                    {standingsConfig.showGamesPlayed ? (
                                                        <td>{result.totalGamesPlayed} Games Played</td>
                                                    ) : null}
                                                    {standingsConfig.showUpcomingGamesScheduled ? (
                                                        <td>{result.totalGamesScheduled} Games Scheduled</td>
                                                    ) : null}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </div>
                    ));
                }}
            />
        );
    }

    return (
        <>
            {heading ? <h1>{heading}</h1> : null}
            {seasonCards}
        </>
    );
};

const mapStateToProps = state => {
    return {
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        teams: state.teams.teams,
        games: state.games.games,
        profile: state.user.currentProfile,
        error: state.games.error,
        loading: state.games.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchGames: (sort, query) => dispatch(actions.fetchGames(sort, query)),
        onFetchLeagues: (sort, query) => dispatch(actions.fetchLeagues(sort, query)),
        onFetchSeasons: (sort, query) => dispatch(actions.fetchSeasons(sort, query)),
        onFetchTeams: sort => dispatch(actions.fetchTeams(sort)),
        onClearGameErrorStates: () => dispatch(actions.clearGameErrorStates())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Standings);
