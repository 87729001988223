import React from 'react';
 
import styles from './Logo.module.css';
import vcTorontoLogo from '../../../assets/images/vctoronto-logo.png';
 
const logo = (props) => (
   <div className={styles.Logo}>
       <img src={vcTorontoLogo} alt="VCToronto" />
   </div>
);
 
export default logo