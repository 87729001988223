const rules = {
    visitor: {
        static: [
            '/home-page:visit',
            '/about-page:visit',
            '/scores-page:visit',
            '/standings-page:visit',
            '/404-page:visit',
            '/500-page:visit',
            'games:read',
            'locations:read',
            'announcements:read',
            'news:read',
        ]
    }
}

export default rules;