import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

// Initialize with Project ID
const devProjectId = process.env.REACT_APP_DEV_NOOB_LEAGUES_PROJECT_ID;
const prodProjectId = process.env.REACT_APP_PROD_NOOB_LEAGUES_PROJECT_ID;
const projectId = process.env.NODE_ENV === 'production' ? prodProjectId : devProjectId;

export function* fetchProjectsSaga(action) {
    try {
        const projects = [];
        let query = null;
        let querySnapshot = null;

        // Determine Sort Fields
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_NAME_ASC:
                sortField = 'name';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_NAME_DESC:
                sortField = 'name';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'name';
                sortDirection = 'asc';
                break;
        }

        // Check if action contains query
        // Requires custom composite index (see error logged if the index does not show)
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'project' || action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            querySnapshot = yield firebase
                .db()
                .collection('projects')
                .where(query.field, query.operation, query.value)
                .get();
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('projects')
                .orderBy(sortField, sortDirection)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            projects.push(items);
        });
        yield put(actions.fetchProjectsSuccess(projects));
    } catch (error) {
        // console.log('[fetchProjectsSaga, error]', error);
        yield put(actions.fetchProjectsFail(error));
    }
}

export function* createProjectSaga(action) {
    try {
        const userId = action.userId;
        const projectData = {
            ...action.project,
            createdAt: firebase.timestamp,
            createdBy: userId,
            updatedAt: firebase.timestamp,
            updatedBy: userId
        };

        const docRef = yield firebase
            .db()
            .collection('projects')
            .add(projectData);

        // Create Website Branding
        const projectId = docRef.id;
        const initialWebsiteBranding = {
            name: {
                name: ''
            },
            theme: {
                navigationBackgroundColor: '',
                navigationTextPrimaryColor: '',
                navigationTextHoverColor: '',
                navigationTextActiveColor: '',
                bodyBackgroundColor: '',
                mainPanelBackgroundColor: '',
                mainPanelTextColor: '',
                sidePanelBackgroundColor: '',
                sidePanelTextColor: ''
            },
            assets: {
                mainLogoImageUrl: ''
            }
        };
        yield put(actions.createWebsiteBranding(userId, projectId, initialWebsiteBranding));

        yield put(actions.createProjectSuccess());
    } catch (error) {
        yield put(actions.createProjectFail(error));
    }
}

export function* updateProjectSaga(action) {
    try {
        const projectData = { ...action.project, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('projects')
            .doc(action.projectId)
            .update(projectData);
        yield put(actions.updateProjectSuccess());
    } catch (error) {
        yield put(actions.updateProjectFail(error));
    }
}

export function* deleteProjectSaga(action) {
    try {
        const projectData = { ...action.project };
        yield firebase
            .db()
            .collection('projects')
            .doc(projectData.id)
            .delete();
        yield put(actions.deleteProjectSuccess());
    } catch (error) {
        yield put(actions.deleteProjectFail(error));
    }
}

export function* fetchWebsiteBrandingSaga(action) {
    try {
        const currentProjectId = action.projectId ? action.projectId : projectId;
        let websiteBranding = null;
        let docRef = null;

        docRef = yield firebase
            .db()
            .collection('websiteBranding')
            .doc(currentProjectId)
            .get();
        let item = docRef.data();
            item = {
                ...item,
                id: docRef.id
            };
        websiteBranding = item;

        yield put(actions.fetchWebsiteBrandingSuccess(websiteBranding));
    } catch (error) {
        // console.log('[fetchWebsiteBrandingSaga, error]', error);
        yield put(actions.fetchWebsiteBrandingFail(error));
    }
}

export function* createWebsiteBrandingSaga(action) {
    try {
        const userId = action.userId;
        const projectId = action.projectId;
        const websiteBrandingData = {
            ...action.websiteBranding,
            createdAt: firebase.timestamp,
            createdBy: userId,
            updatedAt: firebase.timestamp,
            updatedBy: userId
        };
        yield firebase
            .db()
            .collection('websiteBranding')
            .doc(projectId)
            .set(websiteBrandingData);
        yield put(actions.createWebsiteBrandingSuccess());
    } catch (error) {
        yield put(actions.createWebsiteBrandingFail(error));
    }
}

export function* updateWebsiteBrandingSaga(action) {
    try {
        const websiteBrandingData = { ...action.websiteBranding, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('websiteBranding')
            .doc(action.websiteBrandingId)
            .update(websiteBrandingData);
        yield put(actions.updateWebsiteBrandingSuccess());
    } catch (error) {
        yield put(actions.updateWebsiteBrandingFail(error));
    }
}

export function* deleteWebsiteBrandingSaga(action) {
    try {
        const websiteBrandingData = { ...action.websiteBranding };
        yield firebase
            .db()
            .collection('websiteBranding')
            .doc(websiteBrandingData.id)
            .delete();
        yield put(actions.deleteWebsiteBrandingSuccess());
    } catch (error) {
        yield put(actions.deleteWebsiteBrandingFail(error));
    }
}
