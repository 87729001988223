import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchLocationsSaga(action) {
    try {
        const locations = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_NAME_ASC:
                sortField = 'name';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_NAME_DESC:
                sortField = 'name';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'name';
                sortDirection = 'asc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('locations')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            locations.push(items);
        });
        yield put(actions.fetchLocationsSuccess(locations));
    } catch (error) {
        yield put(actions.fetchLocationsFail());
    }
}

export function* createLocationSaga(action) {
    try {
        const userId = action.userId;
        const locationData = {
            ...action.location,
            createdAt: firebase.timestamp,
            createdBy: userId,
            updatedAt: firebase.timestamp,
            updatedBy: userId
        };
        yield firebase
            .db()
            .collection('locations')
            .add(locationData);
        yield put(actions.fetchLocations());
        yield put(actions.createLocationSuccess());
    } catch (error) {
        yield put(actions.createLocationFail(error));
    }
}

export function* updateLocationSaga(action) {
    try {
        const locationData = { ...action.location, updatedAt: firebase.timestamp, updatedBy: action.userId };
        yield firebase
            .db()
            .collection('locations')
            .doc(action.locationId)
            .update(locationData);
        yield put(actions.fetchLocations());
        yield put(actions.updateLocationSuccess());
    } catch (error) {
        yield put(actions.updateLocationFail(error));
    }
}

export function* deleteLocationSaga(action) {
    try {
        const locationData = { ...action.location };
        yield firebase
            .db()
            .collection('locations')
            .doc(locationData.id)
            .delete();
        yield put(actions.fetchLocations());
        yield put(actions.deleteLocationSuccess());
    } catch (error) {
        yield put(actions.deleteLocationFail());
    }
}
