// LOCATIONS ACTION

import * as actionTypes from './actionTypes';

export const fetchLocations = (sort) => {
    return {
        type: actionTypes.FETCH_LOCATIONS,
        sort: sort
    };
}

export const fetchLocationsSuccess = (locations) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_SUCCESS,
        locations: locations
    };
}

export const fetchLocationsFail = (error) => {
    return {
        type: actionTypes.FETCH_LOCATIONS_FAIL,
        error: error
    };
}

export const createLocation = (userId, location) => {
    return {
        type: actionTypes.CREATE_LOCATION,
        userId: userId,
        location: location
    };
}

export const createLocationSuccess = () => {
    return {
        type: actionTypes.CREATE_LOCATION_SUCCESS
    };
}

export const createLocationFail = (error) => {
    return {
        type: actionTypes.CREATE_LOCATION_FAIL,
        error: error
    };
}


export const updateLocation = (userId, location, locationId) => {
    return {
        type: actionTypes.UPDATE_LOCATION,
        userId: userId,
        location: location,
        locationId: locationId
    };
}

export const updateLocationSuccess = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_SUCCESS
    };
}

export const updateLocationFail = () => {
    return {
        type: actionTypes.UPDATE_LOCATION_FAIL
    };
}

export const deleteLocation = (userId, location) => {
    return {
        type: actionTypes.DELETE_LOCATION,
        userId: userId,
        location: location
    };
}

export const deleteLocationSuccess = () => {
    return {
        type: actionTypes.DELETE_LOCATION_SUCCESS
    };
}

export const deleteLocationFail = (error) => {
    return {
        type: actionTypes.DELETE_LOCATION_FAIL,
        error: error
    };
}

export const clearLocationSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_LOCATION_SUCCESS_STATES
    };
}

export const clearLocationErrorStates = () => {
    return {
        type: actionTypes.CLEAR_LOCATION_ERROR_STATES
    };
}