import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavLink, Dropdown, DropdownToggle } from 'reactstrap';

import styles from './Navigation.module.css';
import VcTorontoLogo from '../UI/Logo/Logo';
import MenuButton from '../UI/MenuButton/MenuButton';
import InlineNavigation from './InlineNavigation/InlineNavigation';
import getLeagueName from '../../utilities/helpers/getLeagueName';

const initialNavDropdown = [false, false, false, false];
const initialNavCollapseSubMenu = [false, false, false, false];

const Navigation = props => {
    // Initialize Seasons from Props
    const { branding, leagues, seasons } = props;

    // Initialize Dropdowns
    const [navDropdown, setNavDropdown] = useState(initialNavDropdown);
    const [navCollapseMainMenu, setNavCollapseMainMenu] = useState();
    const [navCollapseSubMenu, setNavCollapseSubMenu] = useState(initialNavCollapseSubMenu);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (branding) {
            document.documentElement.style.setProperty('--navigationBackgroundColor', branding.theme.navigationBackgroundColor);
            document.documentElement.style.setProperty('--navigationTextPrimaryColor', branding.theme.navigationTextPrimaryColor);
            document.documentElement.style.setProperty('--navigationTextHoverColor', branding.theme.navigationTextHoverColor);
            document.documentElement.style.setProperty('--navigationTextActiveColor', branding.theme.navigationTextActiveColor);
            document.documentElement.style.setProperty('--subNavigationBackgroundColor', branding.theme.subNavigationBackgroundColor);
            document.documentElement.style.setProperty('--subNavigationTextPrimaryColor', branding.theme.subNavigationTextPrimaryColor);
            document.documentElement.style.setProperty('--subNavigationTextHoverColor', branding.theme.subNavigationTextHoverColor);
            document.documentElement.style.setProperty('--subNavigationTextActiveColor', branding.theme.subNavigationTextActiveColor);
        }
    })

    // Toggles
    const toggleNavCollapse = () => {
        setNavCollapseMainMenu(!navCollapseMainMenu);
    };

    const toggleNavDropdown = index => {
        const updatedNavDropdown = [...navDropdown];
        navDropdown.forEach((dropdown, dropdownIndex) => {
            if (index || index === 0) {
                if (dropdownIndex === index) {
                    updatedNavDropdown[dropdownIndex] = !updatedNavDropdown[dropdownIndex];
                } else {
                    updatedNavDropdown[dropdownIndex] = false;
                }
            } else {
                updatedNavDropdown[dropdownIndex] = false;
            }
        });
        setNavDropdown(updatedNavDropdown);

        const updatedNavCollapseSubMenu = [...navCollapseSubMenu];
        navCollapseSubMenu.forEach((dropdown, dropdownIndex) => {
            if (index || index === 0) {
                if (dropdownIndex === index) {
                    updatedNavCollapseSubMenu[dropdownIndex] = !updatedNavCollapseSubMenu[dropdownIndex];
                } else {
                    updatedNavCollapseSubMenu[dropdownIndex] = false;
                }
            } else {
                updatedNavCollapseSubMenu[dropdownIndex] = false;
            }
        });
        setNavCollapseSubMenu(updatedNavCollapseSubMenu);
    };

    // Set Navigation Dropdown Per Season
    let mainNavItemsList = null;
    const mainNavLeaguesDropdownList = [];
    const bottomNavLeaguesItemsList = [];
    const bottomNavLeaguesItemsListMobile = [];

    if (leagues && seasons) {
        leagues
            .filter(league => league.status === 'Active')
            .map((league, index) => {
                const seasonsItemsList = [];
                const seasonsItemsListMobile = [];

                seasons
                    .filter(season => season.status === 'Active')
                    .map((season, index) => {
                        seasonsItemsList[index] = (
                            <>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/schedule'}
                                    tag={Link}
                                    className={styles.NavigationBottomLayerItem}
                                >
                                    Schedule
                                </NavLink>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/scores'}
                                    tag={Link}
                                    className={styles.NavigationBottomLayerItem}
                                >
                                    Scores
                                </NavLink>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/standings'}
                                    tag={Link}
                                    className={styles.NavigationBottomLayerItem}
                                >
                                    Standings
                                </NavLink>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/teams'}
                                    tag={Link}
                                    className={styles.NavigationBottomLayerItem}
                                >
                                    Teams
                                </NavLink>
                            </>
                        );
                        seasonsItemsListMobile[index] = (
                            <>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/schedule'}
                                    tag={Link}
                                    className={styles.NavigationInlineLayerItem}
                                    onClick={() => toggleNavCollapse()}
                                >
                                    Schedule
                                </NavLink>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/scores'}
                                    tag={Link}
                                    className={styles.NavigationInlineLayerItem}
                                    onClick={() => toggleNavCollapse()}
                                >
                                    Scores
                                </NavLink>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/standings'}
                                    tag={Link}
                                    className={styles.NavigationInlineLayerItem}
                                    onClick={() => toggleNavCollapse()}
                                >
                                    Standings
                                </NavLink>
                                <NavLink
                                    to={'/league/' + league.id + '/season/' + season.id + '/teams'}
                                    tag={Link}
                                    className={styles.NavigationInlineLayerItem}
                                    onClick={() => toggleNavCollapse()}
                                >
                                    Teams
                                </NavLink>
                            </>
                        );
                        return null;
                    });

                bottomNavLeaguesItemsList[index] = (
                    <Collapse key={index} isOpen={navCollapseSubMenu[index]} className={styles.NavigationBottomLayerCollapse}>
                        <Nav key={league.id} className={styles.NavigationBottomLayerCollapseList}>
                            <NavLink to={'/league/' + league.id + '/about'} tag={Link} className={styles.NavigationBottomLayerItem}>
                                About
                            </NavLink>
                            {seasonsItemsList[index]}
                        </Nav>
                    </Collapse>
                );

                bottomNavLeaguesItemsListMobile[index] = (
                    <div className={styles.NavigationInlineLayerCollapse}>
                        <InlineNavigation isOpen={navCollapseSubMenu[index]}>
                            <Nav key={league.id} className={styles.NavigationInlineLayerCollapseList}>
                                <NavLink
                                    to={'/league/' + league.id + '/about'}
                                    tag={Link}
                                    className={styles.NavigationInlineLayerItem}
                                    onClick={() => toggleNavCollapse()}
                                >
                                    About
                                </NavLink>
                                {seasonsItemsListMobile[index]}
                            </Nav>
                        </InlineNavigation>
                    </div>
                );

                mainNavLeaguesDropdownList[index] = (
                    <Dropdown key={league.id} isOpen={navDropdown[index]} toggle={() => {}} className={styles.NavigationMiddleLayerItem}>
                        <DropdownToggle
                            nav
                            caret
                            className={navDropdown[index] ? styles.NavigationMiddleLayerDropdownItem : styles.NavigationMiddleLayerItem}
                            onClick={() => {
                                toggleNavDropdown(index);
                            }}
                        >
                            {getLeagueName(league.id, leagues)}
                        </DropdownToggle>
                        {bottomNavLeaguesItemsListMobile[index]}
                    </Dropdown>
                );

                return null;
            });

        mainNavItemsList = (
            <Nav className={styles.NavigationMiddleLayerCollapseList}>
                <NavLink to='/' tag={Link} className={styles.NavigationMiddleLayerItem} onClick={() => toggleNavDropdown()}>
                    Home
                </NavLink>
                {mainNavLeaguesDropdownList}
                <NavLink to='/contact' tag={Link} className={styles.NavigationMiddleLayerItem} onClick={() => toggleNavDropdown()}>
                    Contact
                </NavLink>
            </Nav>
        );
    }

    return (
        <Navbar expand='lg' className={styles.Navigation}>
            <NavbarBrand to='/' tag={Link} className={styles.NavigationTopLayer}>
                <VcTorontoLogo />
            </NavbarBrand>
            <NavbarToggler onClick={toggleNavCollapse} color='dark' className={styles.MenuButton}>
                <MenuButton />
            </NavbarToggler>
            <Collapse isOpen={navCollapseMainMenu} navbar className={styles.NavigationMiddleLayerCollapse}>
                {mainNavItemsList}
            </Collapse>
            {bottomNavLeaguesItemsList.map(bottomNavLeagueItemsList => bottomNavLeagueItemsList)}
        </Navbar>
    );
};

export default Navigation;
