import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import styles from './Home.module.css';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/Spinner';
import PublishedAlertsBanner from '../Website/PublishedAlertsBanner/PublishedAlertsBanner';
import PublishedAnnouncementsCarousel from '../Website/PublishedAnnouncementsCarousel/PublishedAnnouncementsCarousel';
import PublishedHighlightsGrid from '../Website/PublishedHighlightsGrid/PublishedHighlightsGrid';
import MainPanelUpcomingGamesByLocation from '../Website/MainPanelUpcomingGamesByLocation/MainPanelUpcomingGamesByLocation';
import SidePanel from '../../components/Website/SidePanel/SidePanel';

const Home = props => {
    // Initializing Props from Store
    const {
        profile,
        websiteBranding,
        publishedAlerts,
        publishedAnnouncements,
        publishedNewsArticles,
        games,
        leagues,
        seasons,
        locations,
        teams,
        onFetchWebsiteBranding,
        onFetchPublishedAlerts,
        onFetchCurrentProfile,
        onFetchPublishedAnnouncements,
        onFetchPublishedNewsArticles,
        onFetchGames,
        onFetchLeagues,
        onFetchSeasons,
        onFetchTeams,
        onFetchLocations,
        loading
    } = props;

    // Get Profile
    useEffect(() => {
        if (!profile) {
            onFetchCurrentProfile();
        }
    }, [profile, onFetchCurrentProfile]);

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Get Published Alerts
    useEffect(() => {
        if (!publishedAlerts) {
            onFetchPublishedAlerts();
        }
    }, [publishedAlerts, onFetchPublishedAlerts]);

    // Get Published Announcements
    useEffect(() => {
        if (!publishedAnnouncements) {
            onFetchPublishedAnnouncements();
        }
    }, [publishedAnnouncements, onFetchPublishedAnnouncements]);

    // Get Published News Articles
    useEffect(() => {
        if (!publishedNewsArticles) {
            onFetchPublishedNewsArticles();
        }
    }, [publishedNewsArticles, onFetchPublishedNewsArticles]);

    // Get Games
    useEffect(() => {
        if (!games) {
            onFetchGames();
        }
    }, [games, onFetchGames]);

    // Get Leagues
    useEffect(() => {
        if (!leagues) {
            onFetchLeagues();
        }
    }, [leagues, onFetchLeagues]);

    // Get Seasons
    useEffect(() => {
        if (!seasons) {
            onFetchSeasons();
        }
    }, [seasons, onFetchSeasons]);

    // Get Teams
    useEffect(() => {
        if (!teams) {
            onFetchTeams();
        }
    }, [teams, onFetchTeams]);

    // Get Locations
    useEffect(() => {
        if (!locations) {
            onFetchLocations();
        }
    }, [locations, onFetchLocations]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--bodyBackgroundColor', branding.theme.bodyBackgroundColor);
            document.documentElement.style.setProperty('--bodyTextPrimaryColor', branding.theme.bodyTextPrimaryColor);
            document.documentElement.style.setProperty('--bodyTextSecondaryColor', branding.theme.bodyTextSecondaryColor);
            document.documentElement.style.setProperty('--bodyTextLinkColor', branding.theme.bodyTextLinkColor);
            document.documentElement.style.setProperty('--bodyPrimaryColor', branding.theme.bodyPrimaryColor);
            document.documentElement.style.setProperty('--bodyAccentColor', branding.theme.bodyAccentColor);
            document.documentElement.style.setProperty('--mainPanelBackgroundColor', branding.theme.mainPanelBackgroundColor);
            document.documentElement.style.setProperty('--mainPanelTextPrimaryColor', branding.theme.mainPanelTextPrimaryColor);
            document.documentElement.style.setProperty('--sidePanelBackgroundColor', branding.theme.sidePanelBackgroundColor);
            document.documentElement.style.setProperty('--sidePanelTextPrimaryColor', branding.theme.sidePanelTextPrimaryColor);
        }
    });

    // Display Heading
    let header = null;
    if (loading) {
        header = <Spinner />;
    }
    if (profile && publishedAlerts && publishedAnnouncements) {
        header = (
            <header>
                {publishedAlerts.length > 0 ? <PublishedAlertsBanner /> : null}
                {publishedAnnouncements.length > 0 ? <PublishedAnnouncementsCarousel /> : null}
            </header>
        );
    }

    // Display Body
    let body = null;
    let headingStyleNoAnnouncements = {
        top: '0rem',
        marginTop: '2rem',
        marginBottom: '0rem',
        color: 'black'
    };
    if (profile && leagues && seasons && publishedNewsArticles && publishedAnnouncements) {
        body = (
            <main>
                {publishedNewsArticles.length > 0 ? (
                    publishedAnnouncements.length > 0 ? (
                        <PublishedHighlightsGrid />
                    ) : (
                        <PublishedHighlightsGrid headingStyle={headingStyleNoAnnouncements} />
                    )
                ) : null}
                <Row className='mt-4 mb-4 ml-0 mr-0'>
                    <Col lg={{ size: 8, order: 2 }} style={{ padding: '0' }}>
                        <MainPanelUpcomingGamesByLocation />
                    </Col>
                    <Col lg={{ size: 4, order: 1 }} style={{ padding: '0' }}>
                        <SidePanel branding={websiteBranding} />
                    </Col>
                </Row>
            </main>
        );
    }

    // Display Footer
    let footer = null;
    if (profile && leagues && seasons) {
        footer = <footer className='text-left p-2'>TDVC v0.1.14</footer>;
    }

    return (
        <div className={styles.Home}>
            {header}
            {body}
            {footer}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.user.currentProfile,
        websiteBranding: state.websiteConfiguration.websiteBranding,
        publishedAlerts: state.alerts.publishedAlerts,
        publishedAnnouncements: state.announcements.publishedAnnouncements,
        publishedNewsArticles: state.news.publishedNewsArticles,
        games: state.games.games,
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        locations: state.locations.locations,
        loading: state.games.loading,
        error: state.games.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCurrentProfile: () => dispatch(actions.fetchCurrentProfile()),
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchPublishedAlerts: sort => dispatch(actions.fetchPublishedAlerts(sort)),
        onFetchPublishedAnnouncements: sort => dispatch(actions.fetchPublishedAnnouncements(sort)),
        onFetchPublishedNewsArticles: sort => dispatch(actions.fetchPublishedNewsArticles(sort)),
        onFetchGames: sort => dispatch(actions.fetchGames(sort)),
        onFetchLeagues: sort => dispatch(actions.fetchLeagues(sort)),
        onFetchSeasons: sort => dispatch(actions.fetchSeasons(sort)),
        onFetchTeams: sort => dispatch(actions.fetchTeams(sort)),
        onFetchLocations: sort => dispatch(actions.fetchLocations(sort))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
