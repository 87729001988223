// TEAMS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    teams: null,
    createTeamSuccess: null,
    createBulkTeamsSuccess: null,
    updateTeamSuccess: null,
    addTeamToSeasonSuccess: null,
    removeTeamFromSeasonSuccess: null,
    deleteTeamSuccess: null,
    error: null,
    loading: false
}

const fetchTeams = (state, action) => {
    return updateObject( state, {
        error: null, 
        loading: true 
    });
}

const fetchTeamsSuccess = (state, action) => {
    return updateObject( state, {
        teams: action.teams,
        error: null,
        loading: false
    });
}

const fetchTeamsFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
}

const createTeam = (state, action) => {
    return updateObject( state, {
        createTeamSuccess: null,
        error: null, 
        loading: true 
    });
}

const createTeamSuccess = (state, action) => {
    return updateObject( state, {
        createTeamSuccess: true,
        error: null,
        loading: false
    });
}

const createTeamFail = (state, action) => {
    return updateObject( state, {
        createTeamSuccess: false,
        error: action.error,
        loading: false
    });
}

const createBulkTeams = (state, action) => {
    return updateObject( state, {
        createBulkTeamsSuccess: null,
        error: null, 
        loading: true 
    });
}

const createBulkTeamsSuccess = (state, action) => {
    return updateObject( state, {
        createBulkTeamsSuccess: true,
        error: null,
        loading: false
    });
}

const createBulkTeamsFail = (state, action) => {
    return updateObject( state, {
        createBulkTeamsSuccess: false,
        error: action.error,
        loading: false
    });
}

const updateTeam = (state, action) => {
    return updateObject( state, {
        updateTeamSuccess: null,
        error: null, 
        loading: true 
    });
}

const updateTeamSuccess = (state, action) => {
    return updateObject( state, {
        updateTeamSuccess: true,
        error: null,
        loading: false
    });
}

const updateTeamFail = (state, action) => {
    return updateObject( state, {
        updateTeamSuccess: false,
        error: action.error, 
        loading: false
    });
}

const addTeamToSeason = (state, action) => {
    return updateObject( state, {
        addTeamToSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const addTeamToSeasonSuccess = (state, action) => {
    return updateObject( state, {
        addTeamToSeasonSuccess: true,
        error: null,
        loading: false
    });
}

const addTeamToSeasonFail = (state, action) => {
    return updateObject( state, {
        addTeamToSeasonSuccess: false,
        error: action.error, 
        loading: false
    });
}

const removeTeamFromSeason = (state, action) => {
    return updateObject( state, {
        removeTeamFromSeasonSuccess: null,
        error: null, 
        loading: true 
    });
}

const removeTeamFromSeasonSuccess = (state, action) => {
    return updateObject( state, {
        removeTeamFromSeasonSuccess: true,
        error: null,
        loading: false
    });
}

const removeTeamFromSeasonFail = (state, action) => {
    return updateObject( state, {
        removeTeamFromSeasonSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deleteTeam = (state, action) => {
    return updateObject( state, {
        deleteTeamSuccess: null,
        error: null, 
        loading: true 
    });
}

const deleteTeamSuccess = (state, action) => {
    return updateObject( state, {
        deleteTeamSuccess: true,
        error: null, 
        loading: false 
    });
}

const deleteTeamFail = (state, action) => {
    return updateObject( state, {
        deleteTeamSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearTeamSuccessStates = (state, action) => {
    return updateObject( state, {
        createTeamSuccess: null,
        createBulkTeamsSuccess: null,
        updateTeamSuccess: null,
        addTeamToSeasonSuccess: null,
        removeTeamFromSeasonSuccess: null,
        deleteTeamSuccess: null,
    });
}

const clearTeamErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_TEAMS: return fetchTeams(state, action);
        case actionTypes.FETCH_TEAMS_SUCCESS: return fetchTeamsSuccess(state, action);
        case actionTypes.FETCH_TEAMS_FAIL: return fetchTeamsFail(state, action);
        case actionTypes.CREATE_TEAM: return createTeam(state, action);
        case actionTypes.CREATE_TEAM_SUCCESS: return createTeamSuccess(state, action);
        case actionTypes.CREATE_TEAM_FAIL: return createTeamFail(state, action);
        case actionTypes.CREATE_BULK_TEAMS: return createBulkTeams(state, action);
        case actionTypes.CREATE_BULK_TEAMS_SUCCESS: return createBulkTeamsSuccess(state, action);
        case actionTypes.CREATE_BULK_TEAMS_FAIL: return createBulkTeamsFail(state, action);
        case actionTypes.UPDATE_TEAM: return updateTeam(state, action);
        case actionTypes.UPDATE_TEAM_SUCCESS: return updateTeamSuccess(state, action);
        case actionTypes.UPDATE_TEAM_FAIL: return updateTeamFail(state, action);
        case actionTypes.ADD_TEAM_TO_SEASON: return addTeamToSeason(state, action);
        case actionTypes.ADD_TEAM_TO_SEASON_SUCCESS: return addTeamToSeasonSuccess(state, action);
        case actionTypes.ADD_TEAM_TO_SEASON_FAIL: return addTeamToSeasonFail(state, action);
        case actionTypes.REMOVE_TEAM_FROM_SEASON: return removeTeamFromSeason(state, action);
        case actionTypes.REMOVE_TEAM_FROM_SEASON_SUCCESS: return removeTeamFromSeasonSuccess(state, action);
        case actionTypes.REMOVE_TEAM_FROM_SEASON_FAIL: return removeTeamFromSeasonFail(state, action);
        case actionTypes.DELETE_TEAM: return deleteTeam(state, action);
        case actionTypes.DELETE_TEAM_SUCCESS: return deleteTeamSuccess(state, action);
        case actionTypes.DELETE_TEAM_FAIL: return deleteTeamFail(state, action);
        case actionTypes.CLEAR_TEAM_SUCCESS_STATES: return clearTeamSuccessStates(state, action);
        case actionTypes.CLEAR_TEAM_ERROR_STATES: return clearTeamErrorStates(state, action);
        default: return state;
    }
}

export default reducer;