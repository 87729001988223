// ANNOUNCEMENTS ACTION

import * as actionTypes from './actionTypes';

export const fetchAllAnnouncements = (sort) => {
    return {
        type: actionTypes.FETCH_ALL_ANNOUNCEMENTS,
        sort: sort
    };
}

export const fetchAllAnnouncementsSuccess = (announcements) => {
    return {
        type: actionTypes.FETCH_ALL_ANNOUNCEMENTS_SUCCESS,
        announcements: announcements
    };
}

export const fetchAllAnnouncementsFail = (error) => {
    return {
        type: actionTypes.FETCH_ALL_ANNOUNCEMENTS_FAIL,
        error: error
    };
}

export const fetchPublishedAnnouncements = (sort) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS,
        sort: sort
    };
}

export const fetchPublishedAnnouncementsSuccess = (publishedAnnouncements) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS_SUCCESS,
        publishedAnnouncements: publishedAnnouncements
    };
}

export const fetchPublishedAnnouncementsFail = (error) => {
    return {
        type: actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS_FAIL,
        error: error
    };
}

export const fetchAnnouncement = (announcementId) => {
    return {
        type: actionTypes.FETCH_ANNOUNCEMENT,
        announcementId: announcementId
    };
}

export const fetchAnnouncementSuccess = (announcement) => {
    return {
        type: actionTypes.FETCH_ANNOUNCEMENT_SUCCESS,
        announcement: announcement
    };
}

export const fetchAnnouncementFail = (error) => {
    return {
        type: actionTypes.FETCH_ANNOUNCEMENT_FAIL,
        error: error
    };
}

export const createAnnouncement = (userId, announcement) => {
    return {
        type: actionTypes.CREATE_ANNOUNCEMENT,
        userId: userId,
        announcement: announcement
    };
}

export const createAnnouncementSuccess = () => {
    return {
        type: actionTypes.CREATE_ANNOUNCEMENT_SUCCESS
    };
}

export const createAnnouncementFail = (error) => {
    return {
        type: actionTypes.CREATE_ANNOUNCEMENT_FAIL,
        error: error
    };
}

export const updateAnnouncement = (userId, announcement, announcementId) => {
    return {
        type: actionTypes.UPDATE_ANNOUNCEMENT,
        userId: userId,
        announcement: announcement,
        announcementId: announcementId
    };
}

export const updateAnnouncementSuccess = () => {
    return {
        type: actionTypes.UPDATE_ANNOUNCEMENT_SUCCESS
    };
}

export const updateAnnouncementFail = (error) => {
    return {
        type: actionTypes.UPDATE_ANNOUNCEMENT_FAIL,
        error: error
    };
}

export const deleteAnnouncement = (userId, announcement) => {
    return {
        type: actionTypes.DELETE_ANNOUNCEMENT,
        userId: userId,
        announcement: announcement
    };
}

export const deleteAnnouncementSuccess = () => {
    return {
        type: actionTypes.DELETE_ANNOUNCEMENT_SUCCESS
    };
}

export const deleteAnnouncementFail = (error) => {
    return {
        type: actionTypes.DELETE_ANNOUNCEMENT_FAIL,
        error: error
    };
}

export const clearAnnouncementSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_ANNOUNCEMENT_SUCCESS_STATES
    };
}

export const clearAnnouncementErrorStates = () => {
    return {
        type: actionTypes.CLEAR_ANNOUNCEMENT_ERROR_STATES
    };
}