import { takeEvery } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

import { fetchAllProfilesSaga } from './admin';
import {
    loginSaga,
    loginAnonymouslySaga,
    logoutSaga,
    reauthenticateSaga,
    registerSaga,
    sendEmailVerificationSaga,
    forgotPasswordSaga,
    deleteAccountSaga,
    checkAutoLoginSaga
} from './auth';
import {
    fetchCurrentProfileSaga,
    fetchProfileByIdSaga,
    fetchProfileByEmailSaga,
    createProfileSaga,
    createAnonymousProfileSaga,
    updateProfileSaga,
    deleteProfileSaga,
    updateEmailSaga,
    updatePasswordSaga
} from './user';
import { fetchLeaguesSaga, createLeagueSaga, updateLeagueSaga, deleteLeagueSaga } from './leagues';
import { fetchSeasonsSaga, createSeasonSaga, updateSeasonSaga, pinSeasonSaga, removePinFromSeasonSaga, deleteSeasonSaga } from './seasons';
import {
    fetchTeamsSaga,
    createTeamSaga,
    createBulkTeamsSaga,
    updateTeamSaga,
    deleteTeamSaga,
    addTeamToSeasonSaga,
    removeTeamFromSeasonSaga
} from './teams';
import {
    fetchPlayersSaga,
    createPlayerSaga,
    updatePlayerSaga,
    deletePlayerSaga,
    addPlayerToTeamSaga,
    removePlayerFromTeamSaga
} from './players';
import {
    fetchGamesSaga,
    fetchGamesByDateSaga,
    fetchGamesByQuerySaga,
    fetchUpcomingGamesSaga,
    fetchCompletedGamesSaga,
    fetchPreviousGamesSaga,
    fetchNextGameDateSaga,
    createGameSaga,
    createBulkGamesSaga,
    updateGameSaga,
    updateBulkGamesSaga,
    deleteGameSaga
} from './games';
import { fetchLocationsSaga, createLocationSaga, updateLocationSaga, deleteLocationSaga } from './locations';
import { fetchAllAlertsSaga, fetchPublishedAlertsSaga, fetchAlertSaga, createAlertSaga, updateAlertSaga, deleteAlertSaga } from './alerts';
import {
    fetchAllAnnouncementsSaga,
    fetchPublishedAnnouncementsSaga,
    fetchAnnouncementSaga,
    createAnnouncementSaga,
    updateAnnouncementSaga,
    deleteAnnouncementSaga
} from './announcements';
import {
    fetchAllNewsArticlesSaga,
    fetchPublishedNewsArticlesSaga,
    fetchPublishedHighlightsSaga,
    fetchNewsArticleSaga,
    createNewsArticleSaga,
    updateNewsArticleSaga,
    deleteNewsArticleSaga
} from './news';
import { uploadImageSaga, deleteImagesSaga } from './media';
import {
    fetchProjectsSaga,
    createProjectSaga,
    updateProjectSaga,
    deleteProjectSaga,
    fetchWebsiteBrandingSaga,
    createWebsiteBrandingSaga,
    updateWebsiteBrandingSaga,
    deleteWebsiteBrandingSaga
} from './websiteConfiguration';

export function* watchAdmin() {
    yield takeEvery(actionTypes.FETCH_ALL_PROFILES, fetchAllProfilesSaga);
}

export function* watchAuth() {
    yield takeEvery(actionTypes.LOGIN, loginSaga);
    yield takeEvery(actionTypes.LOGIN_ANONYMOUSLY, loginAnonymouslySaga);
    yield takeEvery(actionTypes.LOGOUT, logoutSaga);
    yield takeEvery(actionTypes.REAUTHENTICATE, reauthenticateSaga);
    yield takeEvery(actionTypes.REGISTER, registerSaga);
    yield takeEvery(actionTypes.SEND_EMAIL_VERIFICATION, sendEmailVerificationSaga);
    yield takeEvery(actionTypes.FORGOT_PASSWORD, forgotPasswordSaga);
    yield takeEvery(actionTypes.DELETE_ACCOUNT, deleteAccountSaga);
    yield takeEvery(actionTypes.CHECK_AUTO_LOGIN, checkAutoLoginSaga);
}

export function* watchUser() {
    yield takeEvery(actionTypes.FETCH_CURRENT_PROFILE, fetchCurrentProfileSaga);
    yield takeEvery(actionTypes.FETCH_PROFILE_BY_ID, fetchProfileByIdSaga);
    yield takeEvery(actionTypes.FETCH_PROFILE_BY_EMAIL, fetchProfileByEmailSaga);
    yield takeEvery(actionTypes.CREATE_PROFILE, createProfileSaga);
    yield takeEvery(actionTypes.CREATE_ANONYMOUS_PROFILE, createAnonymousProfileSaga);
    yield takeEvery(actionTypes.UPDATE_PROFILE, updateProfileSaga);
    yield takeEvery(actionTypes.DELETE_PROFILE, deleteProfileSaga);
    yield takeEvery(actionTypes.UPDATE_EMAIL, updateEmailSaga);
    yield takeEvery(actionTypes.UPDATE_PASSWORD, updatePasswordSaga);
}

export function* watchLeagues() {
    yield takeEvery(actionTypes.FETCH_LEAGUES, fetchLeaguesSaga);
    yield takeEvery(actionTypes.CREATE_LEAGUE, createLeagueSaga);
    yield takeEvery(actionTypes.UPDATE_LEAGUE, updateLeagueSaga);
    yield takeEvery(actionTypes.DELETE_LEAGUE, deleteLeagueSaga);
}

export function* watchSeasons() {
    yield takeEvery(actionTypes.FETCH_SEASONS, fetchSeasonsSaga);
    yield takeEvery(actionTypes.CREATE_SEASON, createSeasonSaga);
    yield takeEvery(actionTypes.UPDATE_SEASON, updateSeasonSaga);
    yield takeEvery(actionTypes.PIN_SEASON, pinSeasonSaga);
    yield takeEvery(actionTypes.REMOVE_PIN_FROM_SEASON, removePinFromSeasonSaga);
    yield takeEvery(actionTypes.DELETE_SEASON, deleteSeasonSaga);
}

export function* watchTeams() {
    yield takeEvery(actionTypes.FETCH_TEAMS, fetchTeamsSaga);
    yield takeEvery(actionTypes.CREATE_TEAM, createTeamSaga);
    yield takeEvery(actionTypes.CREATE_BULK_TEAMS, createBulkTeamsSaga);
    yield takeEvery(actionTypes.UPDATE_TEAM, updateTeamSaga);
    yield takeEvery(actionTypes.ADD_TEAM_TO_SEASON, addTeamToSeasonSaga);
    yield takeEvery(actionTypes.REMOVE_TEAM_FROM_SEASON, removeTeamFromSeasonSaga);
    yield takeEvery(actionTypes.DELETE_TEAM, deleteTeamSaga);
}

export function* watchPlayers() {
    yield takeEvery(actionTypes.FETCH_PLAYERS, fetchPlayersSaga);
    yield takeEvery(actionTypes.CREATE_PLAYER, createPlayerSaga);
    yield takeEvery(actionTypes.UPDATE_PLAYER, updatePlayerSaga);
    yield takeEvery(actionTypes.ADD_PLAYER_TO_TEAM, addPlayerToTeamSaga);
    yield takeEvery(actionTypes.REMOVE_PLAYER_FROM_TEAM, removePlayerFromTeamSaga);
    yield takeEvery(actionTypes.DELETE_PLAYER, deletePlayerSaga);
}

export function* watchGames() {
    yield takeEvery(actionTypes.FETCH_GAMES, fetchGamesSaga);
    yield takeEvery(actionTypes.FETCH_GAMES_BY_DATE, fetchGamesByDateSaga);
    yield takeEvery(actionTypes.FETCH_GAMES_BY_QUERY, fetchGamesByQuerySaga);
    yield takeEvery(actionTypes.FETCH_UPCOMING_GAMES, fetchUpcomingGamesSaga);
    yield takeEvery(actionTypes.FETCH_COMPLETED_GAMES, fetchCompletedGamesSaga);
    yield takeEvery(actionTypes.FETCH_PREVIOUS_GAMES, fetchPreviousGamesSaga);
    yield takeEvery(actionTypes.FETCH_NEXT_GAME_DATE, fetchNextGameDateSaga);
    yield takeEvery(actionTypes.CREATE_GAME, createGameSaga);
    yield takeEvery(actionTypes.CREATE_BULK_GAMES, createBulkGamesSaga);
    yield takeEvery(actionTypes.UPDATE_GAME, updateGameSaga);
    yield takeEvery(actionTypes.UPDATE_BULK_GAMES, updateBulkGamesSaga);
    yield takeEvery(actionTypes.DELETE_GAME, deleteGameSaga);
}

export function* watchLocations() {
    yield takeEvery(actionTypes.FETCH_LOCATIONS, fetchLocationsSaga);
    yield takeEvery(actionTypes.CREATE_LOCATION, createLocationSaga);
    yield takeEvery(actionTypes.UPDATE_LOCATION, updateLocationSaga);
    yield takeEvery(actionTypes.DELETE_LOCATION, deleteLocationSaga);
}

export function* watchAlerts() {
    yield takeEvery(actionTypes.FETCH_ALL_ALERTS, fetchAllAlertsSaga);
    yield takeEvery(actionTypes.FETCH_PUBLISHED_ALERTS, fetchPublishedAlertsSaga);
    yield takeEvery(actionTypes.FETCH_ALERT, fetchAlertSaga);
    yield takeEvery(actionTypes.CREATE_ALERT, createAlertSaga);
    yield takeEvery(actionTypes.UPDATE_ALERT, updateAlertSaga);
    yield takeEvery(actionTypes.DELETE_ALERT, deleteAlertSaga);
}

export function* watchAnnouncements() {
    yield takeEvery(actionTypes.FETCH_ALL_ANNOUNCEMENTS, fetchAllAnnouncementsSaga);
    yield takeEvery(actionTypes.FETCH_PUBLISHED_ANNOUNCEMENTS, fetchPublishedAnnouncementsSaga);
    yield takeEvery(actionTypes.FETCH_ANNOUNCEMENT, fetchAnnouncementSaga);
    yield takeEvery(actionTypes.CREATE_ANNOUNCEMENT, createAnnouncementSaga);
    yield takeEvery(actionTypes.UPDATE_ANNOUNCEMENT, updateAnnouncementSaga);
    yield takeEvery(actionTypes.DELETE_ANNOUNCEMENT, deleteAnnouncementSaga);
}

export function* watchNews() {
    yield takeEvery(actionTypes.FETCH_ALL_NEWS_ARTICLES, fetchAllNewsArticlesSaga);
    yield takeEvery(actionTypes.FETCH_PUBLISHED_NEWS_ARTICLES, fetchPublishedNewsArticlesSaga);
    yield takeEvery(actionTypes.FETCH_PUBLISHED_HIGHLIGHTS, fetchPublishedHighlightsSaga);
    yield takeEvery(actionTypes.FETCH_NEWS_ARTICLE, fetchNewsArticleSaga);
    yield takeEvery(actionTypes.CREATE_NEWS_ARTICLE, createNewsArticleSaga);
    yield takeEvery(actionTypes.UPDATE_NEWS_ARTICLE, updateNewsArticleSaga);
    yield takeEvery(actionTypes.DELETE_NEWS_ARTICLE, deleteNewsArticleSaga);
}

export function* watchMedia() {
    yield takeEvery(actionTypes.UPLOAD_IMAGE, uploadImageSaga);
    yield takeEvery(actionTypes.DELETE_IMAGES, deleteImagesSaga);
}

export function* watchWebsiteConfiguration() {
    yield takeEvery(actionTypes.FETCH_PROJECTS, fetchProjectsSaga);
    yield takeEvery(actionTypes.CREATE_PROJECT, createProjectSaga);
    yield takeEvery(actionTypes.UPDATE_PROJECT, updateProjectSaga);
    yield takeEvery(actionTypes.DELETE_PROJECT, deleteProjectSaga);
    yield takeEvery(actionTypes.FETCH_WEBSITE_BRANDING, fetchWebsiteBrandingSaga);
    yield takeEvery(actionTypes.CREATE_WEBSITE_BRANDING, createWebsiteBrandingSaga);
    yield takeEvery(actionTypes.UPDATE_WEBSITE_BRANDING, updateWebsiteBrandingSaga);
    yield takeEvery(actionTypes.DELETE_WEBSITE_BRANDING, deleteWebsiteBrandingSaga);
}
