import React from 'react';

import styles from './MenuButton.module.css';

const MenuButton = () => (
    <div className={styles.container}>
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
    </div>
);

export default MenuButton;
