import React from 'react';

import styles from './InlineNavigation.module.css';

const InlineNavigation = props => {
    // Initialize Props from Component
    const { isOpen } = props;

    const inlineNavigation = isOpen ? styles.InlineNavigationIsOpen : styles.InlineNavigationIsClosed;
    
    return <div className={inlineNavigation}>
        {props.children}
    </div>;
};

export default InlineNavigation;
