// USER REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    currentProfile: null,
    queryProfile: null,
    fetchCurrentProfileError: null,
    fetchCurrentProfileSuccess: null,
    fetchProfileByIdError: null,
    fetchProfileByIdSuccess: null,
    fetchProfileByEmailError: null,
    fetchProfileByEmailSuccess: null,
    createProfileError: null,
    createProfileSuccess: null,
    updateProfileError: null,
    updateProfileSuccess: null,
    deleteProfileError: null,
    deleteProfileSuccess: null,
    updateEmailError: null,
    updateEmailSuccess: null,
    updatePasswordError: null,
    updatePasswordSuccess: null,
    loading: false
};

const fetchCurrentProfile = (state, action) => {
    return updateObject(state, {
        fetchCurrentProfileError: null,
        fetchCurrentProfileSuccess: null,
        loading: true
    });
};

const fetchCurrentProfileSuccess = (state, action) => {
    return updateObject(state, {
        currentProfile: action.profile,
        fetchCurrentProfileError: null,
        fetchCurrentProfileSuccess: true,
        loading: false
    });
};

const fetchCurrentProfileFail = (state, action) => {
    return updateObject(state, {
        fetchCurrentProfileError: action.error,
        fetchCurrentProfileSuccess: false,
        loading: false
    });
};

const fetchProfileById = (state, action) => {
    return updateObject(state, {
        fetchProfileByIdError: null,
        fetchProfileByIdSuccess: null,
        loading: true
    });
};

const fetchProfileByIdSuccess = (state, action) => {
    return updateObject(state, {
        queryProfile: action.profile,
        fetchProfileByIdError: null,
        fetchProfileByIdSuccess: true,
        loading: false
    });
};

const fetchProfileByIdFail = (state, action) => {
    return updateObject(state, {
        fetchProfileByIdError: action.error,
        fetchProfileByIdSuccess: false,
        loading: false
    });
};

const fetchProfileByEmail = (state, action) => {
    return updateObject(state, {
        fetchProfileByEmailError: null,
        fetchProfileByEmailSuccess: null,
        loading: true
    });
};

const fetchProfileByEmailSuccess = (state, action) => {
    return updateObject(state, {
        queryProfile: action.profile,
        fetchProfileByEmailError: null,
        fetchProfileByEmailSuccess: true,
        loading: false
    });
};

const fetchProfileByEmailFail = (state, action) => {
    return updateObject(state, {
        fetchProfileByEmailError: action.error,
        fetchProfileByEmailSuccess: false,
        loading: false
    });
};

const createProfile = (state, action) => {
    return updateObject(state, {
        createProfileError: null,
        createProfileSuccess: null,
        loading: true
    });
};

const createProfileSuccess = (state, action) => {
    return updateObject(state, {
        createProfileError: null,
        createProfileSuccess: true,
        loading: false
    });
};

const createProfileFail = (state, action) => {
    return updateObject(state, {
        createProfileError: action.error,
        createProfileSuccess: false,
        loading: false
    });
};

const createAnonymousProfile = (state, action) => {
    return updateObject(state, {
        createAnonymousProfileError: null,
        createAnonymousProfileSuccess: null,
        loading: true
    });
};

const createAnonymousProfileSuccess = (state, action) => {
    return updateObject(state, {
        currentProfile: action.profile,
        createAnonymousProfileError: null,
        createAnonymousProfileSuccess: true,
        loading: false
    });
};

const createAnonymousProfileFail = (state, action) => {
    return updateObject(state, {
        createAnonymousProfileError: action.error,
        createAnonymousProfileSuccess: false,
        loading: false
    });
};

const updateProfile = (state, action) => {
    return updateObject(state, {
        updateProfileError: null,
        updateProfileSuccess: null,
        loading: true
    });
};

const updateProfileSuccess = (state, action) => {
    return updateObject(state, {
        updateProfileError: null,
        updateProfileSuccess: true,
        loading: false
    });
};

const updateProfileFail = (state, action) => {
    return updateObject(state, {
        updateProfileError: action.error,
        updateProfileSuccess: false,
        loading: false
    });
};

const deleteProfile = (state, action) => {
    return updateObject(state, {
        deleteProfileError: null,
        deleteProfileSuccess: null,
        loading: true
    });
};

const deleteProfileSuccess = (state, action) => {
    return updateObject(state, {
        deleteProfileError: null,
        deleteProfileSuccess: true,
        loading: false
    });
};

const deleteProfileFail = (state, action) => {
    return updateObject(state, {
        deleteProfileError: action.error,
        deleteProfileSuccess: false,
        loading: false
    });
};

const updateEmail = (state, action) => {
    return updateObject(state, {
        updateEmailError: null,
        updateEmailSuccess: null,
        loading: true
    });
};

const updateEmailSuccess = (state, action) => {
    return updateObject(state, {
        updateEmailError: null,
        updateEmailSuccess: true,
        loading: false
    });
};

const updateEmailFail = (state, action) => {
    return updateObject(state, {
        updateEmailError: action.error,
        updateEmailSuccess: false,
        loading: false
    });
};

const updatePassword = (state, action) => {
    return updateObject(state, {
        updatePasswordError: null,
        updatePasswordSuccess: null,
        loading: true
    });
};

const updatePasswordSuccess = (state, action) => {
    return updateObject(state, {
        updatePasswordError: null,
        updatePasswordSuccess: true,
        loading: false
    });
};

const updatePasswordFail = (state, action) => {
    return updateObject(state, {
        updatePasswordError: action.error,
        updatePasswordSuccess: false,
        loading: false
    });
};

const clearUserSuccessStates = (state, action) => {
    return updateObject(state, {
        fetchCurrentProfileSuccess: null,
        fetchProfileByIdSuccess: null,
        fetchProfileByEmailSuccess: null,
        createProfileSuccess: null,
        updateProfileSuccess: null,
        deleteProfileSuccess: null,
        updateEmailSuccess: null,
        updatePasswordSuccess: null
    });
};

const clearUserErrorStates = (state, action) => {
    return updateObject(state, {
        fetchCurrentProfileError: null,
        fetchProfileByIdError: null,
        fetchProfileByEmailError: null,
        createProfileError: null,
        updateProfileError: null,
        deleteProfileError: null,
        updateEmailError: null,
        updatePasswordError: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CURRENT_PROFILE:
            return fetchCurrentProfile(state, action);
        case actionTypes.FETCH_CURRENT_PROFILE_SUCCESS:
            return fetchCurrentProfileSuccess(state, action);
        case actionTypes.FETCH_CURRENT_PROFILE_FAIL:
            return fetchCurrentProfileFail(state, action);
        case actionTypes.FETCH_PROFILE_BY_ID:
            return fetchProfileById(state, action);
        case actionTypes.FETCH_PROFILE_BY_ID_SUCCESS:
            return fetchProfileByIdSuccess(state, action);
        case actionTypes.FETCH_PROFILE_BY_ID_FAIL:
            return fetchProfileByIdFail(state, action);
        case actionTypes.FETCH_PROFILE_BY_EMAIL:
            return fetchProfileByEmail(state, action);
        case actionTypes.FETCH_PROFILE_BY_EMAIL_SUCCESS:
            return fetchProfileByEmailSuccess(state, action);
        case actionTypes.FETCH_PROFILE_BY_EMAIL_FAIL:
            return fetchProfileByEmailFail(state, action);
        case actionTypes.CREATE_PROFILE:
            return createProfile(state, action);
        case actionTypes.CREATE_PROFILE_SUCCESS:
            return createProfileSuccess(state, action);
        case actionTypes.CREATE_PROFILE_FAIL:
            return createProfileFail(state, action);
        case actionTypes.CREATE_ANONYMOUS_PROFILE:
            return createAnonymousProfile(state, action);
        case actionTypes.CREATE_ANONYMOUS_PROFILE_SUCCESS:
            return createAnonymousProfileSuccess(state, action);
        case actionTypes.CREATE_ANONYMOUS_PROFILE_FAIL:
            return createAnonymousProfileFail(state, action);
        case actionTypes.UPDATE_PROFILE:
            return updateProfile(state, action);
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            return updateProfileSuccess(state, action);
        case actionTypes.UPDATE_PROFILE_FAIL:
            return updateProfileFail(state, action);
        case actionTypes.DELETE_PROFILE:
            return deleteProfile(state, action);
        case actionTypes.DELETE_PROFILE_SUCCESS:
            return deleteProfileSuccess(state, action);
        case actionTypes.DELETE_PROFILE_FAIL:
            return deleteProfileFail(state, action);
        case actionTypes.UPDATE_EMAIL:
            return updateEmail(state, action);
        case actionTypes.UPDATE_EMAIL_SUCCESS:
            return updateEmailSuccess(state, action);
        case actionTypes.UPDATE_EMAIL_FAIL:
            return updateEmailFail(state, action);
        case actionTypes.UPDATE_PASSWORD:
            return updatePassword(state, action);
        case actionTypes.UPDATE_PASSWORD_SUCCESS:
            return updatePasswordSuccess(state, action);
        case actionTypes.UPDATE_PASSWORD_FAIL:
            return updatePasswordFail(state, action);
        case actionTypes.CLEAR_USER_SUCCESS_STATES:
            return clearUserSuccessStates(state, action);
        case actionTypes.CLEAR_USER_ERROR_STATES:
            return clearUserErrorStates(state, action);
        default:
            return state;
    }
};

export default reducer;
