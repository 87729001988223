import { put } from 'redux-saga/effects';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchAllNewsArticlesSaga(action) {
    try {
        const newsArticles = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('news')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            newsArticles.push(items);
        });
        yield put(actions.fetchAllNewsArticlesSuccess(newsArticles));
    } catch (error) {
        // console.log('fetchAllNewsArticles error', error);
        yield put(actions.fetchAllNewsArticlesFail(error));
    }
}

export function* fetchPublishedNewsArticlesSaga(action) {
    try {
        const newsArticles = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('news')
            .where('status', '==', 'Published')
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            newsArticles.push(items);
        });
        yield put(actions.fetchPublishedNewsArticlesSuccess(newsArticles));
    } catch (error) {
        // console.log('fetchPublishedNewsArticles error', error);
        yield put(actions.fetchPublishedNewsArticlesFail(error));
    }
}

export function* fetchPublishedHighlightsSaga(action) {
    try {
        const newsArticles = [];
        let sortField = null;
        let sortDirection = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_CREATED_AT_ASC:
                sortField = 'createdAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_CREATED_AT_DESC:
                sortField = 'createdAt';
                sortDirection = 'desc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_ASC:
                sortField = 'publishedAt';
                sortDirection = 'asc';
                break;
            case sortTypes.SORT_BY_PUBLISHED_AT_DESC:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
            default:
                sortField = 'publishedAt';
                sortDirection = 'desc';
                break;
        }
        const querySnapshot = yield firebase
            .db()
            .collection('news')
            .where('status', '==', 'Published')
            .where('displayAsHighlight', '==', true)
            .orderBy(sortField, sortDirection)
            .get();
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            newsArticles.push(items);
        });
        yield put(actions.fetchPublishedHighlightsSuccess(newsArticles));
    } catch (error) {
        // console.log('fetchPublishedHighlights error', error);
        yield put(actions.fetchPublishedHighlightsFail(error));
    }
}

export function* fetchNewsArticleSaga(action) {
    try {
        let newsArticle = null;
        const newsArticleId = action.newsArticleId;
        const querySnapshot = yield firebase
            .db()
            .collection('news')
            .doc(newsArticleId)
            .get();
        if (querySnapshot.exists) {
            newsArticle = yield querySnapshot.data();
            newsArticle = { ...newsArticle, id: querySnapshot.id };
        }
        yield put(actions.fetchNewsArticleSuccess(newsArticle));
    } catch (error) {
        // console.log('fetchNewsArticle error', error);
        yield put(actions.fetchNewsArticleFail(error));
    }
}

export function* createNewsArticleSaga(action) {
    try {
        const newsArticleData = {
            ...action.newsArticle,
            createdAt: firebase.timestamp,
            updatedAt: firebase.timestamp,
            createdBy: action.userId,
            updatedBy: action.userId
        };

        if (newsArticleData.status === 'Published') {
            newsArticleData.publishedAt = firebase.timestamp;
            newsArticleData.publishedBy = action.userId;
        } else {
            newsArticleData.publishedAt = '';
            newsArticleData.publishedBy = '';
        }

        yield firebase
            .db()
            .collection('news')
            .add(newsArticleData);
        yield put(actions.createNewsArticleSuccess());
    } catch (error) {
        // console.log('[createNewsArticleSaga > error]', error);
        yield put(actions.createNewsArticleFail(error));
    }
}

export function* updateNewsArticleSaga(action) {
    try {
        const newsArticleData = {
            ...action.newsArticle,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        const newsArticleId = action.newsArticleId;

        if (newsArticleData.status === 'Published') {
            newsArticleData.publishedAt = firebase.timestamp;
            newsArticleData.publishedBy = action.userId;
        } else {
            newsArticleData.publishedAt = '';
            newsArticleData.publishedBy = '';
        }

        yield firebase
            .db()
            .collection('news')
            .doc(newsArticleId)
            .update(newsArticleData);
        yield put(actions.updateNewsArticleSuccess());
    } catch (error) {
        // console.log('[updateNewsArticleSaga > error]', error);
        yield put(actions.updateNewsArticleFail(error));
    }
}

export function* deleteNewsArticleSaga(action) {
    try {
        const newsArticleData = {
            ...action.newsArticle
        };
        const userId = action.userId;
        const newsArticleId = newsArticleData.id;
        const uploadedImages = [];
        if (newsArticleData.uploadedImages) {
            if (newsArticleData.uploadedImages.length > 0) {
                uploadedImages.push(...newsArticleData.uploadedImages);
            }
        }        

        yield firebase
            .db()
            .collection('news')
            .doc(newsArticleId)
            .delete();
        yield put(actions.deleteImages(userId, uploadedImages));
        yield put(actions.deleteNewsArticleSuccess());
    } catch (error) {
        // console.log('[deleteNewsArticleSaga > error]', error);
        yield put(actions.deleteNewsArticleFail(error));
    }
}
