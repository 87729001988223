// GAMES ACTION

import * as actionTypes from './actionTypes';

export const fetchGames = (sort) => {
    return {
        type: actionTypes.FETCH_GAMES,
        sort: sort
    };
}

export const fetchGamesSuccess = (games) => {
    return {
        type: actionTypes.FETCH_GAMES_SUCCESS,
        games: games
    };
}

export const fetchGamesFail = (error) => {
    return {
        type: actionTypes.FETCH_GAMES_FAIL,
        error: error
    };
}

export const fetchGamesByDate = (sort, filterDate) => {
    return {
        type: actionTypes.FETCH_GAMES_BY_DATE,
        sort: sort,
        filterDate: filterDate
    };
}

export const fetchGamesByDateSuccess = (games) => {
    return {
        type: actionTypes.FETCH_GAMES_BY_DATE_SUCCESS,
        games: games
    };
}

export const fetchGamesByDateFail = (error) => {
    return {
        type: actionTypes.FETCH_GAMES_BY_DATE_FAIL,
        error: error
    };
}

export const fetchGamesByQuery = (sort, query) => {
    return {
        type: actionTypes.FETCH_GAMES_BY_QUERY,
        sort: sort,
        query: query
    };
}

export const fetchGamesByQuerySuccess = (games) => {
    return {
        type: actionTypes.FETCH_GAMES_BY_QUERY_SUCCESS,
        games: games
    };
}

export const fetchGamesByQueryFail = (error) => {
    return {
        type: actionTypes.FETCH_GAMES_BY_QUERY_FAIL,
        error: error
    };
}

export const fetchUpcomingGames = (sort, query) => {
    return {
        type: actionTypes.FETCH_UPCOMING_GAMES,
        sort: sort,
        query: query
    };
}

export const fetchUpcomingGamesSuccess = (games) => {
    return {
        type: actionTypes.FETCH_UPCOMING_GAMES_SUCCESS,
        games: games
    };
}

export const fetchUpcomingGamesFail = (error) => {
    return {
        type: actionTypes.FETCH_UPCOMING_GAMES_FAIL,
        error: error
    };
}

export const fetchCompletedGames = (sort, query) => {
    return {
        type: actionTypes.FETCH_COMPLETED_GAMES,
        sort: sort,
        query: query
    };
}

export const fetchCompletedGamesSuccess = (games) => {
    return {
        type: actionTypes.FETCH_COMPLETED_GAMES_SUCCESS,
        games: games
    };
}

export const fetchCompletedGamesFail = (error) => {
    return {
        type: actionTypes.FETCH_COMPLETED_GAMES_FAIL,
        error: error
    };
}

export const fetchPreviousGames = (sort, query) => {
    return {
        type: actionTypes.FETCH_PREVIOUS_GAMES,
        sort: sort,
        query: query
    };
}

export const fetchPreviousGamesSuccess = (games) => {
    return {
        type: actionTypes.FETCH_PREVIOUS_GAMES_SUCCESS,
        games: games
    };
}

export const fetchPreviousGamesFail = (error) => {
    return {
        type: actionTypes.FETCH_PREVIOUS_GAMES_FAIL,
        error: error
    };
}

export const fetchNextGameDate = () => {
    return {
        type: actionTypes.FETCH_NEXT_GAME_DATE
    };
}

export const fetchNextGameDateSuccess = (nextGameDate) => {
    return {
        type: actionTypes.FETCH_NEXT_GAME_DATE_SUCCESS,
        nextGameDate: nextGameDate
    };
}

export const fetchNextGameDateFail = (error) => {
    return {
        type: actionTypes.FETCH_NEXT_GAME_DATE_FAIL,
        error: error
    };
}

export const createGame = (userId, game) => {
    return {
        type: actionTypes.CREATE_GAME,
        userId: userId,
        game: game
    };
}

export const createGameSuccess = () => {
    return {
        type: actionTypes.CREATE_GAME_SUCCESS
    };
}

export const createGameFail = (error) => {
    return {
        type: actionTypes.CREATE_GAME_FAIL,
        error: error
    };
}

export const createBulkGames = (userId, games) => {
    return {
        type: actionTypes.CREATE_BULK_GAMES,
        userId: userId,
        games: games
    };
}

export const createBulkGamesSuccess = () => {
    return {
        type: actionTypes.CREATE_BULK_GAMES_SUCCESS
    };
}

export const createBulkGamesFail = (error) => {
    return {
        type: actionTypes.CREATE_BULK_GAMES_FAIL,
        error: error
    };
}

export const updateGame = (userId, game, gameId) => {
    return {
        type: actionTypes.UPDATE_GAME,
        userId: userId,
        game: game,
        gameId: gameId
    };
}

export const updateGameSuccess = () => {
    return {
        type: actionTypes.UPDATE_GAME_SUCCESS
    };
}

export const updateGameFail = () => {
    return {
        type: actionTypes.UPDATE_GAME_FAIL
    };
}

export const updateBulkGames = (userId, games, gameIds) => {
    return {
        type: actionTypes.UPDATE_BULK_GAMES,
        userId: userId,
        games: games,
        gameIds: gameIds
    };
}

export const updateBulkGamesSuccess = (gameIds) => {
    return {
        type: actionTypes.UPDATE_BULK_GAMES_SUCCESS,
        gameIds: gameIds
    };
}

export const updateBulkGamesFail = (error) => {
    return {
        type: actionTypes.UPDATE_BULK_GAMES_FAIL,
        error: error
    };
}

export const deleteGame = (userId, game) => {
    return {
        type: actionTypes.DELETE_GAME,
        userId: userId,
        game: game
    };
}

export const deleteGameSuccess = () => {
    return {
        type: actionTypes.DELETE_GAME_SUCCESS
    };
}

export const deleteGameFail = (error) => {
    return {
        type: actionTypes.DELETE_GAME_FAIL,
        error: error
    };
}

export const clearGameSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_GAME_SUCCESS_STATES
    };
}

export const clearGameErrorStates = () => {
    return {
        type: actionTypes.CLEAR_GAME_ERROR_STATES
    };
}