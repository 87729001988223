import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

import styles from './About.module.css';
import Banner from '../UI/Banner/Banner';

const About = () => {
    return (
        <div className={styles.About}>
            <Banner />
            <main>
                <Card body className='text-left'>
                    <CardBody>
                        <div className='mb-4'>
                            <h1>
                                <strong>About Us</strong>
                            </h1>
                        </div>
                        <CardText>
                            First there was an idea. And from this idea research began. Hope started to form quickly. And from this hope a
                            plan was constructed.
                        </CardText>

                        <CardText>One idea, one glorious vision.</CardText>

                        <CardText>
                            Eventually, a group of friends came together and executed on this plan and formed Toronto District Volleyball
                            Club. With no previous experience in operating any formal leagues, we were still confident our collective
                            efforts would ensure a fun, competitive, well-managed league and also provide a weekly sporting event in our
                            community that allows individuals to remain active in choosing a healthy productive lifestyle. And lets be
                            honest, what sports fanatic doesn't occasionally dream about what it would be like to operate a sports league?
                        </CardText>

                        <CardText>
                            The people at Toronto District Volleyball Club is committed to developing a culture of excellence in
                            sportsmanship, competitive spirit, and to build long lasting friendships with our teammates and other members of
                            this club. We expect everyone to show a reasonable level of respect and treat each other with consideration. In
                            addition, TDVC will always strive to implement rules and ideas that will improve our league for the long run
                            which includes the following:
                        </CardText>

                        <ul>
                            <li>We always try to listen to others and value what they say</li>
                            <li>
                                We will create a competitive atmosphere where every member will have the opportunity to improve their
                                volleyball skills
                            </li>
                            <li>We will provide regular updates about our league through this website</li>
                            <li>
                                We will always give out prizes that are useful and generally don't get the, "argg what am I suppose to do
                                with this?" look
                            </li>
                            <li>
                                We will continue to build relationships with outside groups and look for offers that can benefit members of
                                this club
                            </li>
                            <li>
                                We will keep costs affordable for all members and will not at any time add in silly ridiculous atrocious
                                hidden fees
                            </li>
                        </ul>
                    </CardBody>
                </Card>
            </main>
        </div>
    );
};

export default About;
