import { put, call } from 'redux-saga/effects';

import * as actions from '../actions';
import firebase from '../../components/Integrations/Firebase';
import * as Firebase from 'firebase/app';

export function* loginSaga(action) {
    const user = action.loginData;
    try {
        const response = yield firebase.auth().signInWithEmailAndPassword(user.email, user.password);
        // Clear States
        yield put(actions.clearAuthSuccessStates());
        yield put(actions.clearAuthErrorStates());
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        const userData = {
            userId: response.user.uid,
            email: response.user.email,
            emailVerified: response.user.emailVerified
        };
        yield put(actions.loginSuccess(userData));
    } catch (error) {
        yield put(actions.loginFail(error));
    }
}

export function* loginAnonymouslySaga() {
    try {
        const response = yield firebase.auth().signInAnonymously();

        const userData = {
            userId: response.user.uid
        };

        const profile = {
            id: response.user.uid,
            roles: [
                'visitor'
            ]
        };

        // Clear States
        yield put(actions.clearAuthSuccessStates());
        yield put(actions.clearAuthErrorStates());
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.createAnonymousProfile(profile));
        yield put(actions.loginAnonymouslySuccess(userData));
    } catch (error) {
        yield put(actions.loginAnonymouslyFail(error));
    }
}

export function* logoutSaga(action) {
    try {
        yield firebase.auth().signOut();
        // yield clear(); // Remove Permissions
        yield put(actions.clearAuthSuccessStates());
        yield put(actions.clearAuthErrorStates());
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.logoutSuccess());
    } catch (error) {
        yield put(actions.logoutFail());
    }
}

export function* reauthenticateSaga(action) {
    const loginData = action.loginData;

    try {
        const credential = yield Firebase.auth.EmailAuthProvider.credential(loginData.email, loginData.password);
        const response = yield firebase.auth().currentUser.reauthenticateWithCredential(credential);
        yield put(actions.clearAuthSuccessStates());
        yield put(actions.clearAuthErrorStates());
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.reauthenticateSuccess(response.user.uid));
    } catch (error) {
        yield put(actions.reauthenticateFail(error));
    }
}

export function* registerSaga(action) {
    const user = action.registrationData;
    try {
        const response = yield firebase.auth().createUserWithEmailAndPassword(user.email, user.password);
        const profile = {
            id: response.user.uid,
            email: user.email,
            firstName: '',
            lastName: '',
            profileImageUrl: '',
            roles: [
                'player'
            ]
        };
        yield put(actions.createProfile(profile, response.user.uid));
        yield put(actions.sendEmailVerification());
        yield put(actions.clearAuthSuccessStates());
        yield put(actions.clearAuthErrorStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.registerSuccess(response.user.uid));
    } catch (error) {
        // console.log('[registerSaga > error]', error);
        yield put(actions.registerFail(error));
    }
}

export function* sendEmailVerificationSaga(action) {
    try {
        const user = yield firebase.auth().currentUser;
        yield user.sendEmailVerification();
        yield put(actions.sendEmailVerificationSuccess());
    } catch (error) {
        yield put(actions.sendEmailVerificationFail(error));
    }
}

export function* forgotPasswordSaga(action) {
    const email = action.email;
    try {
        yield firebase.auth().sendPasswordResetEmail(email);
        yield put(actions.forgotPasswordSuccess());
    } catch (error) {
        yield put(actions.forgotPasswordFail(error));
    }
}

export function* deleteAccountSaga(action) {
    try {
        yield put(actions.deleteProfile(action.userId));
        const user = yield firebase.auth().currentUser;
        yield user.delete();
        yield put(actions.clearAuthSuccessStates());
        yield put(actions.clearAuthErrorStates());
        yield put(actions.clearUserSuccessStates());
        yield put(actions.clearUserErrorStates());
        yield put(actions.deleteAccountSuccess());
    } catch (error) {
        yield put(actions.deleteAccountFail(error));
    }
}

function checkAuthState() {
    return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                resolve(user);
            } else {
                reject(new Error('User not found.'));
            }
        });
    });
}

export function* checkAutoLoginSaga(action) {
    try {
        const user = yield call(checkAuthState);
        if (user) {
            yield put(actions.clearAuthSuccessStates());
            yield put(actions.clearAuthErrorStates());
            yield put(actions.clearUserSuccessStates());
            yield put(actions.clearUserErrorStates());
            
            const userData = {
                userId: user.uid,
                email: user.email ? user.email : null,
                emailVerified: user.emailVerified ? user.emailVerified : null
            };

            // Create 'visitor' profile for anonymous users
            if (user.isAnonymous) {
                const profile = {
                    id: user.uid,
                    roles: [
                        'visitor'
                    ]
                };
                yield put(actions.createAnonymousProfile(profile));
            }

            // console.log('[checkAutoLoginSaga > userData]', userData);
            yield put(actions.checkAutoLoginSuccess(userData));
        }
    } catch (error) {
        // console.log('[checkAutoLoginSaga > error]', error);
        // Do not push error into checkAutoLoginFail or it will be displayed to the user on every visit.
        yield put(actions.checkAutoLoginFail());
    }
}
