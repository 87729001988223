import React from 'react';

import styles from './Banner.module.css';
// import vcTorontoHeader from '../../../assets/images/vctoronto-header.jpg';
import vcTorontoHeader from '../../../assets/images/vctoronto-header-2.jpg';

const Banner = props => {
    return (
        <div className={styles.Banner}>
            <header>
                <img src={vcTorontoHeader} alt='VCToronto' />
            </header>
        </div>
    );
};

export default Banner;
