// TEAMS ACTION

import * as actionTypes from './actionTypes';

export const fetchTeams = (sort) => {
    return {
        type: actionTypes.FETCH_TEAMS,
        sort: sort
    };
}

export const fetchTeamsSuccess = (teams) => {
    return {
        type: actionTypes.FETCH_TEAMS_SUCCESS,
        teams: teams
    };
}

export const fetchTeamsFail = (error) => {
    return {
        type: actionTypes.FETCH_TEAMS_FAIL,
        error: error
    };
}

export const createTeam = (userId, team) => {
    return {
        type: actionTypes.CREATE_TEAM,
        userId: userId,
        team: team
    };
}

export const createTeamSuccess = () => {
    return {
        type: actionTypes.CREATE_TEAM_SUCCESS
    };
}

export const createTeamFail = (error) => {
    return {
        type: actionTypes.CREATE_TEAM_FAIL,
        error: error
    };
}

export const createBulkTeams = (userId, teams) => {
    return {
        type: actionTypes.CREATE_BULK_TEAMS,
        userId: userId,
        teams: teams
    };
}

export const createBulkTeamsSuccess = () => {
    return {
        type: actionTypes.CREATE_BULK_TEAMS_SUCCESS
    };
}

export const createBulkTeamsFail = (error) => {
    return {
        type: actionTypes.CREATE_BULK_TEAMS_FAIL,
        error: error
    };
}

export const updateTeam = (userId, team, teamId) => {
    return {
        type: actionTypes.UPDATE_TEAM,
        userId: userId,
        team: team,
        teamId: teamId
    };
}

export const updateTeamSuccess = () => {
    return {
        type: actionTypes.UPDATE_TEAM_SUCCESS
    };
}

export const updateTeamFail = (error) => {
    return {
        type: actionTypes.UPDATE_TEAM_FAIL,
        error: error
    };
}

export const addTeamToSeason = (userId, team, teamId) => {
    return {
        type: actionTypes.ADD_TEAM_TO_SEASON,
        userId: userId,
        team: team,
        teamId: teamId
    };
}

export const addTeamToSeasonSuccess = () => {
    return {
        type: actionTypes.ADD_TEAM_TO_SEASON_SUCCESS
    };
}

export const addTeamToSeasonFail = (error) => {
    return {
        type: actionTypes.ADD_TEAM_TO_SEASON_FAIL,
        error: error
    };
}

export const removeTeamFromSeason = (userId, team, teamId) => {
    return {
        type: actionTypes.REMOVE_TEAM_FROM_SEASON,
        userId: userId,
        team: team,
        teamId: teamId
    };
}

export const removeTeamFromSeasonSuccess = () => {
    return {
        type: actionTypes.REMOVE_TEAM_FROM_SEASON_SUCCESS
    };
}

export const removeTeamFromSeasonFail = (error) => {
    return {
        type: actionTypes.REMOVE_TEAM_FROM_SEASON_FAIL,
        error: error
    };
}

export const deleteTeam = (userId, team) => {
    return {
        type: actionTypes.DELETE_TEAM,
        userId: userId,
        team: team
    };
}

export const deleteTeamSuccess = () => {
    return {
        type: actionTypes.DELETE_TEAM_SUCCESS
    };
}

export const deleteTeamFail = (error) => {
    return {
        type: actionTypes.DELETE_TEAM_FAIL,
        error: error
    };
}

export const clearTeamSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_TEAM_SUCCESS_STATES
    };
}

export const clearTeamErrorStates = () => {
    return {
        type: actionTypes.CLEAR_TEAM_ERROR_STATES
    };
}