// AUTH ACTIONS

import * as actionTypes from './actionTypes';

export const login = (loginData) => {
    return {
        type: actionTypes.LOGIN,
        loginData: loginData
    };
}

export const loginSuccess = (userData) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        userData: userData
    };
}

export const loginFail = (error) => {
    return {
        type: actionTypes.LOGIN_FAIL,
        error: error
    };
}

export const loginAnonymously = () => {
    return {
        type: actionTypes.LOGIN_ANONYMOUSLY
    };
}

export const loginAnonymouslySuccess = (userData) => {
    return {
        type: actionTypes.LOGIN_ANONYMOUSLY_SUCCESS,
        userData: userData
    };
}

export const loginAnonymouslyFail = (error) => {
    return {
        type: actionTypes.LOGIN_ANONYMOUSLY_FAIL,
        error: error
    };
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    };
}

export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    };
}

export const logoutFail = (error) => {
    return {
        type: actionTypes.LOGOUT_FAIL,
        error: error
    };
}


export const reauthenticate = (loginData) => {
    return {
        type: actionTypes.REAUTHENTICATE,
        loginData: loginData
    };
}

export const reauthenticateSuccess = (userId) => {
    return {
        type: actionTypes.REAUTHENTICATE_SUCCESS,
        userId: userId
    };
}

export const reauthenticateFail = (error) => {
    return {
        type: actionTypes.REAUTHENTICATE_FAIL,
        error: error
    };
}

export const register = (registrationData) => {
    return {
        type: actionTypes.REGISTER,
        registrationData: registrationData
    };
}

export const registerSuccess = (userId) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        userId: userId
    };
}

export const registerFail = (error) => {
    return {
        type: actionTypes.REGISTER_FAIL,
        error: error
    };
}

export const sendEmailVerification = () => {
    return {
        type: actionTypes.SEND_EMAIL_VERIFICATION
    };
}

export const sendEmailVerificationSuccess = () => {
    return {
        type: actionTypes.SEND_EMAIL_VERIFICATION_SUCCESS
    };
}

export const sendEmailVerificationFail = (error) => {
    return {
        type: actionTypes.SEND_EMAIL_VERIFICATION_FAIL,
        error: error
    };
}

export const forgotPassword = (email) => {
    return {
        type: actionTypes.FORGOT_PASSWORD,
        email: email
    };
}

export const forgotPasswordSuccess = () => {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS
    };
}

export const forgotPasswordFail = (error) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_FAIL,
        error: error
    };
}

export const checkAutoLogin = () => {
    // console.log('[auth.js Action > checkAutoLogin]');
    return {
        type: actionTypes.CHECK_AUTO_LOGIN
    };
}

export const checkAutoLoginSuccess = (userData) => {
    return {
        type: actionTypes.CHECK_AUTO_LOGIN_SUCCESS,
        userData: userData
    };
}

export const checkAutoLoginFail = (error) => {
    return {
        type: actionTypes.CHECK_AUTO_LOGIN_FAIL,
        error: error
    }
}


export const deleteAccount = (userId) => {
    return {
        type: actionTypes.DELETE_ACCOUNT,
        userId: userId
    };
}

export const deleteAccountSuccess = () => {
    return {
        type: actionTypes.DELETE_ACCOUNT_SUCCESS
    };
}

export const deleteAccountFail = (error) => {
    return {
        type: actionTypes.DELETE_ACCOUNT_FAIL,
        error: error
    };
}

export const clearAuthSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_AUTH_SUCCESS_STATES
    };
}

export const clearAuthErrorStates = () => {
    return {
        type: actionTypes.CLEAR_AUTH_ERROR_STATES
    };
}