import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import firebase from '../../components/Integrations/Firebase';

export function* fetchAllProfilesSaga() {
    try {
        let profiles = [];
        const querySnapshot = yield firebase
            .db()
            .collection('profiles')
            .get();
        yield querySnapshot.forEach(doc => {
            profiles.push(doc.data());
        });
        yield put(actions.fetchAllProfilesSuccess(profiles));
    } catch (error) {
        yield put(actions.fetchAllProfilesFail(error));
    }
}