import React, { useEffect } from 'react';
import { Table, Alert, Badge } from 'reactstrap';
import { connect } from 'react-redux';

import styles from './SidePanelStandings.module.css';
import * as actions from '../../../store/actions';
import Spinner from '../../../components/UI/Spinner/Spinner';
import getLeagueAndSeasonName from '../../../utilities/helpers/getLeagueAndSeasonName';

const SidePanelStandings = props => {
    // Initialize Standings Config
    const standingsConfig = {
        showTeams: true,
        showWins: true,
        showLosses: true,
        showTies: false,
        showSeasonPoints: true,
        showAccumulatedGamePoints: false,
        showGamesPlayed: false,
        showUpcomingGamesScheduled: false
    };

    // Initializing State for Fetch & Set Standings
    const { websiteBranding, filteredSeasons, games, leagues, seasons, teams, onFetchWebsiteBranding, onFetchGames, onFetchLeagues, onFetchSeasons, onFetchTeams } = props;

    // Get Website Branding
    useEffect(() => {
        if (!websiteBranding) {
            onFetchWebsiteBranding();
        }
    }, [websiteBranding, onFetchWebsiteBranding]);

    // Get Games
    useEffect(() => {
        if (!games) {
            onFetchGames();
        }
    }, [games, onFetchGames]);
    
    // Get Leagues
    useEffect(() => {
        if (!leagues) {
            onFetchLeagues();
        }
    }, [leagues, onFetchLeagues]);

    // Get Seasons
    useEffect(() => {
        if (!seasons) {
            onFetchSeasons();
        }
    }, [seasons, onFetchSeasons]);

    // Get Teams
    useEffect(() => {
        if (!teams) {
            onFetchTeams();
        }
    }, [teams, onFetchTeams]);

    // Set Branding Variables in CSS
    useEffect(() => {
        if (websiteBranding) {
            const branding = { ...websiteBranding };
            document.documentElement.style.setProperty('--sidePanelBackgroundColor', branding.theme.sidePanelBackgroundColor);
            document.documentElement.style.setProperty('--sidePanelHeadingTextColor', branding.theme.sidePanelHeadingTextColor);
            document.documentElement.style.setProperty('--sidePanelSubtitleTextColor', branding.theme.sidePanelSubtitleTextColor);
            document.documentElement.style.setProperty('--sidePanelBodyTextColor', branding.theme.sidePanelBodyTextColor);
        }
    });

    // Display Errors
    let alert = null;

    if (props.error) {
        console.log('[Standings.js > error]', props.error.toString());
        alert = (
            <Alert color='danger'>
                <p>Sorry, something went wrong! Please try again later.</p>
                <p>{props.error.toString()}</p>
            </Alert>
        );
    }

    // Display List of Seasons as Cards
    let seasonCards = null;
    let listOfSeasons = null;

    // Determine if filteredSeasons were passed as a prop
    if (filteredSeasons) {
        listOfSeasons = filteredSeasons;
    } else {
        listOfSeasons = seasons;
    }

    if (props.loading) {
        seasonCards = <Spinner />;
    }

    if (games && leagues && seasons && teams) {
        seasonCards = listOfSeasons.map(season => (
            <div key={season.id} className={styles.Table}>
                <h4>
                    <Badge color='primary'>{getLeagueAndSeasonName(season.id, seasons, leagues)}</Badge>
                </h4>
                {alert}
                <Table borderless size='sm'>
                    <thead>
                        <tr className={styles.TableHeading}>
                            {standingsConfig.showTeams ? <th colSpan='3'>Team</th> : null}
                            {standingsConfig.showWins ? <th>Wins</th> : null}
                            {standingsConfig.showLosses ? <th>Losses</th> : null}
                            {standingsConfig.showTies ? <th>Ties</th> : null}
                            {standingsConfig.showSeasonPoints ? <th>Points</th> : null}
                            {standingsConfig.showAccumulatedGamePoints ? <th>Game Points</th> : null}
                            {standingsConfig.showGamesPlayed ? <th>Games Played</th> : null}
                            {standingsConfig.showUpcomingGamesScheduled ? <th>Upcoming Games Scheduled</th> : null}
                        </tr>
                    </thead>
                    <tbody className={styles.TableBody}>
                        {/* TO DO: Transition Logic to Backend */}
                        {teams
                            .filter(team => {
                                if (team.seasons) {
                                    const seasonRef = team.seasons.filter(teamSeason => teamSeason === season.id);
                                    return seasonRef.length > 0;
                                } else {
                                    return false;
                                }
                            })
                            .map(team => {
                                const result = games.reduce(
                                    (accumulator, game) => {
                                        const stats = {
                                            totalWins: 0,
                                            totalLosses: 0,
                                            totalTies: 0,
                                            totalSeasonPoints: 0,
                                            totalGamePoints: 0,
                                            totalGamesPlayed: 0,
                                            totalGamesScheduled: 0
                                        };
                                        // Check if game is in the current season
                                        if (game.season === season.id) {
                                            // Check if team is in the current game, calculate points, games played, games scheduled
                                            if (game.teamHome === team.id) {
                                                if (game.gameStatus === 'Completed') {
                                                    stats.totalGamesPlayed++;
                                                } else {
                                                    stats.totalGamesScheduled++;
                                                }
                                                stats.totalGamePoints = game.teamHomeScore
                                                    ? +stats.totalGamePoints + +game.teamHomeScore
                                                    : 0;
                                            }

                                            if (game.teamAway === team.id) {
                                                if (game.gameStatus === 'Completed') {
                                                    stats.totalGamesPlayed++;
                                                } else {
                                                    stats.totalGamesScheduled++;
                                                }
                                                stats.totalGamePoints = game.teamAwayScore
                                                    ? +stats.totalGamePoints + +game.teamAwayScore
                                                    : 0;
                                            }

                                            // Check if winning team / losing team is current team
                                            if (game.winningTeam === team.id) {
                                                stats.totalWins++;
                                                stats.totalSeasonPoints = stats.totalSeasonPoints + 2;
                                            } else if (game.losingTeam === team.id) {
                                                stats.totalLosses++;
                                            } else if (
                                                (game.teamHome === team.id || game.teamAway === team.id) &&
                                                game.winningTeam === 'Tied'
                                            ) {
                                                stats.totalTies++;
                                                stats.totalSeasonPoints = stats.totalSeasonPoints + 1;
                                            }
                                        }
                                        return {
                                            ...accumulator,
                                            totalWins: accumulator.totalWins + stats.totalWins,
                                            totalLosses: accumulator.totalLosses + stats.totalLosses,
                                            totalTies: accumulator.totalTies + stats.totalTies,
                                            totalSeasonPoints: accumulator.totalSeasonPoints + stats.totalSeasonPoints,
                                            totalGamePoints: +accumulator.totalGamePoints + +stats.totalGamePoints,
                                            totalGamesPlayed: accumulator.totalGamesPlayed + stats.totalGamesPlayed,
                                            totalGamesScheduled: accumulator.totalGamesScheduled + stats.totalGamesScheduled
                                        };
                                    },
                                    {
                                        totalWins: 0,
                                        totalLosses: 0,
                                        totalTies: 0,
                                        totalSeasonPoints: 0,
                                        totalGamePoints: 0,
                                        totalGamesPlayed: 0,
                                        totalGamesScheduled: 0
                                    }
                                );

                                return (
                                    <tr key={team.id}>
                                        {standingsConfig.showTeams ? <td colSpan='3'>{team.name}</td> : null}
                                        {standingsConfig.showWins ? <td>{result.totalWins}</td> : null}
                                        {standingsConfig.showLosses ? <td>{result.totalLosses}</td> : null}
                                        {standingsConfig.showTies ? <td>{result.totalTies}</td> : null}
                                        {standingsConfig.showSeasonPoints ? <td>{result.totalSeasonPoints}</td> : null}
                                        {standingsConfig.showAccumulatedGamePoints ? <td>{result.totalGamePoints}</td> : null}
                                        {standingsConfig.showGamesPlayed ? <td>{result.totalGamesPlayed}</td> : null}
                                        {standingsConfig.showUpcomingGamesScheduled ? <td>{result.totalGamesScheduled}</td> : null}
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
            </div>
        ));
    }

    return (
        <div className='mb-4'>
            <div className={styles.StandingsHeading}>
                <h4>
                    <strong>Standings</strong>
                </h4>
            </div>
            {seasonCards}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        websiteBranding: state.websiteConfiguration.websiteBranding,
        leagues: state.leagues.leagues,
        seasons: state.seasons.seasons,
        teams: state.teams.teams,
        games: state.games.games,
        profile: state.user.currentProfile,
        error: state.games.error,
        loading: state.games.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchWebsiteBranding: () => dispatch(actions.fetchWebsiteBranding()),
        onFetchGames: (sort, query) => dispatch(actions.fetchGames(sort, query)),
        onFetchLeagues: (sort, query) => dispatch(actions.fetchLeagues(sort, query)),
        onFetchSeasons: (sort, query) => dispatch(actions.fetchSeasons(sort, query)),
        onFetchTeams: sort => dispatch(actions.fetchTeams(sort)),
        onClearGameErrorStates: () => dispatch(actions.clearGameErrorStates())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SidePanelStandings);
