// PLAYERS ACTION

import * as actionTypes from './actionTypes';

export const fetchPlayers = (sort) => {
    return {
        type: actionTypes.FETCH_PLAYERS,
        sort: sort
    };
}

export const fetchPlayersSuccess = (players) => {
    return {
        type: actionTypes.FETCH_PLAYERS_SUCCESS,
        players: players
    };
}

export const fetchPlayersFail = (error) => {
    return {
        type: actionTypes.FETCH_PLAYERS_FAIL,
        error: error
    };
}

export const createPlayer = (userId, player) => {
    return {
        type: actionTypes.CREATE_PLAYER,
        userId: userId,
        player: player
    };
}

export const createPlayerSuccess = () => {
    return {
        type: actionTypes.CREATE_PLAYER_SUCCESS
    };
}

export const createPlayerFail = (error) => {
    return {
        type: actionTypes.CREATE_PLAYER_FAIL,
        error: error
    };
}

export const updatePlayer = (userId, player, playerId) => {
    return {
        type: actionTypes.UPDATE_PLAYER,
        userId: userId,
        player: player,
        playerId: playerId
    };
}

export const updatePlayerSuccess = () => {
    return {
        type: actionTypes.UPDATE_PLAYER_SUCCESS
    };
}

export const updatePlayerFail = (error) => {
    return {
        type: actionTypes.UPDATE_PLAYER_FAIL,
        error: error
    };
}

export const addPlayerToTeam = (userId, player, playerId) => {
    return {
        type: actionTypes.ADD_PLAYER_TO_TEAM,
        userId: userId,
        player: player,
        playerId: playerId
    };
}

export const addPlayerToTeamSuccess = () => {
    return {
        type: actionTypes.ADD_PLAYER_TO_TEAM_SUCCESS
    };
}

export const addPlayerToTeamFail = (error) => {
    return {
        type: actionTypes.ADD_PLAYER_TO_TEAM_FAIL,
        error: error
    };
}

export const removePlayerFromTeam = (userId, player, playerId) => {
    return {
        type: actionTypes.REMOVE_PLAYER_FROM_TEAM,
        userId: userId,
        player: player,
        playerId: playerId
    };
}

export const removePlayerFromTeamSuccess = () => {
    return {
        type: actionTypes.REMOVE_PLAYER_FROM_TEAM_SUCCESS
    };
}

export const removePlayerFromTeamFail = (error) => {
    return {
        type: actionTypes.REMOVE_PLAYER_FROM_TEAM_FAIL,
        error: error
    };
}

export const deletePlayer = (userId, player) => {
    return {
        type: actionTypes.DELETE_PLAYER,
        userId: userId,
        player: player
    };
}

export const deletePlayerSuccess = () => {
    return {
        type: actionTypes.DELETE_PLAYER_SUCCESS
    };
}

export const deletePlayerFail = (error) => {
    return {
        type: actionTypes.DELETE_PLAYER_FAIL,
        error: error
    };
}

export const clearPlayerSuccessStates = () => {
    return {
        type: actionTypes.CLEAR_PLAYER_SUCCESS_STATES
    };
}

export const clearPlayerErrorStates = () => {
    return {
        type: actionTypes.CLEAR_PLAYER_ERROR_STATES
    };
}