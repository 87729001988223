// ALERTS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    alerts: null,
    publishedAlerts: null,
    alert: null,
    fetchAllAlertsSuccess: null,
    fetchPublishedAlertsSuccess: null,
    fetchAlertSuccess: null,
    createAlertSuccess: null,
    updateAlertSuccess: null,
    deleteAlertSuccess: null,
    error: null,
    loading: false
}

const fetchAllAlerts = (state, action) => {
    return updateObject( state, {
        fetchAllAlertsSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchAllAlertsSuccess = (state, action) => {
    return updateObject( state, {
        alerts: action.alerts,
        fetchAllAlertsSuccess: true,
        error: null,
        loading: false
    });
}

const fetchAllAlertsFail = (state, action) => {
    return updateObject( state, {
        fetchAllAlertsSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchPublishedAlerts = (state, action) => {
    return updateObject( state, {
        fetchPublishedAlertsSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchPublishedAlertsSuccess = (state, action) => {
    return updateObject( state, {
        publishedAlerts: action.publishedAlerts,
        fetchPublishedAlertsSuccess: true,
        error: null,
        loading: false
    });
}

const fetchPublishedAlertsFail = (state, action) => {
    return updateObject( state, {
        fetchPublishedAlertsSuccess: false,
        error: action.error,
        loading: false
    });
}

const fetchAlert = (state, action) => {
    return updateObject( state, {
        fetchAlertSuccess: null,
        error: null, 
        loading: true 
    });
}

const fetchAlertSuccess = (state, action) => {
    return updateObject( state, {
        alert: action.alert,
        fetchAlertSuccess: true,
        error: null,
        loading: false
    });
}

const fetchAlertFail = (state, action) => {
    return updateObject( state, {
        fetchAlertSuccess: false,
        error: action.error,
        loading: false
    });
}

const createAlert = (state, action) => {
    return updateObject( state, {
        createAlertSuccess: null,
        error: null, 
        loading: true 
    });
}

const createAlertSuccess = (state, action) => {
    return updateObject( state, {
        createAlertSuccess: true,
        error: null,
        loading: false
    });
}

const createAlertFail = (state, action) => {
    return updateObject( state, {
        createAlertSuccess: false,
        error: action.error,
        loading: false
    });
}

const updateAlert = (state, action) => {
    return updateObject( state, {
        updateAlertSuccess: null,
        error: null, 
        loading: true 
    });
}

const updateAlertSuccess = (state, action) => {
    return updateObject( state, {
        updateAlertSuccess: true,
        error: null,
        loading: false
    });
}

const updateAlertFail = (state, action) => {
    return updateObject( state, {
        updateAlertSuccess: false,
        error: action.error, 
        loading: false
    });
}

const deleteAlert = (state, action) => {
    return updateObject( state, {
        deleteAlertSuccess: null,
        error: null, 
        loading: true 
    });
}

const deleteAlertSuccess = (state, action) => {
    return updateObject( state, {
        deleteAlertSuccess: true,
        error: null, 
        loading: false 
    });
}

const deleteAlertFail = (state, action) => {
    return updateObject( state, {
        deleteAlertSuccess: false,
        error: action.error, 
        loading: false 
    });
}

const clearAlertSuccessStates = (state, action) => {
    return updateObject( state, {
        fetchAllAlertsSuccess: null,
        fetchPublishedAlertsSuccess: null,
        fetchAlertSuccess: null,
        createAlertSuccess: null,
        updateAlertSuccess: null,
        deleteAlertSuccess: null
    });
}

const clearAlertErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ALL_ALERTS: return fetchAllAlerts(state, action);
        case actionTypes.FETCH_ALL_ALERTS_SUCCESS: return fetchAllAlertsSuccess(state, action);
        case actionTypes.FETCH_ALL_ALERTS_FAIL: return fetchAllAlertsFail(state, action);
        case actionTypes.FETCH_PUBLISHED_ALERTS: return fetchPublishedAlerts(state, action);
        case actionTypes.FETCH_PUBLISHED_ALERTS_SUCCESS: return fetchPublishedAlertsSuccess(state, action);
        case actionTypes.FETCH_PUBLISHED_ALERTS_FAIL: return fetchPublishedAlertsFail(state, action);
        case actionTypes.FETCH_ALERT: return fetchAlert(state, action);
        case actionTypes.FETCH_ALERT_SUCCESS: return fetchAlertSuccess(state, action);
        case actionTypes.FETCH_ALERT_FAIL: return fetchAlertFail(state, action);
        case actionTypes.CREATE_ALERT: return createAlert(state, action);
        case actionTypes.CREATE_ALERT_SUCCESS: return createAlertSuccess(state, action);
        case actionTypes.CREATE_ALERT_FAIL: return createAlertFail(state, action);
        case actionTypes.UPDATE_ALERT: return updateAlert(state, action);
        case actionTypes.UPDATE_ALERT_SUCCESS: return updateAlertSuccess(state, action);
        case actionTypes.UPDATE_ALERT_FAIL: return updateAlertFail(state, action);
        case actionTypes.DELETE_ALERT: return deleteAlert(state, action);
        case actionTypes.DELETE_ALERT_SUCCESS: return deleteAlertSuccess(state, action);
        case actionTypes.DELETE_ALERT_FAIL: return deleteAlertFail(state, action);
        case actionTypes.CLEAR_ALERT_SUCCESS_STATES: return clearAlertSuccessStates(state, action);
        case actionTypes.CLEAR_ALERT_ERROR_STATES: return clearAlertErrorStates(state, action);
        default: return state;
    }
}

export default reducer;