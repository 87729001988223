import { put } from 'redux-saga/effects';
import moment from 'moment';

import * as actions from '../actions/index';
import * as sortTypes from '../../utilities/helpers/types';
import firebase from '../../components/Integrations/Firebase';

export function* fetchGamesSaga(action) {
    try {
        const games = [];
        let query = null;
        let querySnapshot = null;

        // Determine Sort Fields
        let sortField = null;
        let sortDirection = null;
        let sortField2 = null;
        let sortDirection2 = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_DATE_ASC:
                sortField = 'date';
                sortDirection = 'asc';
                sortField2 = 'time';
                sortDirection2 = 'asc';
                break;
            case sortTypes.SORT_BY_DATE_DESC:
                sortField = 'date';
                sortDirection = 'desc';
                sortField2 = 'time';
                sortDirection2 = 'desc';
                break;
            default:
                sortField = 'date';
                sortDirection = 'asc';
                sortField2 = 'time';
                sortDirection2 = 'asc';
                break;
        }

        // Check if action contains filter or query
        // Requires custom composite index (see error logged if the index does not show)
        // Date format: YYYY-MM-DD
        if (action.filterDate) {
            const filter = { ...action.filterDate };
            if (filter.date) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '==', filter.date)
                    .get();
            } else if (filter.fromDate && filter.toDate) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '>=', filter.fromDate)
                    .where('date', '<=', filter.toDate)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else if (filter.fromDate) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '>=', filter.fromDate)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else if (filter.toDate) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '<=', filter.toDate)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .orderBy(sortField, sortDirection)
                    .orderBy(sortField2, sortDirection2)
                    .get();
            }
        } else if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            if (action.query.field === 'date') {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where(query.field, query.operation, query.value)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where(query.field, query.operation, query.value)
                    .orderBy(sortField, sortDirection)
                    .get();
            }
            // Fetch all games if no filter exists
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .orderBy(sortField, sortDirection)
                .orderBy(sortField2, sortDirection2)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });
        yield put(actions.fetchGamesSuccess(games));
    } catch (error) {
        // console.log('[fetchGamesSaga > error]', error);
        yield put(actions.fetchGamesFail(error));
    }
}

export function* fetchGamesByDateSaga(action) {
    try {
        const games = [];
        let querySnapshot = null;

        // Determine Sort Fields
        let sortField = null;
        let sortDirection = null;
        let sortField2 = null;
        let sortDirection2 = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_DATE_ASC:
                sortField = 'date';
                sortDirection = 'asc';
                sortField2 = 'time';
                sortDirection2 = 'asc';
                break;
            case sortTypes.SORT_BY_DATE_DESC:
                sortField = 'date';
                sortDirection = 'desc';
                sortField2 = 'time';
                sortDirection2 = 'desc';
                break;
            default:
                sortField = 'date';
                sortDirection = 'asc';
                sortField2 = 'time';
                sortDirection2 = 'asc';
                break;
        }

        // Check if action contains filter or query
        // Requires custom composite index (see error logged if the index does not show)
        // Date format: YYYY-MM-DD
        if (action.filterDate) {
            const filter = { ...action.filterDate };
            if (filter.date) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '==', filter.date)
                    .get();
            } else if (filter.fromDate && filter.toDate) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '>=', filter.fromDate)
                    .where('date', '<=', filter.toDate)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else if (filter.fromDate) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '>=', filter.fromDate)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else if (filter.toDate) {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where('date', '<=', filter.toDate)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .orderBy(sortField, sortDirection)
                    .orderBy(sortField2, sortDirection2)
                    .get();
            }
            // Fetch all games if no filter exists
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .orderBy(sortField, sortDirection)
                .orderBy(sortField2, sortDirection2)
                .get();
        }
        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });

        // Sort by Date, Time, and Court
        games.sort((a, b) => {
            if (a.date > b.date) {
                return 1;
            } else if (a.date === b.date) {
                if (a.time > b.time) {
                    return 1;
                } else if (a.time === b.time) {
                    if (a.court && b.court) {
                        if (a.court > b.court) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            } else {
                return -1;
            }
        });

        yield put(actions.fetchGamesByDateSuccess(games));
    } catch (error) {
        // console.log('[fetchGamesByDateSaga > error]', error);
        yield put(actions.fetchGamesByDateFail(error));
    }
}

export function* fetchGamesByQuerySaga(action) {
    try {
        const games = [];
        let query = null;
        let querySnapshot = null;

        // Determine Sort Fields
        let sortField = null;
        let sortDirection = null;
        let sortField2 = null;
        let sortDirection2 = null;
        switch (action.sort) {
            case sortTypes.SORT_BY_DATE_ASC:
                sortField = 'date';
                sortDirection = 'asc';
                sortField2 = 'time';
                sortDirection2 = 'asc';
                break;
            case sortTypes.SORT_BY_DATE_DESC:
                sortField = 'date';
                sortDirection = 'desc';
                sortField2 = 'time';
                sortDirection2 = 'desc';
                break;
            default:
                sortField = 'date';
                sortDirection = 'asc';
                sortField2 = 'time';
                sortDirection2 = 'asc';
                break;
        }

        // Check if action contains query
        // Requires custom composite index (see error logged if the index does not show)
        // Sort is only available on the query field in Firestore
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            if (action.query.field === 'date') {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where(query.field, query.operation, query.value)
                    .orderBy(sortField, sortDirection)
                    .get();
            } else {
                querySnapshot = yield firebase
                    .db()
                    .collection('games')
                    .where(query.field, query.operation, query.value)
                    .orderBy(sortField, sortDirection)
                    .get();
            }
            // Fetch all games if no date or query exists
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .orderBy(sortField, sortDirection)
                .orderBy(sortField2, sortDirection2)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });

        // Sort by Date, Time, and Court
        games.sort((a, b) => {
            if (a.date > b.date) {
                return 1;
            } else if (a.date === b.date) {
                if (a.time > b.time) {
                    return 1;
                } else if (a.time === b.time) {
                    if (a.court && b.court) {
                        if (a.court > b.court) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            } else {
                return -1;
            }
        });
        yield put(actions.fetchGamesByQuerySuccess(games));
    } catch (error) {
        // console.log('[fetchGamesByQuerySaga > error]', error);
        yield put(actions.fetchGamesByQuerySuccess(error));
    }
}

export function* fetchUpcomingGamesSaga(action) {
    try {
        const games = [];
        let query = null;
        let querySnapshot = null;

        // Upcoming Games: Today's Date + 7 Days
        const today = moment();
        const sevenDaysLater = moment(today).add('7', 'days');

        const todayMoment = moment(today).format('YYYY-MM-DD');
        const sevenDaysLaterMoment = moment(sevenDaysLater).format('YYYY-MM-DD');

        // Check if action contains query
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .where('date', '>=', todayMoment)
                .where('date', '<=', sevenDaysLaterMoment)
                .where(query.field, query.operation, query.value)
                .get();
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .where('date', '>=', todayMoment)
                .where('date', '<=', sevenDaysLaterMoment)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });

        // Sort by Date, Time, and Court
        games.sort((a, b) => {
            if (a.date > b.date) {
                return 1;
            } else if (a.date === b.date) {
                if (a.time > b.time) {
                    return 1;
                } else if (a.time === b.time) {
                    if (a.court && b.court) {
                        if (a.court > b.court) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            } else {
                return -1;
            }
        });

        yield put(actions.fetchUpcomingGamesSuccess(games));
    } catch (error) {
        // console.log('[fetchUpcomingGamesSaga > error]', error);
        yield put(actions.fetchUpcomingGamesFail(error));
    }
}

export function* fetchCompletedGamesSaga(action) {
    try {
        const games = [];
        let query = null;
        let querySnapshot = null;

        // Completed Games: Games with status = Completed
        // Check if action contains query
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .where('gameStatus', '==', 'Completed')
                .where(query.field, query.operation, query.value)
                .get();
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .where('gameStatus', '==', 'Completed')
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });

        // Sort by Date, Time, and Court
        games.sort((a, b) => {
            if (a.date > b.date) {
                return 1;
            } else if (a.date === b.date) {
                if (a.time > b.time) {
                    return 1;
                } else if (a.time === b.time) {
                    if (a.court && b.court) {
                        if (a.court > b.court) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            } else {
                return -1;
            }
        });

        yield put(actions.fetchCompletedGamesSuccess(games));
    } catch (error) {
        // console.log('[fetchCompletedGamesSaga > error]', error);
        yield put(actions.fetchCompletedGamesFail(error));
    }
}

export function* fetchPreviousGamesSaga(action) {
    try {
        const games = [];
        let query = null;
        let querySnapshot = null;

        // Previous Games: Games with date before 'today'
        const today = moment();
        const todayMoment = moment(today).format('YYYY-MM-DD');

        // Check if action contains query
        if (action.query) {
            query = { ...action.query };
            if (action.query.field === 'id') {
                query.field = firebase.firestoreDocumentId;
            }
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .where('date', '<=', todayMoment)
                .where(query.field, query.operation, query.value)
                .get();
        } else {
            querySnapshot = yield firebase
                .db()
                .collection('games')
                .where('date', '<=', todayMoment)
                .get();
        }

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });

        // Sort by Date, Time, and Court
        games.sort((a, b) => {
            if (a.date > b.date) {
                return 1;
            } else if (a.date === b.date) {
                if (a.time > b.time) {
                    return 1;
                } else if (a.time === b.time) {
                    if (a.court && b.court) {
                        if (a.court > b.court) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            } else {
                return -1;
            }
        });

        yield put(actions.fetchPreviousGamesSuccess(games));
    } catch (error) {
        // console.log('[fetchPreviousGamesSaga > error]', error);
        yield put(actions.fetchPreviousGamesFail(error));
    }
}

export function* fetchNextGameDateSaga(action) {
    try {
        const games = [];
        let querySnapshot = null;
        let nextGameDate = null;

        // Previous Games: Games with date before 'today'
        const todayMoment = moment().format('YYYY-MM-DD');

        querySnapshot = yield firebase
            .db()
            .collection('games')
            .where('date', '>=', todayMoment)
            .orderBy('date', 'asc')
            .limit(1)
            .get();

        yield querySnapshot.forEach(doc => {
            let items = doc.data();
            items = {
                ...items,
                id: doc.id
            };
            games.push(items);
        });

        if (games.length > 0) {
            nextGameDate = games[0].date;
        }

        yield put(actions.fetchNextGameDateSuccess(nextGameDate));
    } catch (error) {
        // console.log('[fetchNextGameDateSaga > error]', error);
        yield put(actions.fetchNextGameDateFail(error));
    }
}

export function* createGameSaga(action) {
    try {
        const gameData = {
            ...action.game,
            createdAt: firebase.timestamp,
            updatedAt: firebase.timestamp,
            createdBy: action.userId,
            updatedBy: action.userId
        };
        yield firebase
            .db()
            .collection('games')
            .add(gameData);
        yield put(actions.fetchGames());
        yield put(actions.fetchUpcomingGames());
        yield put(actions.createGameSuccess());
    } catch (error) {
        // console.log('[createGameSaga > error]', error);
        yield put(actions.createGameFail(error));
    }
}

export function* createBulkGamesSaga(action) {
    try {
        const gamesData = [...action.games];
        const batch = yield firebase.db().batch();

        yield gamesData.forEach(game => {
            const gameData = {
                ...game,
                createdAt: firebase.timestamp,
                updatedAt: firebase.timestamp,
                createdBy: action.userId,
                updatedBy: action.userId
            };
            const gameDataRef = firebase
                .db()
                .collection('games')
                .doc();
            batch.set(gameDataRef, gameData);
        });
        yield batch.commit();
        yield put(actions.fetchGames());
        yield put(actions.fetchUpcomingGames());
        yield put(actions.createBulkGamesSuccess());
    } catch (error) {
        // console.log('[createBulkGamesSaga > error]', error);
        yield put(actions.createBulkGamesFail(error));
    }
}

export function* updateGameSaga(action) {
    try {
        const gameData = {
            ...action.game,
            updatedAt: firebase.timestamp,
            updatedBy: action.userId
        };
        yield firebase
            .db()
            .collection('games')
            .doc(action.gameId)
            .update(gameData);
        yield put(actions.fetchGames());
        yield put(actions.fetchUpcomingGames());
        yield put(actions.updateGameSuccess());
    } catch (error) {
        // console.log('[updateGameSaga > error]', error);
        yield put(actions.updateGameFail(error));
    }
}

export function* updateBulkGamesSaga(action) {
    try {
        const gamesData = [...action.games];
        const gameIds = [...action.gameIds];
        const batch = yield firebase.db().batch();

        yield gamesData.forEach((game, gameIndex) => {
            const gameData = {
                ...game,
                updatedAt: firebase.timestamp,
                updatedBy: action.userId
            };
            const gameDataRef = firebase
                .db()
                .collection('games')
                .doc(gameIds[gameIndex]);
            batch.update(gameDataRef, gameData);
        });
        yield batch.commit();
        yield put(actions.fetchGames());
        yield put(actions.fetchUpcomingGames());
        yield put(actions.updateBulkGamesSuccess(gameIds));
        // console.log('[updateBulkGamesSaga > success]');
    } catch (error) {
        // console.log('[updateBulkGamesSaga > error]', error);
        yield put(actions.updateBulkGamesFail(error));
    }
}

export function* deleteGameSaga(action) {
    try {
        const gameData = {
            ...action.game
        };
        yield firebase
            .db()
            .collection('games')
            .doc(gameData.id)
            .delete();
        yield put(actions.fetchGames());
        yield put(actions.fetchUpcomingGames());
        yield put(actions.deleteGameSuccess());
    } catch (error) {
        // console.log('[deleteGameSaga > error]', error);
        yield put(actions.deleteGameFail());
    }
}
