import rules from '../../../utilities/permissions/permissions';
import check from '../../../utilities/permissions/check/check';

const Can = props => {
    if (props.children) {
        return check(rules, props.roles, props.perform, props.data) ? { ...props.children } : props.fallback();
    }
    return check(rules, props.roles, props.perform, props.data) ? props.yes() : props.no();
};

Can.defaultProps = {
    yes: () => null,
    no: () => null,
    fallback: () => null
};

export default Can;
